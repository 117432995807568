<div>
  <p-toast></p-toast>
  <app-header></app-header>
  <div id="section-header">
  <div class="container">
    <div class="container-fluid company-intro p-0 header">
      <div class="row">
        <div class="col-7 pt-4 py-sm-4 py-md-5 py-lg-5 company-intro-content text-center">
          <h6>CUNG CẤP CHO DOANH NGHIỆP NHỮNG SẢN PHẨM CÔNG NGHỆ</h6>
          <div class="row m-3">
            <div class="col-6 text-end">
              <h2>UY TÍN</h2>
              <h2>CHẤT LƯỢNG</h2>
              <h2>CÔNG NGHỆ</h2>
            </div>
            <div class="col-6 text-start mt-lg-0 mt-2">
              <h1 class="text-white">TOP</h1>
              <h1 class="text-white">ĐẦU</h1>
            </div>
          </div>


          <button style="height: 40px" type="button" pButton pRipple
                  class="search-button">
            <span>Tham gia cùng chúng tôi</span>
          </button>
        </div>
        <div class="col-5 logo-product">
          <img class="img-fluid" src="assets/product.png" alt="Ảnh sản phẩm">
        </div>
      </div>
    </div>


    <div class="container-fluid">
      <div class="row title-and-search flex-column">
        <h4 class="col-12 m-0 text-center text-light">Sản phẩm nổi bật của chúng tôi</h4>
        <form [formGroup]="searchForm" (ngSubmit)="onSubmitSearch()" class="col-12 mt-3">
          <div class="row justify-content-center">
            <div class="col-12 col-sm-10 col-md-8">
              <div class="p-inputgroup search-bar">
                <input type="text" pInputText placeholder="Tìm kiếm..."
                       formControlName="name"
                       class="w-100">
                <button type="submit" pButton pRipple class="search-button">
                  <i class="pi pi-search gray-icon"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="container-fluid my-5">
      <div class="row g-3">
        <div *ngFor="let product of productsTop3" class="col-12 col-md-4"> <!-- col-12 for small screens, col-md-4 for medium/large screens -->
          <div [routerLink]="['/product/product-detail', product.id]" class="p-card p-0 product">
            <div class="product-image">
              <img class="img-fluid" [src]="product.backGroundImage" alt="Ảnh sản phẩm">
            </div>
            <div class="m-3">
              <h5 class="fw-bold">{{ product.name }}</h5>
              <span>{{ product.detailShort }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="container-fluid">
      <div class="row text-center">
        <h2 class="fw-bold">Mang đến những sản phẩm công nghệ hàng đầu!</h2>
        <span>Luôn mang lại cho doanh nghiệp những sản phẩm công nghệ có chất lượng tốt nhất</span>
        <span>Cập nhật công nghệ nhanh chóng, giao diện thân thiện với người dùng</span>
      </div>
    </div>

    <div class="container-fluid my-5">
      <div class="row g-3">
        <div *ngFor="let product of products" class="col-12 col-md-4"> <!-- Responsive classes -->
          <div [routerLink]="['/product/product-detail', product.id]" class="p-card p-0 product">
            <div class="product-image">
              <img class="img-fluid" [src]="product.backGroundImage" alt="Ảnh sản phẩm">
            </div>
            <div class="m-3">
              <h5 class="fw-bold">{{ product.name }}</h5>
              <span>{{ product.detailShort }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="table-footer d-flex align-items-center">
      <div style="width: 100%; float: none;">
        <p-paginator
          [rows]="itemPerPage"
          [totalRecords]="totalItem"
          [rowsPerPageOptions]="[6, 12, 18, 24]"
          [showCurrentPageReport]="true"
          [first]="pageNumber * itemPerPage"
          (onPageChange)="onPageChange($event)"
        ></p-paginator>
      </div>
    </div>
  </div>
    </div>
  <app-footer></app-footer>
</div>
