<div>
  <app-header></app-header>

  <!-- <div class="news" *ngIf="news">
    <h4 class="news__heading">{{ news.title }}</h4>
    <div class="news__createTime">{{
      news.createTime | date: "dd/MM/yyyy"
      }}</div>
    <p class="news__summary">
      {{ news.summary }}
    </p>
    <div [innerHTML]="news.content" class="news__content">
    </div>
  </div> -->
  <div class="container" *ngIf="news">
    <p-card header="{{ news.title }}" subheader="{{ news.summary }}" styleClass="p-card-shadow">
      <ng-template pTemplate="header">
        <div class="d-flex align-items-center p-4">
          <img *ngIf="employee" [src]="employee.image"
            style="width: 45px; height: 45px" class="img-fluid rounded-circle" />
          <img *ngIf="!employee" src="http://cdn.smartup.com.vn:8001/test/SmartSolution.jpg"
            style="width: 45px; height: 45px" class="img-fluid rounded-circle" />
          <div class="row d-flex align-items-center" style="padding-left: 20px;">
            <h6 *ngIf="employee" class="m-0" style="color: black;">{{ employee.name }}</h6>
            <h6 *ngIf="!employee" class="m-0" style="color: black;">{{ news.createBy }}</h6>
            <div class="d-flex align-items-center news_createTime">
              <i class="material-symbols-outlined" style="font-size: 15px;">Public</i>
              <span>&nbsp;{{ news.createTime | date: "HH:mm dd/MM/yyyy" }}</span>
            </div>
          </div>
        </div>
        <img alt="Card" loading="lazy" [src]="news.image">
      </ng-template>
      <p [innerHTML]="news.content">
      </p>
      <!-- <ng-template pTemplate="footer">
        <p-button label="Save" icon="pi pi-check"></p-button>
        <p-button label="Cancel" icon="pi pi-times" styleClass="p-button-secondary" [style]="{'margin-left': '.5em'}"></p-button>
    </ng-template> -->
    </p-card>
  </div>


  <footer>
    <app-footer></app-footer>
  </footer>
</div>
