import { Component, Input, OnInit } from '@angular/core';
import { ChartConfiguration, ChartOptions } from 'chart.js';
import { count } from 'rxjs';
import { EmployeeService } from 'src/app/services/employee.service';
@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.css']
})
export class ChartComponent implements OnInit {
  @Input() title = '';
  @Input() width = '';
  @Input() type = '';

  public barChartData: ChartConfiguration<'bar'>['data'] = {
    labels: [],
    datasets:  [
      { data: [], label: 'Số lượng nhân viên' },
    ]
  };

  

  public barChartOptions: ChartOptions<'bar'> = {
    responsive: true
  };
  public barChartLegend = true;

  constructor(
    private employee: EmployeeService,
  ) { }

  ngOnInit(): void {
    this.updateChartData();
  }

  updateChartData(): void{
    this.employee.countEmployeeUnActiveByYear().subscribe((dataUnactive: any) => {
    this.employee.countEmployeeActiveByYear().subscribe((dataActive: any) => {
      this.barChartData = {
        labels: Object.keys(dataActive),
        datasets: [
          { data: Object.values(dataActive), label: 'Số lượng nhân viên' },
          { data: Object.values(dataUnactive), label: 'Số lượng nhân viên đã nghỉ' },
        ]
      };
    })
  })
  }
}
