import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {contact, contractRecruitmentInfoSearch} from '../models/contact';
import { response } from '../models/response';
import { AuthService } from './auth.service';
import {news} from "../models/news";

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  private apiUrl = environment.apiUrl;
  private token = this.authService.getToken();
  private httpOptions = {
    headers: {
      Authorization: `Bearer ${this.token}`
    }
  }
  constructor(
    private authService: AuthService,
    private http: HttpClient
  ) { }

  getContacts(page: number): Observable<response<contact[]>> {
    return this.http.get<response<contact[]>>(`${this.apiUrl}/contact/select?page=${page}&sort=create_desc`, this.httpOptions);
  }

  selectContacts(page: number, status : number, search : string, sort : string): Observable<response<contact[]>> {
    return this.http.get<response<contact[]>>(`${this.apiUrl}/contact/select?status=${status}&page=${page}&sort=${sort}&search=${search}`, this.httpOptions);
  }

  filterContacts(status: number): Observable<response<contact[]>> {
    return this.http.get<response<contact[]>>(`${this.apiUrl}/contact/select?status=${status}`, this.httpOptions)
  }

  sortContacts(condition: string): Observable<response<contact[]>> {
    return this.http.get<response<contact[]>>(`${this.apiUrl}/contact/select?sort=${condition}`, this.httpOptions)
  }

  searchContact(keyword: string): Observable<response<contact[]>> {
    return this.http.get<response<contact[]>>(`${this.apiUrl}/contact/select?search=${keyword}`, this.httpOptions)
  }

  getContact(id: number | undefined): Observable<contact> {
    return this.http.get<contact>(`${this.apiUrl}/contact/selectOne?id=${id}`, this.httpOptions);
  }

  createContact(data: contact, file: File | undefined) {
    const formData = new FormData();
    formData.append('requestContact', new Blob([
      JSON.stringify(data)
    ], {
      type: 'application/json'
    }));

    if(file != undefined) {
      formData.append('file', file)
    }

    return this.http.post(`${this.apiUrl}/contact/create`, formData, {
      headers: {
        Authorization: `No Auth`
      },
      responseType: 'text'
    })
  }

  getContactRecruitment(data : contractRecruitmentInfoSearch ): Observable<response<contact[]>> {
    return this.http.post<response<contact[]>>(`${this.apiUrl}/contact/searchContractRecruitmentInfo`, data, this.httpOptions);
  }

  updateContactById(id: number | undefined, data: contact) {
    return this.http.post(`${this.apiUrl}/contact/update?id=${id}`, data, {
      headers: {
        Authorization: `Bearer ${this.token}`
      },
      responseType: 'text'
    })
  }

  deleteContactById(id: any | undefined) {
    return this.http.post(`${this.apiUrl}/contact/delete`, id, {
      headers: {
        Authorization: `Bearer ${this.token}`
      },
      responseType: 'text'
    })
  }
}
