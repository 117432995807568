<nav id="navbar" class="navbar navbar-expand-lg fixed-top"
     [ngStyle]="{'background': (page=='landing') ? '' : 'linear-gradient(84.43deg, #01579b -2.2%, #e1f5fe 98.42%)'}">
  <div class="container-fluid">
    <div class="row w-100 align-items-center">
      <div class="col-6 col-lg-4 d-flex align-items-center">
        <img class="me-4" src="/assets/logo-light.png" alt="" style="width: 3rem; height: 3rem" />
        <span class="text-uppercase text-light fw-bold d-none d-md-block" style="font-size: 24px">Smart Solutions</span>
      </div>

      <div class="col-6 col-lg-8 d-flex justify-content-end align-items-center">
        <div [ngClass]="{'show': !isMenuCollapsed}" class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <a class="nav-link text-menu" routerLink="/" routerLinkActive="" (click)="closeMenu()">{{ "menu.home" | translate }}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-menu" routerLink="/product" routerLinkActive="" (click)="closeMenu()">{{ "menu.product" | translate }}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-menu" routerLink="/job" routerLinkActive="" (click)="closeMenu()">{{ "menu.recruitment" | translate }}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-menu" routerLink="/news" routerLinkActive="" (click)="closeMenu()">{{ "menu.news" | translate }}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-menu" routerLink="/contact" routerLinkActive="" (click)="closeMenu()">{{ "menu.contact" | translate }}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-menu" routerLink="/faq" routerLinkActive="" (click)="closeMenu()">{{ "menu.faq" | translate }}</a>
            </li>
          </ul>
        </div>

<!--        <div class="d-flex align-items-center nav-language ">-->
<!--          <a class="m-1 pointer-cursor" (click)="setLang('vi')" role="button"><img src="/assets/lang-icon/Vi-vn.png" alt=""/></a>-->
<!--          <a class="m-1" (click)="setLang('jp')" role="button"><img src="/assets/lang-icon/Jp-j.png" alt=""/></a>-->
<!--          <a class="m-1" (click)="setLang('en')" role="button"><img src="/assets/lang-icon/en-US.png" alt=""/></a>-->
<!--        </div>-->

        <button id="btn-menu" class="btn d-lg-none" type="button" (click)="toggleMenu()">
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
    </div>
  </div>
</nav>
