<div class="bg-white shadow p-1">
  <p class="text-center">{{ title }}</p>
  <canvas
    baseChart
    [type]="'pie'"
    width="300"
    height="300"
    [datasets]="pieChartDatasets"
    [labels]="pieChartLabels"
    [options]="pieChartOptions"
    [plugins]="pieChartPlugins"
    [legend]="pieChartLegend"
  >
  </canvas>
</div>
