import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {contact} from '../models/contact';
import {response} from '../models/response';
import {AuthService} from './auth.service';
import {lookUpValue, RecruitmentInfoDto, searchRecruitment} from "../models/job";

@Injectable({
  providedIn: 'root'
})
export class JobListService {
  private apiUrl = environment.apiUrl;
  private token = this.authService.getToken();
  private httpOptions = {
    headers: {
      Authorization: `Bearer ${this.token}`
    }
  }

  constructor(
    private authService: AuthService,
    private http: HttpClient
  ) {
  }

  getSkills(searchCriteria: {
    lcoIds: number[];
    pageNumber: number;
    pageSize: number
  }): Observable<response<lookUpValue[]>> {
    const url = `${this.apiUrl}/lookUpValue/search`;
    return this.http.post<response<lookUpValue[]>>(url, searchCriteria, {headers: this.httpOptions.headers});
  }

  getRecruitments(searchRecruitment: {
    search?: string;
    skillIds?: number[];
    levelIds?: number[];
    pageNumber: number;
    pageSize: number
  }): Observable<response<RecruitmentInfoDto[]>> {
    const url = `${this.apiUrl}/recruitment-info/search`;
    return this.http.post<response<RecruitmentInfoDto[]>>(url, searchRecruitment, {headers: this.httpOptions.headers});
  }

  getJobDetail(id: number | undefined): Observable<RecruitmentInfoDto> {
    return this.http.get<RecruitmentInfoDto>(`${this.apiUrl}/recruitment-info/detail/${id}`, this.httpOptions);
  }

  selectContacts(page: number, status: number, search: string, sort: string): Observable<response<contact[]>> {
    return this.http.get<response<contact[]>>(`${this.apiUrl}/contact/select?status=${status}&page=${page}&sort=${sort}&search=${search}`, this.httpOptions);
  }

  filterContacts(status: number): Observable<response<contact[]>> {
    return this.http.get<response<contact[]>>(`${this.apiUrl}/contact/select?status=${status}`, this.httpOptions)
  }

  sortContacts(condition: string): Observable<response<contact[]>> {
    return this.http.get<response<contact[]>>(`${this.apiUrl}/contact/select?sort=${condition}`, this.httpOptions)
  }

  searchContact(keyword: string): Observable<response<contact[]>> {
    return this.http.get<response<contact[]>>(`${this.apiUrl}/contact/select?search=${keyword}`, this.httpOptions)
  }

}
