import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { response } from 'src/app/models/response';
import { role } from 'src/app/models/role';
import { RoleService } from 'src/app/services/role.service';
import {ToastService} from "../../toast.service";
import { NgForm } from '@angular/forms';


@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.css']
})
export class RoleComponent implements OnInit {
  @ViewChild('formAdd') formAdd: NgForm;
  @ViewChild('formImport') formImport: NgForm;

  roles: role[] = [];
  role: role = {
    name: ''
  }
  roleDetail: role = {
    name: ''
  }
  numberOfElements = 0;
  selectedValue: any = []
  fileToUpload: any = ''
  totalItem = 10;
  totalPage = 10;
  itemPerPage = 10;
  pageNumber = 0;
  checkAll = false;
  statusRole : number = 1;
  name = '';
  sort = 'create_desc';
  checked = false;
  checkedCheckBox: any[] =[]; 
  indexCheckBox: any[] = [];
  searchTerm = new Subject<string>();
  constructor(
    private roleService: RoleService,
    private confirmationService: ConfirmationService,
    private toast: ToastService,

  ) {
    this.formAdd = new NgForm([], []);
    this.formImport = new NgForm([], []);
  }


  ngOnInit(): void {
    this.getAll()
    this.searchTerm.pipe(debounceTime(1000), distinctUntilChanged(),).subscribe((name: string) => {
      if (name.length === 0) {
        this.getAll();
      }
      this.roleService.searchRole(name).subscribe({
        next: (data : any) => {
          this.roles = data.content;
          this.totalPage = data.totalPages;
          this.totalItem = data.totalElements;
          this.itemPerPage = data.numberOfElements;
          this.pageNumber = data.pageable.pageNumber;
          this.numberOfElements = data.numberOfElements;
        },
        error: (err) =>{
          console.log(err);
        }
      })
    })
  }

  getAll() {
    this.roleService.getAll(1, 1).subscribe({
      next: (data: response<role[]>) => {
        this.roles = data.content;
        this.totalPage = data.totalPages;
        this.totalItem = data.totalElements;
        this.itemPerPage = data.numberOfElements;
        this.pageNumber = data.pageable.pageNumber;
        this.numberOfElements = data.numberOfElements;
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
        this.toast.error('Load dữ liệu', 'Có lỗi xảy ra khi load dữ liệu');
      }
    })
  }

  onSearch(value: string) {
    this.name = value;
    this.searchTerm.next(value);
  }

  onFilter(e: Event) {
     this.statusRole = +(e.target as HTMLInputElement).value;
    this.roleService.filterRole(this.statusRole).subscribe({
      next: (data: response<role[]>) => {
        this.roles = data.content;
        this.pageNumber = data.pageable.pageNumber;
        this.totalPage = data.totalPages;
        this.totalItem = data.totalElements;
        this.itemPerPage = data.numberOfElements;
        this.numberOfElements = data.numberOfElements;
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
        this.toast.error('Lọc vai trò', 'Có lỗi xảy ra khi lọc');
      }
    })
  }

  onSort(e: Event) {
    this.sort = (e.target as HTMLInputElement).value;
    this.roleService.sortRole(this.sort).subscribe({
      next: (data: response<role[]>) => {
        this.roles = data.content;
        this.pageNumber = data.pageable.pageNumber;
        this.totalPage = data.totalPages;
        this.totalItem = data.totalElements;
        this.itemPerPage = data.numberOfElements;
        this.numberOfElements = data.numberOfElements;
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
        this.toast.error('Sắp xếp vai trò', 'Có lỗi xảy ra khi sắp xếp');
      }
    })
  }

  onPageChange(e: any) {
    const page = e.page + 1;
    this.roleService.selectAll(this.statusRole, page,this.name,this.sort).subscribe({
      next: (data: response<role[]>) => {
        this.roles = data.content;
        this.pageNumber = data.pageable.pageNumber;
        this.numberOfElements = data.numberOfElements;
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      }
    })
  }

  onCheckboxValue(e: any, index: number){
    const id = +(e.target as HTMLInputElement).value;
    if (e.target.checked) {
      this.selectedValue.push(id);
      this.checkedCheckBox[index] = true;
      this.indexCheckBox.push(index);
      if (this.selectedValue.length === this.itemPerPage) {
        this.checkAll = true;
      }else{
        this.checkAll = false;
      }
    } else {
      this.selectedValue = this.selectedValue.filter((item: number) => item !== id);
      this.indexCheckBox = this.indexCheckBox.filter((item: number) => item !== index);
      if (this.selectedValue.length === this.itemPerPage) {
        this.checkAll = true;
      }else{
        this.checkAll = false;
      }
    }
  }

  onChangeFile(e: any) {
    this.fileToUpload = e.target.files[0];
  }

  onDownloadTemplate():void {
    this.roleService.downloadTemplateExcel().subscribe(response => {
      if (response.body) {
        const filename = 'template-role.xlsx';
        const blob = new Blob([response.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } else {
        console.error('Lỗi tải về Template');
      }
    });
  }

  onUploadFile(data: role, input: HTMLInputElement) {
    if(input.value === ''){
      this.toast.error('Import excel', 'Có lỗi xảy ra khi import');
    }else{
      this.roleService.create(data, this.fileToUpload).subscribe({
        next: () => {
          this.getAll();
          this.formImport.resetForm();
          input.value = '';
          this.toast.success('Import excel', 'Import thành công');
        },
        error: (err: HttpErrorResponse) => {
          this.toast.error('Import excel', 'Có lỗi xảy ra khi import');
        }
      })
    }
  }

  onGetDetail(id: number | undefined) {
    this.roleService.getById(id).subscribe({
      next: (data: role) => {
        this.roleDetail = data;
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      }
    })
  }

  onSubmit(data: role) {
    this.trimData(data)
    if(data.name.length === 0){
      this.toast.error('Thêm vai trò', 'Vui lòng nhập tên vai trò');
    }else{
    this.roleService.create(data, this.fileToUpload).subscribe({
      next: () => {
        this.getAll();
        this.formAdd.resetForm();
        this.toast.success('Thêm vai trò', 'Thêm thành công');
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
        this.toast.error('Thêm vai trò', 'Có lỗi xảy ra khi thêm');
      }
    })
  }
  }

  sortUpdate(){
    this.roleService.sortRole('update_desc').subscribe({
      next: (data: response<role[]>) => {
        this.roles = data.content;
        this.pageNumber = data.pageable.pageNumber;
        this.totalPage = data.totalPages;
        this.totalItem = data.totalElements;
        this.itemPerPage = data.numberOfElements;
        this.numberOfElements = data.numberOfElements;
      }
    })
  }

  onEdit(id: number | undefined, data: role) {
    this.roleService.updateById(id, data).subscribe({
      next: () => {
        this.sortUpdate();
        this.toast.success('Sửa vai trò', 'Sửa thành công');
      },
      error: (err: HttpErrorResponse) => {
        this.toast.error('Sửa vai trò', 'Có lỗi xảy ra khi sửa');
      }
    })
  }

  onDelete(id: number | undefined) {
    this.confirmationService.confirm({
      header: 'Xóa vai trò',
      message: 'Bạn có chắc muốn xóa không ?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Đồng ý',
      rejectLabel: 'Hủy',
      accept: () => {
        if (this.selectedValue.length === 0) {
          this.selectedValue.push(id);
          this.roleService.deleteById(this.selectedValue).subscribe({
            next: (data) => {
              this.getAll();
              this.selectedValue = []
              this.checked = false;
              this.checkAll = false;
              this.indexCheckBox.forEach(i => {
                this.checkedCheckBox[i] = false
              })
              this.indexCheckBox = [];
              this.toast.success('Xóa vai trò', 'Xóa thành công');
            },
            error: (err: HttpErrorResponse) => {
              console.log(err);
              this.toast.error('Xóa vai trò', 'Có lỗi xảy ra khi xóa');
            }
          })
        }
        else {
          this.roleService.deleteById(this.selectedValue).subscribe({
            next: (data) => {
              this.getAll();
              this.selectedValue = []
              this.checked = false;
              this.checkAll = false;
              this.indexCheckBox.forEach(i => {
                this.checkedCheckBox[i] = false
              })
              this.indexCheckBox = [];
              this.toast.success('Xóa vai trò', 'Xóa thành công');
            },
            error: (error) => {
              console.log(error);
              this.toast.error('Xóa vai trò', 'Có lỗi xảy ra khi xóa');
            }
          })
        }
      }
    })
  }
  onCheckAll(e: any): void {
    if (e.target.checked) {
      this.selectedValue = [];
      this.roles.forEach(n => {
        return this.selectedValue.push(n.id);
      });
      this.checked = true;
      this.checkAll = true;
    } else {
      this.selectedValue = [];
      this.checked = false;
      this.checkAll = false;
      for (let index = 0; index <= 10; index++) {
        this.checkedCheckBox[index] = false;
      }
    }
    console.log(this.selectedValue);
  }
  onCheckBox(){
    this.checked = false;
    this.checkAll = false;
    this.selectedValue = []
    this.indexCheckBox.forEach(i => {
      this.checkedCheckBox[i] = false
    })
    this.indexCheckBox = [];
  }

  trimData(data: role){
    data.name = data.name.trim();
  }

  resetForm(){
    this.formAdd.resetForm();
  }
}
