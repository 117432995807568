import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { user } from '../models/user';
import { Router } from '@angular/router';
import jwtDecode from 'jwt-decode';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  login(data: user): Observable<user> {
    return this.http.post<user>(`${this.apiUrl}/login`, data);
  }

  logout() {
    localStorage.removeItem('user');
    this.router.navigateByUrl('/login')
  }

  isAuthenticated(): boolean {
    return JSON.parse(localStorage.getItem('user') as string);
  }

  getToken() {
    if (this.isAuthenticated()) {
      return JSON.parse(localStorage.getItem('user') as string).accessToken;
    } else {
      return ''
    }
  }

  getUsername() {
    const { username }: user = jwtDecode(this.getToken());
    console.log(jwtDecode(this.getToken()));
    return username;
  }

  getScopes() {
    console.log("Scope");
    if (this.isAuthenticated()) {
      console.log("Scope: authen");
      return JSON.parse(localStorage.getItem('user') as string).scopes;
    } else {
      console.log("Scope: non");
      return [];
    }
  }
}
