import {Component, OnInit, ViewChild} from '@angular/core';
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {ToastModule} from "primeng/toast";
import {RouterLinkWithHref} from "@angular/router";
import {FormsModule, NgForm} from "@angular/forms";
import {DatePipe, NgClass, NgForOf, NgIf} from "@angular/common";
import {PaginatorModule} from "primeng/paginator";
import {user} from "../../models/user";
import {debounceTime, distinctUntilChanged, Subject} from "rxjs";
import {RecruitmentService} from "../../services/recruitment.service";
import {ContactService} from "../../services/contact.service";
import {ToastService} from "../../toast.service";
import {ConfirmationService} from "primeng/api";
import {response} from "../../models/response";
import {HttpErrorResponse} from "@angular/common/http";
import {recruitment, recruitment_search} from "../../models/recruitment";
import {contractRecruitmentInfoSearch} from "../../models/contact";
import {look_up_value} from "../../models/look_up_value";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {CKEditorModule} from "@ckeditor/ckeditor5-angular";
import {contact} from "../../models/contact";
import editor from "@ckeditor/ckeditor5-build-classic";
import {MultiSelectModule} from "primeng/multiselect";

@Component({
  selector: 'app-recruitment',
  standalone: true,
  imports: [
    ConfirmDialogModule,
    ToastModule,
    RouterLinkWithHref,
    FormsModule,
    NgForOf,
    NgIf,
    PaginatorModule,
    NgClass,
    CKEditorModule,
    DatePipe,
    MultiSelectModule
  ],
  templateUrl: './recruitment.component.html',
  styleUrls: ['./recruitment.component.css']
})
export class RecruitmentComponent implements OnInit {
  @ViewChild('formAdd') formAdd: NgForm;
  @ViewChild('formEdit') formEdit: NgForm;
  recruitments: recruitment[] = []
  contacts: contact[] = [];
  recruitment: recruitment = {
    id: 0,
    workingHoursId: 0,
    experienceId: 0,
    salaryId: 0,
    recruitmentFrom: '',
    recruitmentTo: '',
    recruitmentFromStr: '',
    recruitmentToStr: '',
    quantity: 0,
    title: '',
    content: '',
    contentShort: '',
    workingHoursName: '',
    experienceName: '',
    salaryName: '',
    createBy: '',
    createTime: '',
    createTimeStr: '',
    levelId: 0,
    levelName: '',
    skillIds: [],
    skills:[],
    skillNames:''
  };
  recruitmentDetail: recruitment = {
    id: 0,
    workingHoursId: 0,
    experienceId: 0,
    salaryId: 0,
    recruitmentFrom: '',
    recruitmentTo: '',
    recruitmentFromStr: '',
    recruitmentToStr: '',
    quantity: 0,
    title: '',
    content: '',
    contentShort: '',
    workingHoursName: '',
    experienceName: '',
    salaryName: '',
    createBy: '',
    createTime: '',
    createTimeStr: '',
    levelId: 0,
    levelName: '',
    skillIds: [],
    skills : [],
    skillNames:''
  };
  checked = false;
  loading = false;
  users: user[] = [];
  levels: look_up_value[] = [];
  skills: look_up_value[] = [];
  salarys: look_up_value[] = [];
  experiences: look_up_value[] = [];

  indexCheckBox: any[] = [];
  checkedCheckBox: any[] = [];
  fileToUpload: string | Blob = '';
  selectedValue: any = [];
  checkAll = false;
  isFirstTableVisible = true;
  totalPage = 10;
  totalItem = 10;
  itemPerPage = 10;
  pageNumber = 0;
  totalPageContact = 10;
  totalItemContact = 10;
  itemPerPageContact = 10;
  pageNumberContact = 0;
  statusRecruitment: number = 1;
  statusContact = 1;
  keyword = '';
  sort = 'create_desc';
  numberOfElements = 0;
  numberOfElementsContact = 0;
  searchTerm = new Subject<string>();
  searchTermContactRecruitment = new Subject<string>();
  search = '';
  searchContactRecruitment = '';
  sortColumn = '';
  sortDirection = '';
  statusDf: number | undefined = 1;
  editor = ClassicEditor;

  // IMAGE_URL = `http://192.168.1.86:9000/test/`;
  constructor(
    private recruitmentService: RecruitmentService,
    private toast: ToastService,
    private confirmatinService: ConfirmationService,
    private contactService: ContactService
  ) {
    this.formAdd = new NgForm([], []);
    this.formEdit = new NgForm([], []);
  }

  ngOnInit(): void {
    this.getAll()
    this.loadFilters()
    this.getContactRecruitment()
    this.searchTerm.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
    ).subscribe((keyword: string) => {
      if (keyword.length === 0) {
        this.getAll()
      }
      console.log(keyword)
      const search: recruitment_search = {
        search: keyword,
      };
      this.recruitmentService.getAll(search).subscribe({
        next: (data: response<recruitment[]>) => {
          this.recruitments = data.content;
          this.pageNumber = data.pageable.pageNumber;
          this.totalPage = data.totalPages;
          this.totalItem = data.totalElements;
          this.itemPerPage = data.numberOfElements;
          this.numberOfElements = data.numberOfElements;
        },
        error: (err: any) => {
          console.log(err);
        }
      })
    })
    this.searchTermContactRecruitment.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
    ).subscribe((keyword: string) => {
      if (keyword.length === 0) {
        this.getContactRecruitment()
      }
      console.log(keyword)
      const search: contractRecruitmentInfoSearch = {
        search: keyword,
      };
      this.contactService.getContactRecruitment(search).subscribe({
        next: (data: response<contact[]>) => {
          this.contacts = data.content;
          this.pageNumberContact = data.pageable.pageNumber;
          this.totalPageContact = data.totalPages;
          this.totalItemContact = data.totalElements;
          this.itemPerPageContact = data.numberOfElements;
          this.numberOfElementsContact = data.numberOfElements;
        },
        error: (err: any) => {
          console.log(err);
        }
      })
    })
  }

  loadFilters(): void {
    const searchExperience = {lcoIds: [3], pageNumber: 0, pageSize: 999};
    const searchSalarys = {lcoIds: [1], pageNumber: 0, pageSize: 999};
    const searchLevel = {lcoIds: [17], pageNumber: 0, pageSize: 999};
    const searchSkill = {lcoIds: [2], pageNumber: 0, pageSize: 999};

    this.recruitmentService.getLookUpValues(searchSalarys).subscribe({
      next: (data) => this.salarys = data.content,
      error: (error) => this.handleError('Failed to load salarys', error)
    });
    this.recruitmentService.getLookUpValues(searchExperience).subscribe({
      next: (data) => this.experiences = data.content,
      error: (error) => this.handleError('Failed to load skills', error)
    });

    this.recruitmentService.getLookUpValues(searchLevel).subscribe({
      next: (data) => this.levels = data.content,
      error: (error) => this.handleError('Failed to load levels', error)
    });

    this.recruitmentService.getLookUpValues(searchSkill).subscribe({
      next: (data) => this.skills = data.content,
      error: (error) => this.handleError('Failed to load levels', error)
    });
  }

  getContactRecruitment() {
    const search: contractRecruitmentInfoSearch = {

    };
    this.contactService.getContactRecruitment(search).subscribe({
      next: (data: response<contact[]>) => {
        this.contacts = data.content;
        this.totalItemContact = data.totalElements;
        this.totalPageContact = data.totalPages;
        this.itemPerPageContact = data.numberOfElements;
        this.pageNumberContact = data.pageable.pageNumber;
        this.numberOfElementsContact = data.numberOfElements;
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      }
    })
  }


  getAll() {
    this.loading = true;
    const search: recruitment_search = {}
    this.recruitmentService.getAll(search).subscribe({
      next: (data: response<recruitment[]>) => {
        this.recruitments = data.content;
        this.totalPage = data.totalPages;
        this.totalItem = data.totalElements;
        this.itemPerPage = data.numberOfElements;
        this.pageNumber = data.pageable.pageNumber;
        this.numberOfElements = data.numberOfElements;
        this.loading = false;
        console.log(this.recruitments);
      },
      error: (error: any) => {
        console.log(error);
      }
    })
  }

  onFilter(e: Event) {
    this.statusRecruitment = +(e.target as HTMLInputElement).value;
    const search: recruitment_search = {
      status: this.statusRecruitment,
    }
    this.recruitmentService.getAll(search).subscribe({
      next: (data: response<recruitment[]>) => {
        this.recruitments = data.content;
        this.pageNumber = data.pageable.pageNumber;
        this.totalPage = data.totalPages;
        this.totalItem = data.totalElements;
        this.itemPerPage = data.numberOfElements;
        this.numberOfElements = data.numberOfElements;
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      }
    })
  }

  onFilterContact(e: Event) {
    this.statusContact = +(e.target as HTMLInputElement).value;
    const search: contractRecruitmentInfoSearch = {
      status: this.statusContact,
    }
    this.contactService.getContactRecruitment(search).subscribe({
      next: (data: response<contact[]>) => {
        this.contacts = data.content;
        this.pageNumberContact = data.pageable.pageNumber;
        this.totalPageContact = data.totalPages;
        this.totalItemContact = data.totalElements;
        this.itemPerPageContact = data.numberOfElements;
        this.numberOfElementsContact = data.numberOfElements;
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      }
    })
  }

  onSort(e: Event): void {
    this.sort = (e.target as HTMLInputElement).value;
    if (this.sort === '') {
      this.getAll();
    } else {
      this.sortColumn = this.sort.slice(0, this.sort.indexOf("_"));
      this.sortDirection = this.sort.slice(this.sort.indexOf("_") + 1);
      const searchParams: recruitment_search = {
        sortColumn: this.sortColumn,
        sortDirection: this.sortDirection,
        pageNumber: this.pageNumber,
        search: this.search

      };
      this.recruitmentService.getAll(searchParams).subscribe({
        next: (data: response<recruitment[]>) => {
          this.recruitments = data.content;
          this.pageNumber = data.pageable.pageNumber;
          this.totalPage = data.totalPages;
          this.totalItem = data.totalElements;
          this.itemPerPage = data.numberOfElements;
          this.numberOfElements = data.numberOfElements;
        },
        error: (err: HttpErrorResponse) => {
          console.log(err);
          this.toast.error('Sắp xếp danh sách tuyển dụng', 'Có lỗi xảy khi sắp xếp');
        }
      })
    }
  }

  onSortContact(e: Event): void {
    this.sort = (e.target as HTMLInputElement).value;
    if (this.sort === '') {
      this.getContactRecruitment();
    } else {
      this.sortColumn = this.sort.slice(0, this.sort.indexOf("_"));
      this.sortDirection = this.sort.slice(this.sort.indexOf("_") + 1);
      const searchParams: contractRecruitmentInfoSearch = {
        sortColumn: this.sortColumn,
        sortDirection: this.sortDirection,
        pageNumber: this.pageNumber,
        search: this.search

      };
      this.contactService.getContactRecruitment(searchParams).subscribe({
        next: (data: response<contact[]>) => {
          this.contacts = data.content;
          this.pageNumberContact = data.pageable.pageNumber;
          this.totalPageContact = data.totalPages;
          this.totalItemContact = data.totalElements;
          this.itemPerPageContact = data.numberOfElements;
          this.numberOfElementsContact = data.numberOfElements;
        },
        error: (err: HttpErrorResponse) => {
          console.log(err);
          this.toast.error('Sắp xếp danh sách ứng viên', 'Có lỗi xảy khi sắp xếp');
        }
      })
    }
  }

  onSearch(keyword: string): void {
    this.search = keyword;
    this.searchTerm.next(keyword)
  }

  onSearchContract(keyword: string): void {
    this.searchContactRecruitment = keyword;
    this.searchTermContactRecruitment.next(keyword)
  }


  onPageChange(e: any) {
    const page = e.page;
    const search: recruitment_search = {
      pageNumber: page,
    }
    this.recruitmentService.getAll(search).subscribe({
      next: (data: response<recruitment[]>) => {
        this.recruitments = data.content;
        this.pageNumber = data.pageable.pageNumber;
        this.numberOfElements = data.numberOfElements;
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      }
    })
  }

    onPageChangeContract(e: any): void {
    const page = e.page + 1;
      const search: contractRecruitmentInfoSearch = {
        pageNumber: page,
      }
    this.contactService.getContactRecruitment(search).subscribe({
      next: (data: response<contact[]>) => {
        this.contacts = data.content;
        this.pageNumberContact = data.pageable.pageNumber;
        this.numberOfElementsContact = data.numberOfElements;
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      }
    })
  }

  onCheckboxValue(e: any, index: number) {
    const id = +(e.target as HTMLInputElement).value;
    if (e.target.checked) {
      this.selectedValue.push(id);
      this.checkedCheckBox[index] = true;
      this.indexCheckBox.push(index);
      if (this.selectedValue.length === this.itemPerPage) {
        this.checkAll = true;
      } else {
        this.checkAll = false;
      }
    } else {
      const removedId = this.selectedValue.filter((item: number) => item !== id);
      this.selectedValue = removedId;
      this.indexCheckBox = this.indexCheckBox.filter((item: number) => item !== index);
      if (this.selectedValue.length === this.itemPerPage) {
        this.checkAll = true;
      } else {
        this.checkAll = false;
      }
    }
  }

  getById(id: number | undefined) {
    this.recruitmentService.getById(id).subscribe({
      next: (data: recruitment) => {
        this.recruitmentDetail = data;
        this.recruitmentDetail.skills = this.skills.filter(skill =>
          this.recruitmentDetail.skillIds.includes(skill.id!)
        );
        this.recruitmentDetail.recruitmentTo = this.recruitmentDetail.recruitmentToStr === undefined ? undefined
          : this.formatDateForInput(this.recruitmentDetail.recruitmentToStr);

        this.recruitmentDetail.recruitmentFrom = this.recruitmentDetail.recruitmentFromStr === undefined ? undefined
          : this.formatDateForInput(this.recruitmentDetail.recruitmentFromStr);
      },
      error: (error: any) => {
        console.log(error);
      }
    })
  }

  onSubmit(data: recruitment) {
    data.skillIds = this.recruitment.skillIds.flat();
    this.recruitmentService.create(data).subscribe({
      next: () => {
        this.getAll();
        this.formAdd.resetForm();
        this.toast.success('Thêm tuyển dụng', 'Thêm thành công');
      },
      error: (err) => {
        console.log(err);
        this.toast.error('Thêm tuyển dụng', 'Có lỗi xảy ra khi thêm');
      },
    })
  }

  onEdit(id: number | undefined, data: recruitment) {
    data.skillIds = this.recruitmentDetail.skillIds.flat();
    this.recruitmentService.updateById(id, data).subscribe({
      next: () => {
        this.getAll();
        this.toast.success('Sửa tuyển dụng', 'Sửa thành công');
      },
      error: (error) => {
        console.log(error);
        this.toast.error('Sửa tuyển dụng', 'Có lỗi xảy ra khi sửa');
      }
    })
  }

  onDelete(id: number | undefined) {
    this.confirmatinService.confirm({
      header: 'Xóa nhân viên',
      message: 'Bạn có chắc muốn xóa không ?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Đồng ý',
      rejectLabel: 'Hủy',
      accept: () => {
        if (this.selectedValue.length > 0) {
          this.recruitmentService.deleteById(this.selectedValue).subscribe({
            next: (data) => {
              this.getAll();
              this.checkAll = false;
              this.checked = false;
              this.selectedValue = [];
              this.indexCheckBox.forEach(i => {
                this.checkedCheckBox[i] = false
              })
              this.indexCheckBox = [];
              this.toast.success('Xóa thông tin tuyển dụng', 'Xóa thành công');
            },
            error: (error) => {
              console.log(error);
              this.toast.error('Xóa thông tin tuyển dụng', 'Có lỗi xảy ra khi xóa');
            }
          })
        } else {
          this.selectedValue.push(id);
          this.recruitmentService.deleteById(this.selectedValue).subscribe({
            next: (data) => {
              this.getAll();
              this.checkAll = false;
              this.checked = false;
              this.selectedValue = [];
              this.indexCheckBox.forEach(i => {
                this.checkedCheckBox[i] = false
              })
              this.indexCheckBox = [];
              this.toast.success('Xóa thông tin tuyển dụng', 'Xóa thành công');
            },
            error: (error) => {
              console.log(error);
              this.toast.error('Xóa thông tin tuyển dụng', 'Có lỗi xảy ra khi xóa');
            }
          })
        }
      }
    })
  }

  onCheckAll(e: any): void {
    if (e.target.checked) {
      this.selectedValue = [];
      this.recruitments.forEach(n => {
        return this.selectedValue.push(n.id);
      });
      this.checked = true;
      this.checkAll = true;
    } else {
      this.selectedValue = [];
      this.checkAll = false;
      this.checked = false;
      for (let index = 0; index <= 10; index++) {
        this.checkedCheckBox[index] = false;
      }
    }
    console.log(this.selectedValue);
  }

  onCheckBox() {
    this.checked = false;
    this.checkAll = false;
    this.selectedValue = []
    this.indexCheckBox.forEach(i => {
      this.checkedCheckBox[i] = false
    })
    this.indexCheckBox = [];
  }

  private handleError(message: string, error: any): void {
    console.error(`${message}:`, error);
    // You could add more sophisticated error handling here, like showing a user-friendly message
  }

  formatDateForInput(dateString: string): string {
    const [day, month, year] = dateString.split('/');
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  }

  onDateChange() {
    if (this.recruitmentDetail.recruitmentFrom && this.recruitmentDetail.recruitmentTo) {
      const fromDate = new Date(this.recruitmentDetail.recruitmentFrom);
      const toDate = new Date(this.recruitmentDetail.recruitmentTo);

      if (toDate < fromDate) {
        this.recruitmentDetail.recruitmentTo = this.recruitmentDetail.recruitmentFrom;
      }
    }
    this.formEdit.control.updateValueAndValidity();
  }

  toggleTable() {
    this.isFirstTableVisible = !this.isFirstTableVisible;
  }

  openPDF(fileUrl?: string) {
    window.open(fileUrl, '_blank');
  }

  onCheckboxChange(lookUpValue: any) {
    this.recruitment.skillIds = [];
    this.recruitmentDetail.skillIds = [];
    this.recruitment.skillIds.push(lookUpValue.value.map((skill: { id: any; }) => skill.id))
    this.recruitmentDetail.skillIds.push(lookUpValue.value.map((skill: { id: any; }) => skill.id))
  }

  protected readonly null = null;
}
