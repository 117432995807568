<div class="container-fluid p-4">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/admin">Admin</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">Dashboard</li>
    </ol>
  </nav>
  <!-- List card -->
  <div class="row">
    <app-card
      class="col"
      title="số lượng nhân viên"
      content="{{ totalEmployee }} người"
      icon="manage_accounts"
    ></app-card>
    <app-card
      class="col"
      title="số lượng đối tác"
      content="{{ totalPartner }} đối tác"
      icon="handshake"
    ></app-card>
    <app-card
      class="col"
      title="số lượng bài viết"
      content="{{ totalNews }} bài viết"
      icon="article"
    ></app-card>
    <app-card
      class="col"
      title="số lượng dịch vụ"
      content="{{ totalService }} dịch vụ"
      icon="hive"
    ></app-card>
  </div>
  <!-- Chart -->
  <div class="d-flex justify-content-between align-items-center py-2">
    <app-chart title="Số nhân viên qua từng năm" width="630"></app-chart>
    <app-pie-chart title="Danh sách về"></app-pie-chart>
  </div>
</div>
