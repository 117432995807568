import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit , ViewChild} from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ConfirmationService } from 'primeng/api';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { contact } from 'src/app/models/contact';
import { news, newsData } from 'src/app/models/news';
import { news_type } from 'src/app/models/news_type';
import { response } from 'src/app/models/response';
import { NewsTypeService } from 'src/app/services/news-type.service';
import { NewsService } from 'src/app/services/news.service';
import {ToastService} from "../../toast.service";
import { NgForm } from '@angular/forms';
import { formatDate } from '@angular/common';
@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {
  @ViewChild('formAdd') formAdd: NgForm;
  news: news[] = [];
  newsData: newsData = {
    title: '',
    summary: '',
    content: '',
    newsTypeId: 0,
  }
  newsDetail: newsData = {
    title: '',
    summary: '',
    content: '',
    newsTypeId: 0
  }
  rangeDates?: Date[];
  news_type: news_type[] = [];
  contacts: contact[] = [];
  totalPage = 1;
  totalItem = 1;
  itemPerPage = 1;
  pageNumber = 0;
  checked = false;
  editor = ClassicEditor;
  previewImage: string | Blob = '';
  selectedValue: any = [];
  searchTerm = new Subject<string>();
  search = '';
  sort = 'create_desc';
  statusNews : number = 1;
  numberOfElements = 0;
  checkAll = false;
  indexCheckBox: any[] = [];
  checkedCheckBox: any[] = [];
  constructor(
    private newsService: NewsService,
    private newsTypeService: NewsTypeService,
    private toast: ToastService,
    private confirmationService: ConfirmationService
  ) {
    this.formAdd = new NgForm([], []);
  }

  ngOnInit(): void {
    this.getAll()
    this.getNewsType()
    this.searchTerm.pipe(debounceTime(1000), distinctUntilChanged(),).subscribe((search: string)=> {
      if (search.length === 0) {
        this.getAll();
      }
      this.newsService.searchNews(search).subscribe({
        next:(data : any) => {
          this.news = data.content;
          this.pageNumber = data.pageable.pageNumber;
        this.totalPage = data.totalPages;
        this.totalItem = data.totalElements;
        this.itemPerPage = data.numberOfElements;
        this.numberOfElements = data.numberOfElements;
        },
        error: (err) => {
          console.log(err);
        }
      })
    })
  }

  getAll(): void {
    this.newsService.getListNews(1).subscribe({
      next: (data: response<news[]>) => {
        this.news = data.content;
        this.totalPage = data.totalPages;
        this.totalItem = data.totalElements;
        this.itemPerPage = data.numberOfElements;
        this.pageNumber = data.pageable.pageNumber;
        this.numberOfElements = data.numberOfElements;
        console.log(data);
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      }
    })
  }

  onSearch(search: string){
    this.search = search;
    this.searchTerm.next(search);
  }

  getById(id: number | undefined): void {
    this.clearData();
    this.newsService.getNews(id).subscribe({
      next: (data: news) => {
        this.newsDetail = data;
        this.newsDetail.newsTypeId = data.newsType?.id;
        if (data.publishTime && data.endTime) {
          this.rangeDates = [];
          this.rangeDates[0] = new Date(data.publishTime);
          this.rangeDates[1] = new Date(data.endTime);
        }
        console.log(this.rangeDates);
        console.log(data);
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      }
    })
  }

  getNewsType(): void {
    this.newsTypeService.getListNewsType(1, 1).subscribe({
      next: (data: response<news_type[]>) => {
        this.news_type = data.content;

        console.log(data);

      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      }
    })
  }

  onPageChange(e: any): void {
    const page = e.page + 1;
    this.newsService.selectAll(page, this.statusNews, this.search, this.sort).subscribe({
      next: (data: response<news[]>) => {
        this.news = data.content;
        this.pageNumber = data.pageable.pageNumber;
        this.numberOfElements = data.numberOfElements;
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      }
    })
  }

  onCheckboxValue(e: any, index: number): void {
    const id = +e.target.value;
    if (e.target.checked) {
      this.selectedValue.push(id)
      this.checkedCheckBox[index] = true;
      this.indexCheckBox.push(index);
      if (this.selectedValue.length === this.itemPerPage) {
        this.checkAll = true;
      }else{
        this.checkAll = false;
      }
    } else {
      this.selectedValue = this.selectedValue.filter((item: number) => item !== id);
      this.indexCheckBox = this.indexCheckBox.filter((item: number) => item !== index);
      if (this.selectedValue.length === this.itemPerPage) {
        this.checkAll = true;
      }else{
        this.checkAll = false;
      }
    }
  }

  onSelectedFile(e: any) {
      this.previewImage = e.target.files[0]
  }

  onFilter(e: Event): void {
    this.statusNews = +(e.target as HTMLInputElement).value;
    this.newsService.filterNews(this.statusNews).subscribe({
      next: (data: response<news[]>) => {
        this.news = data.content;
        this.pageNumber = data.pageable.pageNumber;
        this.totalPage = data.totalPages;
        this.totalItem = data.totalElements;
        this.itemPerPage = data.numberOfElements;
        this.numberOfElements = data.numberOfElements;
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      }
    })
  }

  onSort(e: Event): void {
    this.sort = (e.target as HTMLInputElement).value;
    this.newsService.sortNews(this.sort).subscribe({
      next: (data: response<news[]>) => {
        this.news = data.content;
        this.pageNumber = data.pageable.pageNumber;
        this.totalPage = data.totalPages;
        this.totalItem = data.totalElements;
        this.itemPerPage = data.numberOfElements;
        this.numberOfElements = data.numberOfElements;
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      }
    })
  }

  sortUpdate(){
    this.newsService.sortNews('update_desc').subscribe({
      next: (data: response<news[]>) => {
        this.news = data.content;
        this.pageNumber = data.pageable.pageNumber;
        this.totalPage = data.totalPages;
        this.totalItem = data.totalElements;
        this.itemPerPage = data.numberOfElements;
        this.numberOfElements = data.numberOfElements;
      }})
  }

  onSubmit(data: news) {
    this.newsService.createNews(data, this.previewImage).subscribe({
      next: (data) => {
        this.getAll();
        this.formAdd.resetForm();
        this.toast.success('Thêm mới tin tức', 'Thêm mới thành công');
        this.clearData();
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
        this.toast.error('Thêm mới tin tức', 'Có lỗi xảy ra khi thêm mới');
      }
    })
  }

  onEdit(id: number | undefined, data: newsData): void {
    if (!data.newsTypeId) {
      this.toast.warn('Sửa bài viết', 'Vui lòng chọn loại bài viết');
    } else {
      this.newsService.editNewsById(id, data, this.previewImage).subscribe({
        next: (data: any) => {
          this.sortUpdate();
          this.toast.success('Sửa bài viết', 'Sửa thành công');
          this.clearData();
        },
        error: (err: HttpErrorResponse) => {
          console.log(err);
          this.toast.error('Sửa bài viết', 'Có lỗi xảy ra khi sửa');
        }
      })
   }
  }

  onDelete(id: number | undefined) {
    this.confirmationService.confirm({
      header: 'Xóa tin tức',
      message: 'Bạn có chắc muốn xóa không?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Đồng ý',
      rejectLabel: 'Hủy',
      accept: () => {
        if (this.selectedValue.length === 0) {
          this.selectedValue.push(id);
          this.newsService.deleteNewsById(this.selectedValue).subscribe({
            next: (data: any) => {
              this.getAll()
              this.selectedValue = [];
              this.checked = false;
              this.checkAll = false;
              this.indexCheckBox.forEach(i => {
                this.checkedCheckBox[i] = false
              })
              this.indexCheckBox = [];
              this.toast.success('Xóa tin tức', 'Xóa thành công');
            },
            error: (err: HttpErrorResponse) => {
              console.log(err);
              this.toast.error('Xóa tin tức', 'Có lỗi xảy ra khi xóa');
            }
          })
        }else{
          this.newsService.deleteNewsById(this.selectedValue).subscribe({
            next: (data: any) => {
              this.getAll()
              this.selectedValue = [];
              this.checked = false;
              this.checkAll = false;
              this.indexCheckBox.forEach(i => {
                this.checkedCheckBox[i] = false
              })
              this.indexCheckBox = [];
              this.toast.success('Xóa tin tức', 'Xóa thành công');
              },
            error: (err: HttpErrorResponse) => {
              console.log(err);
              this.toast.error('Xóa tin tức', 'Có lỗi xảy ra khi xóa');
            }
          })
        }
      }
    })
  }
  clearData(): void{
    this.previewImage = '';
    this.rangeDates = [];
  }

  onCheckAll(e: any): void {
    if (e.target.checked) {
      this.selectedValue = [];
      this.news.forEach(n => {
        return this.selectedValue.push(n.id);
      });
      this.checked = true;
      this.checkAll = true;
    } else {
      this.selectedValue = [];
      this.checked = false;
      this.checkAll = false;
      for (let index = 0; index <= 10; index++) {
        this.checkedCheckBox[index] = false;
      }
    }
    console.log(this.selectedValue);
  }

  onCheckBox(){
    this.checked = false;
    this.checkAll = false;
    this.selectedValue = []
    this.indexCheckBox.forEach(i => {
      this.checkedCheckBox[i] = false
    })
    this.indexCheckBox = [];
  }
}
