import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { employee } from 'src/app/models/employee';
import { organization } from 'src/app/models/organization';
import { response } from 'src/app/models/response';
import { user } from 'src/app/models/user';
import { EmployeeService } from 'src/app/services/employee.service';
import { ToastService } from "../../toast.service";
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.css']
})
export class EmployeeComponent implements OnInit {
  @ViewChild('formAdd') formAdd: NgForm;
  employees: employee[] = []
  employee: employee = {
    name: '',
    image: '',
    email: '',
    address: '',
    phoneNumber: '',
    user: {
      id: 0
    },
    organizationId: 0
  };
  employeeDetail: employee = {
    name: '',
    email: '',
    image: '',
    address: '',
    phoneNumber: '',
    user: {
      id: 0,
      name: ''
    },
    organizationId: {
      id: 0,
      name: ''
    }
  };
  checked = false;
  loading = false;
  users: user[] = [];
  indexCheckBox: any[] = [];
  checkedCheckBox: any[] = [];
  organizations: organization[] = [];
  fileToUpload: string | Blob = '';
  selectedValue: any = [];
  checkAll = false;
  totalPage = 10;
  totalItem = 10;
  itemPerPage = 10;
  pageNumber = 0;
  statusEmployee: number = 1;
  keyword = '';
  sort = 'create_desc';
  numberOfElements = 0;
  searchTerm = new Subject<string>();
  // IMAGE_URL = `http://192.168.1.86:9000/test/`;
  constructor(
    private employeeService: EmployeeService,
    private toast: ToastService,
    private confirmatinService: ConfirmationService
  ) {
    this.formAdd = new NgForm([], []);
  }

  ngOnInit(): void {
    this.getAll()
    this.getDataForCreate()
    this.searchTerm.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
    ).subscribe((keyword: string) => {
      if (keyword.length === 0) {
        this.getAll()
      }
      this.employeeService.searchEmployee(keyword).subscribe({
        next: (data: any) => {
          this.employees = data.content;
          this.pageNumber = data.pageable.pageNumber;
          this.totalPage = data.totalPages;
          this.totalItem = data.totalElements;
          this.itemPerPage = data.numberOfElements;
          this.numberOfElements = data.numberOfElements;
        },
        error: (err) => {
          console.log(err);
        }
      })
    })
  }


  getAll() {
    this.loading = true;
    this.employeeService.getAll(1).subscribe({
      next: (data: response<employee[]>) => {
        this.employees = data.content;
        this.totalPage = data.totalPages;
        this.totalItem = data.totalElements;
        this.itemPerPage = data.numberOfElements;
        this.pageNumber = data.pageable.pageNumber;
        this.numberOfElements = data.numberOfElements;
        this.loading = false;
        console.log(this.employees);
      },
      error: (error) => {
        console.log(error);
      }
    })
  }

  onFilter(e: Event) {
    this.statusEmployee = +(e.target as HTMLInputElement).value;
    if (this.statusEmployee === 0) {
      this.employeeService.filterEmployeeAll().subscribe({
        next: (data: response<employee[]>) => {
          this.employees = data.content;
          this.pageNumber = data.pageable.pageNumber;
          this.totalPage = data.totalPages;
          this.totalItem = data.totalElements;
          this.itemPerPage = data.numberOfElements;
          this.numberOfElements = data.numberOfElements;
        },
        error: (err: HttpErrorResponse) => {
          console.log(err);
        }
      })
    } else {
      this.employeeService.filterEmployee(this.statusEmployee).subscribe({
        next: (data: response<employee[]>) => {
          this.employees = data.content;
          this.pageNumber = data.pageable.pageNumber;
          this.totalPage = data.totalPages;
          this.totalItem = data.totalElements;
          this.itemPerPage = data.numberOfElements;
          this.numberOfElements = data.numberOfElements;
        },
        error: (err: HttpErrorResponse) => {
          console.log(err);
        }
      })
    }
  }

  onSort(e: Event) {
    this.sort = (e.target as HTMLInputElement).value;
    this.employeeService.sortEmployee(this.sort).subscribe({
      next: (data: response<employee[]>) => {
        this.employees = data.content;
        this.pageNumber = data.pageable.pageNumber;
        this.totalPage = data.totalPages;
        this.totalItem = data.totalElements;
        this.itemPerPage = data.numberOfElements;
        this.numberOfElements = data.numberOfElements;
      },
      error: (err: HttpErrorResponse) => {
        this.toast.error('Sắp xếp nhân viên', 'Có lỗi xảy ra khi sắp xếp');
      }
    })
  }

  sortUpdate() {
    this.employeeService.sortEmployee('update_desc').subscribe({
      next: (data: response<employee[]>) => {
        this.employees = data.content;
        this.pageNumber = data.pageable.pageNumber;
        this.totalPage = data.totalPages;
        this.totalItem = data.totalElements;
        this.itemPerPage = data.numberOfElements;
        this.numberOfElements = data.numberOfElements;
      }
    })
  }

  onSearch(value: string) {
    this.keyword = value;
    this.searchTerm.next(value);
  }

  onPageChange(e: any) {
    const page = e.page + 1;
    this.employeeService.selectAll(page, this.statusEmployee, this.keyword, this.sort).subscribe({
      next: (data: response<employee[]>) => {
        this.employees = data.content;
        this.pageNumber = data.pageable.pageNumber;
        this.numberOfElements = data.numberOfElements;
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      }
    })
  }

  onCheckboxValue(e: any, index: number) {
    const id = +(e.target as HTMLInputElement).value;
    if (e.target.checked) {
      this.selectedValue.push(id);
      this.checkedCheckBox[index] = true;
      this.indexCheckBox.push(index);
      if (this.selectedValue.length === this.itemPerPage) {
        this.checkAll = true;
      } else {
        this.checkAll = false;
      }
    } else {
      const removedId = this.selectedValue.filter((item: number) => item !== id);
      this.selectedValue = removedId;
      this.indexCheckBox = this.indexCheckBox.filter((item: number) => item !== index);
      if (this.selectedValue.length === this.itemPerPage) {
        this.checkAll = true;
      } else {
        this.checkAll = false;
      }
    }
  }

  getById(id: number | undefined) {
    this.employeeService.getById(id).subscribe({
      next: (data: employee) => {
        console.log(data);
        this.employeeDetail = data;
      },
      error: (error) => {
        console.log(error);
      }
    })
  }

  getDataForCreate() {
    this.employeeService.getDataForCreate().subscribe({
      next: (data) => {
        this.organizations = data[0].content;
        this.users = data[1];
        console.log(this.users);
        console.log(this.organizations)
      },
      error: (error) => {
        console.log(error);
      },
    })
  }

  onChangeFile(e: any) {
    this.fileToUpload = e.target.files[0];
    console.log(this.fileToUpload);
  }

  onDownloadTemplate() {
    this.employeeService.downloadTemplateExcel().subscribe(response => {
      if (response.body) {
        const filename = 'template-employee.xlsx';
        const blob = new Blob([response.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } else {
        console.error('Lỗi tải về Template');
      }
    });
  }

  onUploadFile(data: employee, input: HTMLInputElement) {
    if (input.value === '') {
      this.toast.error('Import excel', 'Có lỗi xảy ra khi import');
    } else {
      this.employeeService.importExcel(this.fileToUpload).subscribe({
        next: (value) => {
          this.getAll();
          this.toast.success('Import excel', 'Import thành công');
        },
        error: (err) => {
          console.log(err);
          this.toast.error('Import excel', 'Có lỗi xảy ra khi Import');
        },
      })
    }
  }

  onSubmit(data: employee) {
    this.employeeService.create(data, this.fileToUpload).subscribe({
      next: () => {
        this.getAll();
        this.formAdd.resetForm();
        this.toast.success('Thêm nhân viên', 'Thêm thành công');
      },
      error: (err) => {
        console.log(err);
        this.toast.error('Thêm nhân viên', 'Có lỗi xảy ra khi thêm');
      },
    })
  }

  onEdit(id: number | undefined, data: employee) {
    this.employeeService.updateById(id, data, this.fileToUpload).subscribe({
      next: () => {
        this.sortUpdate();
        this.toast.success('Sửa nhân viên', 'Sửa thành công');
      },
      error: (error) => {
        console.log(error);
        this.toast.error('Sửa nhân viên', 'Có lỗi xảy ra khi sửa');
      }
    })
  }

  onDelete(id: number | undefined) {
    this.confirmatinService.confirm({
      header: 'Xóa nhân viên',
      message: 'Bạn có chắc muốn xóa không ?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Đồng ý',
      rejectLabel: 'Hủy',
      accept: () => {
        if (this.selectedValue.length > 0) {
          this.employeeService.deleteById(this.selectedValue).subscribe({
            next: (data) => {
              this.getAll();
              this.checkAll = false;
              this.checked = false;
              this.selectedValue = [];
              this.indexCheckBox.forEach(i => {
                this.checkedCheckBox[i] = false
              })
              this.indexCheckBox = [];
              this.toast.success('Xóa tài khoản', 'Xóa thành công');
            },
            error: (error) => {
              console.log(error);
              this.toast.error('Xóa tài khoản', 'Có lỗi xảy ra khi xóa');
            }
          })
        }
        else {
          this.selectedValue.push(id);
          this.employeeService.deleteById(this.selectedValue).subscribe({
            next: (data) => {
              this.getAll();
              this.checkAll = false;
              this.checked = false;
              this.selectedValue = [];
              this.indexCheckBox.forEach(i => {
                this.checkedCheckBox[i] = false
              })
              this.indexCheckBox = [];
              this.toast.success('Xóa tài khoản', 'Xóa thành công');
            },
            error: (error) => {
              console.log(error);
              this.toast.error('Xóa tài khoản', 'Có lỗi xảy ra khi xóa');
            }
          })
        }
      }
    })
  }

  onCheckAll(e: any): void {
    if (e.target.checked) {
      this.selectedValue = [];
      this.employees.forEach(n => {
        return this.selectedValue.push(n.id);
      });
      this.checked = true;
      this.checkAll = true;
    } else {
      this.selectedValue = [];
      this.checkAll = false;
      this.checked = false;
      for (let index = 0; index <= 10; index++) {
        this.checkedCheckBox[index] = false;
      }
    }
    console.log(this.selectedValue);
  }

  onCheckBox() {
    this.checked = false;
    this.checkAll = false;
    this.selectedValue = []
    this.indexCheckBox.forEach(i => {
      this.checkedCheckBox[i] = false
    })
    this.indexCheckBox = [];
  }
}
