import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app/app.component';
import { LandingPageComponent } from './app/landing-page/landing-page.component';
import { HomeComponent } from './app/home/home.component';
import { AdminComponent } from './app/admin/admin.component';
import { AppRoutingModule } from './app/app-routing.module';
import { LoginComponent } from './app/admin/login/login.component';
import { DashboardComponent } from './app/admin/dashboard/dashboard.component';
import { ConfigComponent } from './app/admin/config/config.component';
import { OrganizationComponent } from './app/admin/organization/organization.component';
import { AccountComponent } from './app/admin/account/account.component';
import { ServiceComponent } from './app/admin/service/service.component';
import { NewsComponent } from './app/admin/news/news.component';
import { ContactComponent } from './app/admin/contact/contact.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { EmployeeComponent } from './app/admin/employee/employee.component';
import { PartnerComponent } from './app/admin/partner/partner.component';
import { RoleComponent } from './app/admin/role/role.component';
import { CardComponent } from './app/components/card/card.component';
import { ChartComponent } from './app/components/chart/chart.component';
import { NgChartsModule } from 'ng2-charts';
import { PieChartComponent } from './app/components/pie-chart/pie-chart.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { ConfirmationService, MessageService } from 'primeng/api';
import { PaginatorModule } from 'primeng/paginator';
import { NewsTypeComponent } from './app/admin/news-type/news-type.component';
import { NewsPageComponent } from './app/news-page/news-page.component';
import { CardModule } from 'primeng/card';
import { AccordionModule } from 'primeng/accordion';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarModule } from 'primeng/calendar';
import { ContactPageComponent } from './app/contact-page/contact-page.component';
import {NewsContentPageComponent} from "./app/news-page/news-content/news-content-page.component";
import { FAQPageComponent } from './app/faq-page/faq-page.component';
import { HeaderComponent } from './app/components/header/header.component';
import { FooterComponent } from './app/components/footer/footer.component';
import { CarouselModule } from 'primeng/carousel';
import { TagModule } from 'primeng/tag';
import { JobListComponent } from './app/job-list/job-list.component';
import {RippleModule} from "primeng/ripple";
import {MultiSelectModule} from "primeng/multiselect";
import { JobDetailComponent } from './app/job-list/job-detail/job-detail.component';
import {LookUpCodeComponent} from "./app/admin/look-up-code/look-up-code.component";
import {LookUpValueComponent} from "./app/admin/look-up-value/look-up-value.component";
import {ProductComponent} from "./app/admin/product/product.component";
import { ApplyDialogComponent } from './app/job-list/apply-dialog/apply-dialog.component';
import { ProductPageComponent } from './app/product-page/product-page.component';
import { ProductDetailPageComponent } from './app/product-page/product-detail-page/product-detail-page.component';

export function HttpLoaderFactory(httpClient: HttpClient) {
  // return new TranslateHttpLoader(httpClient);
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    HomeComponent,
    AdminComponent,
    LoginComponent,
    DashboardComponent,
    ConfigComponent,
    OrganizationComponent,
    AccountComponent,
    ServiceComponent,
    NewsComponent,
    ContactComponent,
    EmployeeComponent,
    PartnerComponent,
    RoleComponent,
    CardComponent,
    ChartComponent,
    PieChartComponent,
    NewsTypeComponent,
    NewsPageComponent,
    ContactPageComponent,
    NewsContentPageComponent,
    FAQPageComponent,
    HeaderComponent,
    FooterComponent,
    LookUpCodeComponent,
    JobListComponent,
    JobDetailComponent,
    LookUpValueComponent,
    ProductComponent,
    ApplyDialogComponent,
    ProductPageComponent,
    ProductDetailPageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgChartsModule,
    CKEditorModule,
    ConfirmDialogModule,
    MessageModule,
    ToastModule,
    PaginatorModule,
    CardModule,
    AccordionModule,
    InputTextModule,
    CalendarModule,
    CarouselModule,
    TagModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    RippleModule,
    MultiSelectModule,
  ],
  providers: [ConfirmationService, MessageService],
  bootstrap: [AppComponent],
})
export class AppModule { }
