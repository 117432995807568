<section id="footer" class="d-flex align-items-end">
  <div class="container-fluid h-100 m-4">
    <div class="row">
      <div class="col col-12 col-lg-6 col-md-12 mb-4 p-4 text-light">
        <div class="fw-bold text-uppercase company-name" style="font-size: 1.2rem">
          {{ "content.company_name" | translate }}
        </div>
        <div class="d-flex align-items-center my-2">
          <span class="material-symbols-outlined me-3"> pin_drop </span>
          <span>{{ "content.company_address" | translate }}</span>
        </div>
        <div class="d-flex align-items-center my-2">
          <span class="material-symbols-outlined me-3"> call </span>
          <span>0384376297</span>
        </div>
        <div class="d-flex align-items-center my-2">
              <span class="material-symbols-outlined me-3">
                forward_to_inbox
              </span>
          <span>hrsmartsolutionsvn@gmail.com</span>
        </div>
      </div>
      <div
        class="col col-12 col-lg-6 col-md-12 d-flex flex-md-row flex-column justify-content-end align-items-end"
      >
        <button
          class="btn btn-primary btn-icon mx-2 my-md-0 my-2 btn-round"
        >
          <span class="material-symbols-outlined mx-2"> call </span>
          <span>{{ "btn.call" | translate }}: 0384376297</span>
        </button>
        <button
          class="btn btn-primary btn-icon mx-2 my-md-0 my-2 btn-round"
        >
              <span class="material-symbols-outlined mx-2">
                forward_to_inbox
              </span>
          <span>{{ "btn.send_email" | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</section>
