<div>
  <app-header></app-header>

  <!-- SUPPORTER BLOCK -->
  <div class="container">
    <h2>Câu hỏi thường gặp</h2>
    <p-accordion class="m-2" [multiple]="true">
      <p-accordionTab header="Tôi có yêu cầu về bảo trì sau khi hệ thống đi vào vận hành có được không?">
        <p>Vâng. Chúng tôi có thể, hiện tại chúng tôi có nhiều dự án bảo trì như là bảo trì môi trường truyền thông,
          giám sát service, sửa chữa nhỏ, v.v.,
        </p>
      </p-accordionTab>
      <p-accordionTab header="Đảm bảo chất lượng của bạn như thế nào?">
        <p class="m-0">Đảm bảo chất lượng được thực hiện bởi Bộ phận đảm bảo chất lượng.<br>
          Liên quan tới vận hành phát triển một dự án chúng tôi đã đưa vào sử dụng quy trình như bên dưới.
        </p>
        <ul>
          <li>Công ty đã nhận được chứng chỉ. Chúng tôi sử dụng biểu đồ và lịch trình của Gantt để trực quan hóa tiến
            trình, tiến hành các thử nghiệm chung để xác nhận chất lượng, ghi lại các vấn đề và nêu rõ chi phí.</li>
          <li>Về cơ bản được triển khai Scrum như một dự án loại Agile.</li>
        </ul>
      </p-accordionTab>
      <p-accordionTab header="Việc phát triển tôi thường quan tâm đến chất lượng dịch vụ. Có thể đảm bảo chất lượng không?">
        <p>Chúng tôi luôn cung cấp dịch vụ chất lượng cao. Để đảm bảo chất lượng, trong giai đoạn phát triển, chúng tôi luôn có các kỹ sư có thể đáp ứng một cách nhanh nhất</p>
      </p-accordionTab>
      <p-accordionTab header="Công ty làm gì để giữ đúng thời hạn giao hàng của dự án?">
        <p class="m-0">Các hành động sau đây được thực hiện thời hạn giao hàng của dự án.</p>
        <ul>
          <li>Chúng tôi sẽ đặt các mốc quan trọng và lịch trình chi tiết, và quản lý tiến độ một cách chắc chắn cùng với khách hàng.</li>
          <li>Tiến hành báo cáo tiến độ, báo cáo các vấn đề đang vướng mắc.</li>
          <li>Nếu có phát sinh thay đổi yêu cầu, tiến hành phân tích có ảnh hưởng tới thời hạn giao hàng hay không.</li>
        </ul>
        <p class="m-0">Công ty chúng tôi cũng đã đưa vào sử dụng những công cụ quản lý tiến độ như Backlog.</p>
      </p-accordionTab>
      <p-accordionTab header="Việc nghiệm thu được thực hiện như thế nào?">
        <p>Chúng tôi định kì nghiệm thu thành phẩm sau khi hoàn tất. Tại thời điểm bàn giao, khách hàng được yêu cầu cấp giấy chứng nhận hoàn thành và đồng ý hoàn thành bàn giao.</p>
      </p-accordionTab>
    </p-accordion>
  </div>

  <!-- FOOTER -->
  <div>
    <app-footer></app-footer>
  </div>
</div>
