import {HttpClient} from '@angular/common/http';
import {response} from '../models/response';
import {AuthService} from './auth.service';
// import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";
import {recruitment, recruitment_search} from "../models/recruitment";
import {look_up_value} from "../models/look_up_value";

@Injectable({
  providedIn: 'root'
})
export class RecruitmentService {
  private apiUrl = environment.apiUrl;
  private token = this.authService.getToken();
  private httpOptions = {
    headers: {
      Authorization: `Bearer ${this.token}`

    }
  }

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {}

  getLookUpValues(search: {
    lcoIds: number[];
    pageNumber: number;
    pageSize: number
  }): Observable<response<look_up_value[]>> {
    const url = `${this.apiUrl}/lookUpValue/search`;
    return this.http.post<response<look_up_value[]>>(url, search, {headers: this.httpOptions.headers});
  }


  getAll(data:recruitment_search): Observable<response<recruitment[]>> {
    return this.http.post<response<recruitment[]>>(`${this.apiUrl}/recruitment-info/search`, data, this.httpOptions);
  }

  create(data: recruitment) {
    return this.http.post(`${this.apiUrl}/recruitment-info/create`, data, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
      responseType: 'text'
    })
  }

  updateById(id: number | undefined, data: recruitment) {
    return this.http.post(`${this.apiUrl}/recruitment-info/update/${id}`, data, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
      responseType: 'text'
    })
  }

  getById(id: number | undefined): Observable<recruitment> {
    return this.http.get<recruitment>(`${this.apiUrl}/recruitment-info/detail/${id}`, this.httpOptions)
  }

  getListRecruitmentByTitle(): Observable<recruitment[]> {
    return this.http.get<recruitment[]>(`${this.apiUrl}/recruitment-info/search`, {});
  }

  deleteById(id: number[] | undefined) {
    return this.http.post(`${this.apiUrl}/recruitment-info/delete`, id, {
      headers: {
        Authorization: `Bearer ${this.token}`
      },
      responseType: 'text',
    })
  }




}
