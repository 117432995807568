<div class="container-fluid p-4">
  <p-toast></p-toast>
  <p-confirmDialog
    [style]="{ width: '50vw' }"
    [baseZIndex]="10000"
  ></p-confirmDialog>
  <!-- Alert -->
  <div>
    <div class="fixed-bottom d-flex justify-content-center">
      <div
        [hidden]="selectedValue.length === 0"
        class="alert alert-danger w-50"
        role="alert"
      >
        <div class="d-flex justify-content-between align-items-center gap-2">
          <p style="margin-bottom: 0">
            Đã chọn {{ selectedValue.length }} phần tử
          </p>
          <div>
            <button
            class="btn btn-light px-4 cursor-pointer me-3"
            (click)="onCheckBox()"
          >
            Hủy
          </button>
            <button
            class="btn btn-danger px-4 cursor-pointer"
            (click)="onDelete(selectedValue)"
          >
            Xóa
          </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Header -->
  <div class="d-flex justify-content-between">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a class="text-dark text-decoration-none" routerLink="/admin"
            >Admin</a
          >
        </li>
        <li class="breadcrumb-item">
          <a class="text-dark text-decoration-none" routerLink="/organization"
            >Tin tức</a
          >
        </li>
      </ol>
    </nav>
    <div class="d-flex gap-2">
      <button
        class="d-flex gap-2 btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#add-modal"
        (click)="this.rangeDates = [];"
      >
        <i class="material-symbols-outlined"> person_add </i>
        Thêm mới
      </button>
    </div>
  </div>
  <!-- Table -->
  <div
    class="table-wrapper table-responsive border border-bottom-0 rounded-top mt-4"
  >
    <div class="table-header bg-light rounded-top">
      <div class="d-flex justify-content-between align-items-center p-2">
        <div class="d-flex gap-2">
          <!-- Filter -->
          <select
            name="filterValue"
            class="form-select"
            style="max-width: 200px"
            (change)="onFilter($event)"
          >
            <option [value]="1">Lọc theo</option>
            <option [value]="0">Tất cả</option>
            <option [value]="1">Hoạt động</option>
            <option [value]="-1">Không hoạt động</option>
          </select>
          <!-- Sort -->
          <select
            class="form-select"
            style="max-width: 200px"
            (change)="onSort($event)"
          >
            <option value="">Sắp xếp theo</option>
            <option value="id_asc">id tăng dần</option>
            <option value="id_desc">id giảm dần</option>
            <option value="name_asc">Từ a - z</option>
            <option value="name_desc">Từ z - a</option>
            <option value="create_desc">tin mới thêm</option>
            <option value="update_desc">tin mới sửa</option>
          </select>
        </div>

        <input
          type="text"
          class="form-control"
          #searchTerm
          placeholder="Tìm kiếm..."
          style="max-width: 260px"
          #searchInput
          (keyup)="onSearch(searchInput.value)"
        />
      </div>
    </div>
    <table class="table table-responsive table-body">
      <thead>
        <tr>
          <th>
            <input type="checkbox" [checked]="checkAll" name="" class="form-check-input m-0" (change)="onCheckAll($event)" />
          </th>
          <th class="text-secondary">STT</th>
          <th class="text-secondary">Tiêu đề</th>
          <!-- <th class="text-secondary">Tóm tắt</th> -->
          <th class="text-secondary">Hình ảnh</th>
          <th class="text-secondary">Nội dung</th>
          <th class="text-secondary">Trạng thái</th>
          <th class="text-secondary">Ngày tạo</th>
          <th class="text-secondary">Ngày sửa</th>
          <th class="text-secondary">Người tạo</th>
          <th class="text-secondary">Người sửa</th>
          <th class="text-secondary">Thể loại</th>
          <th class="text-secondary">Thao tác</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of news; index as index">
          <td>
            <input
              [checked]="checked"
              type="checkbox"
              [value]="item.id"
              class="form-check-input m-0"
              [checked]="checkedCheckBox[index]"
              (change)="onCheckboxValue($event, index)"
            />
          </td>
          <td>{{ (pageNumber * 10) + index + 1 }}</td>
          <td class="text-truncate" style="max-width: 100px">
            {{ item.title }}
          </td>
          <!-- <td class="text-truncate" style="max-width: 100px">
            {{ item.summary }}
          </td> -->
          <td>
            <img [src]="item.image" class="img-fluid" width="50" height="50" />
          </td>
          <td class="text-truncate" style="max-width: 150px">
            {{ item.content }}
          </td>
          <td>
            <div
              class="d-inline-block bg-success bg-opacity-25 text-success p-2 rounded"
              [ngClass]="{
                'text-success bg-success': item.status === 1,
                'text-danger bg-danger ': item.status === -1,
                'text-warning bg-warning': item.status === null
              }"
            >
              {{ item.status === 1 ? "Hoạt động" : "Không hoạt động" }}
            </div>
          </td>
          <td>{{ item.createTime | date: "dd/MM/yyyy" }}</td>
          <td>{{ item.updateTime | date: "dd/MM/yyyy" }}</td>
          <td>{{ item.createBy }}</td>
          <td>{{ item.updateBy }}</td>
          <td class="text-truncate" style="max-width: 50px">
            {{ item.newsType?.name }}
          </td>
          <td>
            <i
            [hidden]="item.status === -1"
              class="material-symbols-outlined"
              data-bs-toggle="modal"
              data-bs-target="#edit-modal"
              (click)="getById(item.id)"
            >
              edit
            </i>
            <i
            [hidden]="item.status === -1"
              class="material-symbols-outlined mx-2"
              (click)="onDelete(item.id)"
            >
              delete
            </i>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="table-footer d-flex align-items-center border-bottom">
      <a [hidden]="totalItem === 0" class="ms-3" style="text-decoration: none">Hiển thị từ {{(pageNumber * 10) + 1}} - {{(pageNumber * 10)+numberOfElements}}/{{totalItem}} tin tức</a>
      <a [hidden]="totalItem > 0" class="ms-3" style="text-decoration: none">Không có dữ liệu</a>
      <div style="width: 70%; float: right;">
        <p-paginator
        [rows]="itemPerPage"
        [pageLinkSize]="totalPage"
        [totalRecords]="totalItem"
        (onPageChange)="onPageChange($event)"
      ></p-paginator>
      </div>
  </div>
</div>

<!-- Add-modal -->
<div
  class="modal fade"
  id="add-modal"
  tabindex="-1"
  aria-labelledby="add-modal"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="add-modal">Thêm mới tin tức</h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <form
          #formAdd="ngForm"
          class="form-container"
          (submit)="onSubmit(formAdd.value)"
        >
          <div class="py-2">
            <label for="title">Tiêu đề <span style="color: red">*</span></label>
            <input
              type="text"
              name="title"
              [(ngModel)]="newsData.title"
              #title="ngModel"
              class="form-control my-2"
              placeholder="nhập tiêu đề"
              required
            />
          </div>
          <div *ngIf="title.invalid && (title.dirty || title.touched)">
            <p class="text-danger py-2" *ngIf="title.errors?.['required']">
              Vui lòng nhập tiêu đề
            </p>
          </div>
          <div class="py-2">
            <label for="summary">Tóm tắt <span style="color: red">*</span></label>
            <input
              type="text"
              name="summary"
              [(ngModel)]="newsData.summary"
              #summary="ngModel"
              class="form-control my-2"
              placeholder="nhập tóm tắt"
              required
            />
          </div>
          <div *ngIf="summary.invalid && (summary.dirty || summary.touched)">
            <p class="text-danger py-2" *ngIf="summary.errors?.['required']">
              Vui lòng thêm tóm tắt
            </p>
          </div>
          <div class="py-2">
            <label for="image">Hình ảnh <span style="color: red">*</span></label>
            <input
              type="file"
              name="image"
              class="form-control my-2"
              placeholder="thêm Hình ảnh"
              (change)="onSelectedFile($event)"
            />
          </div>
          <div class="py-2">
            <label for="information">Nội dung <span style="color: red">*</span></label>
            <ckeditor
              class="form-content"
              name="content"
              #content="ngModel"
              [editor]="editor"
              [(ngModel)]="newsData.content"
              required
            ></ckeditor>
          </div>
          <div *ngIf="content.invalid && (content.dirty || content.touched)">
            <p class="text-danger py-2" *ngIf="content.errors?.['required']">
              Vui lòng nhập nội dung
            </p>
          </div>
          <div class="row py-2">
            <div class="col-6">
              <label for="information">Thể loại <span style="color: red">*</span></label>
              <select name="newsTypeId" class="form-select" #newsTypeId="ngModel" style="padding: 12px"
              [(ngModel)]="newsData.newsTypeId" required>
                <option *ngFor="let item of news_type" [value]="item.id">
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div *ngIf="newsData.newsTypeId == 46" class="col-6">
              <label for="range">Thời gian diễn ra sự kiện <span style="color: red">*</span></label>
              <p-calendar class="row" [showIcon]="true" placeholder="Ngày bắt đầu - Ngày kết thúc" name="range" [(ngModel)]="rangeDates" #range="ngModel" dateFormat="dd/mm/yy" selectionMode="range" [readonlyInput]="true" inputId="range" [required]="true"></p-calendar>
              <div *ngIf="range.invalid && (range.dirty || range.touched)">
                <p class="text-danger m-0 pt-2" *ngIf="range.errors?.['required']">
                  Vui lòng chọn ngày bắt đầu và ngày kết thúc
                </p>
              </div>
            </div>
          </div>
          <div *ngIf="newsTypeId.invalid && (newsTypeId.dirty || newsTypeId.touched)">
            <p class="text-danger py-2" *ngIf="newsTypeId.errors?.['required']">
              Vui lòng chọn thể loại
            </p>
          </div>
          <div class="d-flex justify-content-end gap-2 pt-2">
            <button
              type="button"
              class="btn btn-outline-secondary"
              data-bs-dismiss="modal"
            >
              Hủy
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              data-bs-dismiss="modal"
              [disabled]="formAdd.invalid || newsData.newsTypeId == 0"
            >
              Thêm Mới
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- Edit-modal -->
<div
  class="modal fade"
  id="edit-modal"
  tabindex="-1"
  aria-labelledby="edit-modal"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="edit-modal">Sửa tin tức</h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <form
          #formEdit="ngForm"
          (submit)="onEdit(newsDetail.id, formEdit.value)"
        >
          <div class="py-2">
            <label for="title">Tiêu đề</label>
            <input
              type="text"
              name="title"
              class="form-control my-2"
              placeholder="nhập tiêu đề"
              [(ngModel)]="newsDetail.title"
              #title="ngModel"
              required
            />
          </div>
          <div *ngIf="title.invalid && (title.dirty || title.touched)">
            <p class="text-danger py-2" *ngIf="title.errors?.['required']">
              Vui lòng nhập tiêu đề
            </p>
          </div>
          <div class="py-2">
            <label for="title">Tóm tắt</label>
            <input
              type="text"
              name="summary"
              class="form-control my-2"
              placeholder="nhập tóm tắt"
              [value]="newsDetail.summary"
              [(ngModel)]="newsDetail.summary"
              #summary="ngModel"
              required
            />
          </div>
          <div *ngIf="summary.invalid && (summary.dirty || summary.touched)">
            <p class="text-danger py-2" *ngIf="summary.errors?.['required']">
              Vui lòng nhập tóm tắt
            </p>
          </div>
          <div class="py-2">
            <label for="image">Hình ảnh</label>
            <input
              type="file"
              name="image"
              class="form-control my-2"
              placeholder="thêm Hình ảnh"
              (change)="onSelectedFile($event)"
            />
          </div>
          <div class="py-2">
            <label for="information">Nội dung</label>
            <ckeditor
              name="content"
              [editor]="editor"
              [(ngModel)]="newsDetail.content"
              #content="ngModel"
            >
              {{ newsDetail.content }}
            </ckeditor>
          </div>
          <div *ngIf="content.invalid && (content.dirty || content.touched)">
            <p class="text-danger py-2" *ngIf="content.errors?.['required']">
              Vui lòng nhập nội dung
            </p>
          </div>
          <div class="row py-2">
            <div class="col-6">
              <label for="information">Thể loại</label>
              <select name="newsTypeId" class="form-select" #newsTypeId="ngModel" style="padding: 12px"
              [(ngModel)]="newsDetail.newsTypeId" [value]="newsDetail.newsTypeId" required>
                <option *ngFor="let item of news_type" [value]="item.id">
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div *ngIf="newsDetail.newsTypeId == 46" class="col-6">
              <label for="range">Thời gian diễn ra sự kiện</label>
              <p-calendar class="row" [showIcon]="true" placeholder="Ngày bắt đầu - Ngày kết thúc" name="range" [(ngModel)]="rangeDates" #range="ngModel" dateFormat="dd/mm/yy" selectionMode="range" [readonlyInput]="true" inputId="range" [required]="true"></p-calendar>
              <div *ngIf="range.invalid && (range.dirty || range.touched)">
                <p class="text-danger m-0 pt-2" *ngIf="range.errors?.['required']">
                  Vui lòng chọn ngày bắt đầu và ngày kết thúc
                </p>
              </div>
            </div>
          </div>
          <div *ngIf="!newsDetail.newsTypeId">
            <p class="text-danger">
              Vui lòng chọn loại tin tức
            </p>
          </div>
          <div class="d-flex justify-content-end gap-2 pt-2">
            <button
              type="button"
              class="btn btn-outline-secondary"
              data-bs-dismiss="modal"
            >
              Hủy
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              data-bs-dismiss="modal"
              [disabled]="formEdit.invalid"
            >
              Lưu
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

