export enum LANGUAGE {
  VIETNAMESE = 'vi',
  ENNGLISH = 'en',
  JAPANESE = 'jp',
}

export class Constants {
  public static LANG_KEY: string = 'lang';
  public static DEFAULT_LANG: string = LANGUAGE.VIETNAMESE;
  public static IMAGE_URL: string = 'http://cdn.smartup.com.vn:8001/test/'
  public static getLang(): string {
    const savedLang = localStorage.getItem(this.LANG_KEY);
    return savedLang?.match(/vi|en|jp/) ? savedLang : this.DEFAULT_LANG;
  }

  public static setLang(lang: string) {
    localStorage.setItem(this.LANG_KEY, lang);
  }
}

export const ROLE = {
  ADMIN: 'ADMIN',
  STAFF: 'STAFF',
  USER: 'USER',
};
