import {Component, Input, HostListener, OnInit, ElementRef, Renderer2} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {Constants} from "../../constants";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Input() page = '';
  isMenuCollapsed = true;

  constructor(
    public translate: TranslateService,
    private renderer: Renderer2,
    private el: ElementRef
  ) { }

  ngOnInit(): void {
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const navBar = document.getElementById('navbar');
    const classList = navBar?.classList;
    if (document.documentElement.scrollTop > 100) {
      classList?.add('navbar-scrolled');
    } else {
      classList?.remove('navbar-scrolled');
    }
  }

  setLang(langCode: string) {
    this.translate.use(langCode);
    Constants.setLang(langCode);
  }

  toggleMenu() {
    this.isMenuCollapsed = !this.isMenuCollapsed;
    if (!this.isMenuCollapsed) {
      this.renderer.addClass(document.body, 'menu-open');
    } else {
      this.renderer.removeClass(document.body, 'menu-open');
    }
  }

  closeMenu() {
    if (!this.isMenuCollapsed) {
      this.isMenuCollapsed = true;
      this.renderer.removeClass(document.body, 'menu-open');
    }
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (!this.el.nativeElement.contains(event.target)) {
      this.closeMenu();
    }
  }

}
