import { Component, OnInit } from '@angular/core';
import { user } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import {NewsService} from "../../services/news.service";
import {EmployeeService} from "../../services/employee.service";
import {ServiceService} from "../../services/service.service";
import {PartnerService} from "../../services/partner.service";
import {response} from "../../models/response";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  totalEmployee = 0;
  totalNews = 0;
  totalPartner = 0;
  totalService = 0;

  constructor(
    private employeeService: EmployeeService,
    private newsService: NewsService,
    private partnerService: PartnerService,
    private serviceService: ServiceService) { }

  ngOnInit(): void {
    this.getAll();
  }

  getAll(): void {
    this.employeeService.countEmployee().subscribe({
      next: (data: number) => {
        this.totalEmployee = data;
        console.log(data);
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      }
    })

    this.newsService.countNews().subscribe({
      next: (data: number) => {
        this.totalNews = data;
        console.log(data);
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      }
    })

    this.partnerService.countPartner().subscribe({
      next: (data: number) => {
        this.totalPartner = data;
        console.log(data);
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      }
    })

    this.serviceService.countService().subscribe({
      next: (data: number) => {
        this.totalService = data;
        console.log(data);
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      }
    })
  }

}
