import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { debounceTime, forkJoin, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { employee } from '../models/employee';
import { organization } from '../models/organization';
import { response } from '../models/response';
import { user } from '../models/user';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  private apiUrl = environment.apiUrl;
  private token = this.authService.getToken();
  private httpOptions = {
    headers: {
      Authorization: `Bearer ${this.token}`
    }
  }
  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }
  
  downloadTemplateExcel(): Observable<HttpResponse<Blob>> {
    return this.http.get(`${this.apiUrl}/employee/download-template`, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`
      }),
      observe: 'response',
      responseType: 'blob',
    });
  }

  getAll(page: number, size?: number, order?: string): Observable<response<employee[]>> {
    return this.http.get<response<employee[]>>(`${this.apiUrl}/employee/selectByStatus?page=${page}&status=1&sort=create_desc` + (Boolean(size) ? `&size=${size}` : ``) + (Boolean(order) ? `&sort=${order}` : ``), this.httpOptions);
  }


  selectAll(page: number, status: number,keyword: string, order: string): Observable<response<employee[]>> {
    return this.http.get<response<employee[]>>(`${this.apiUrl}/employee/selectByStatus?page=${page}&status=${status}&sort=${order}&keyword=${keyword}`, this.httpOptions);
  }

  getListEmployeeByTitle(): Observable<employee[]> {
    return this.http.get<employee[]>(`${this.apiUrl}/employee/listByTitle`, {});
  }

  getListNewEmployee(): Observable<response<employee[]>> {
    return this.http.get<response<employee[]>>(`${this.apiUrl}/employee/selectNewEmployee`, {});
  }

  sortEmployee(order: string): Observable<response<employee[]>> {
    return this.http.get<response<employee[]>>(`${this.apiUrl}/employee/selectByStatus?sort=${order}`, this.httpOptions);
  }

  filterEmployee(status: number): Observable<response<employee[]>> {
    return this.http.get<response<employee[]>>(`${this.apiUrl}/employee/selectByStatus?status=${status}`, this.httpOptions)
  }

  filterEmployeeAll():Observable<response<employee[]>> {
    return this.http.get<response<employee[]>>(`${this.apiUrl}/employee/selectAll`, this.httpOptions)
  }

  searchEmployee(keyword: string): Observable<employee[]> {
    return this.http.get<employee[]>(`${this.apiUrl}/employee/selectAll?keyword=${keyword}`, this.httpOptions)
  }

  getDataForCreate(): Observable<any[]> {
    const getOrganizations = this.http.get<organization[]>(`${this.apiUrl}/organization/select`, this.httpOptions);
    const getAccount = this.http.get<user>(`${this.apiUrl}/user/test`, this.httpOptions);
    return forkJoin([getOrganizations, getAccount])
  }

  getById(id: number | undefined): Observable<employee> {
    return this.http.get<employee>(`${this.apiUrl}/employee/selectOne?id=${id}`, this.httpOptions)
  }

  getByUsername(username: string | undefined): Observable<employee> {
    return this.http.get<employee>(`${this.apiUrl}/employee/selectByUsername?username=${username}`, this.httpOptions)
  }

  create(data: employee, image: any) {
    const formData = new FormData();
    formData.append('employee', new Blob([
      JSON.stringify(data)
    ], {
      type: 'application/json'
    }));
    formData.append('image', image);
    console.log(data);

    return this.http.post(`${this.apiUrl}/employee/create`, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
      responseType: 'text'
    });
  }

  importExcel(file: any) {
    const formData = new FormData();
    formData.append('file', file)
    return this.http.post(`${this.apiUrl}/employee/create`, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
      responseType: 'text'
    });
  }

  updateById(id: number | undefined, data: employee, image: string | Blob) {
    data.status = 1;
    const formData = new FormData();
    formData.append('id', String(id))
    formData.append('employee', new Blob([
      JSON.stringify(data)
    ], {
      type: 'application/json'
    }));
    formData.append('image', image)

    return this.http.post(`${this.apiUrl}/employee/update?id=${id}`, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
      responseType: 'text'
    });
  }

  deleteById(id: number[] | undefined) {
    return this.http.post(`${this.apiUrl}/employee/delete`, id, {
      headers: {
        Authorization: `Bearer ${this.token}`
      },
      responseType: 'text',
    })
  }

  countEmployee(): Observable<number> {
    return this.http.get<number>(`${this.apiUrl}/employee/countEmployee`, this.httpOptions);
  }

  countEmployeeActiveByYear(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/employee/count-employee-active-by-year`, this.httpOptions);
  }
  countEmployeeUnActiveByYear(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/employee/count-employee-un-active-by-year`, this.httpOptions);
  }
}
