import { environment } from 'src/environments/environment';

export class UrlUtils {
  static getUrl(currentPath: string): string {
    const urlPrefix = environment.URL_PREFIX;
    if ((urlPrefix == null || urlPrefix.length === 0) && currentPath === '/') {
      return `${currentPath}`;
    }

    return this.trimSpash(
      `${this.trimSpash(urlPrefix)}/${this.trimSpash(currentPath)}`
    );
  }

  static trimSpash(path: string): string {
    return path.replace(/^\/+|\/+$/gm, '');
  }
}
