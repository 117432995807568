import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {contactData} from "../../models/contact";
import {HttpErrorResponse} from "@angular/common/http";
import {MessageService} from "primeng/api";
import {ContactService} from "../../services/contact.service";
import * as bootstrap from 'bootstrap';


@Component({
  selector: 'app-apply-dialog',
  templateUrl: './apply-dialog.component.html',
  styleUrls: ['./apply-dialog.component.css']
})
export class ApplyDialogComponent implements OnInit {

  @Input() title: string | undefined;
  @Input() recruitmentId: number | undefined;
  @Output() confirmed = new EventEmitter<void>()

  contact: contactData = {
    name: '',
    email: '',
    phoneNumber: '',
    subject: '',
    content: '',
    recruitmentId: undefined,
  }

  contactValid: contactData = {
    name: 'name',
    email: 'email',
    phoneNumber: 'phoneNumber',
    subject: '',
    content: '',
    recruitmentId: undefined,
  }
  fileValid = true
  emailValid = true;
  phoneNumberValid = true;
  file: File | undefined;

  constructor(
    private contactService: ContactService,
    private messageService: MessageService,
  ) {}

  ngOnInit(): void {}

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      if(this.validateFile(input.files[0])) {
        this.file = input.files[0];
        this.fileValid = true;
        this.handleFile(input.files[0]);
      }
    }
  }

  onFileDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    const dropZone = event.currentTarget as HTMLElement;
    dropZone.classList.remove('active');

    if (event.dataTransfer?.files && event.dataTransfer.files.length > 0) {
      if(this.validateFile(event.dataTransfer.files[0])) {
        this.file = event.dataTransfer.files[0];
        this.fileValid = true;
        this.handleFile(event.dataTransfer.files[0]);
      }
    }
  }

  handleFile(file: File): void {
    console.log('File selected:', file.name);
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    const dropZone = event.currentTarget as HTMLElement;
    dropZone.classList.add('active');
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    const dropZone = event.currentTarget as HTMLElement;
    dropZone.classList.remove('active');
  }

  deleteFile(event: MouseEvent) {
    event.stopPropagation();
    this.file = undefined;
  }

  onCancel() {
    this.file = undefined;
    this.contact = {
      name: '',
      email: '',
      phoneNumber: '',
      subject: '',
      content: '',
      recruitmentId: undefined,
    }
  }

  validateData(data: contactData): boolean {
    this.contactValid = data;
    this.emailValid = this.phoneNumberValid = true;
    this.fileValid = this.file ? true : false;
    if (this.fileValid && data.name && data.email && data.phoneNumber && data.recruitmentId) {
      if (!data.email.match('^([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$')) {
        this.emailValid = false;
      }
      if (!data.phoneNumber.match('^(0?)(3[2-9]|5[6|8|9]|7[0|6-9]|8[0-6|8|9]|9[0-4|6-9])[0-9]{7}$')) {
        this.phoneNumberValid = false;
      }
      if (this.emailValid && this.phoneNumberValid) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  }

  onSubmit(): void {
    this.contact.recruitmentId = this.recruitmentId;
    if (this.validateData(this.contact)) {
      this.contactService.createContact(this.contact, this.file).subscribe({
        next: () => {
          this.onCancel();
          this.closeDialog();
          this.messageService.add({
            severity: 'success',
            summary: 'Thành công',
            detail: 'Hồ sơ ứng tuyển đã được gửi đi!'
          })
        },
        error: (err: HttpErrorResponse) => {
          console.log(err);
          this.messageService.add({
            severity: 'error',
            summary: 'Thất bại',
            detail: 'Đã có lỗi xảy ra!'
          })
        }
      })
    } else {
      this.messageService.add({
        severity: 'warn',
        summary: 'Cảnh báo',
        detail: 'Vui lòng nhập đúng, đủ thông tin!'
      })
    }
  }

  closeDialog(): void {
    const modal = document.getElementById('applyDialogModal');
    if (modal) {
      const bootstrapModal =  bootstrap.Modal.getInstance(modal);
      if (bootstrapModal) {
        document.querySelector('.modal-backdrop')?.remove();
        bootstrapModal.hide();
      }
    }
  }

  validateFile(file: File) : boolean {
    // Kiểm tra định dạng
    const validExtensions = ['doc', 'docx', 'pdf'];
    const fileExtension = file.name.split('.').pop()?.toLowerCase();

    // Kiểm tra kích thước (dưới 5MB)
    const maxSizeInMB = 5;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

    if (fileExtension && validExtensions.includes(fileExtension)) {
      if (file.size >= maxSizeInBytes) {
        this.messageService.add({
          severity: 'error',
          summary: 'Thất bại',
          detail: 'Kích thước file vượt quá giới hạn 5MB!'
        })
        return false;
      }
      return true;
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Thất bại',
        detail: 'Định dạng file không hợp lệ. Chỉ chấp nhận file .doc, .docx,' +
          ' .pdf!'
      })
      return false;
    }
  }
}
