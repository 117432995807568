<div class="container-fluid p-4">
  <p-toast></p-toast>
  <p-confirmDialog [style]="{ width: '50vw' }" [baseZIndex]="10000"></p-confirmDialog>
  <!-- Alert -->
  <div>
    <div class="fixed-bottom d-flex justify-content-center">
      <div [hidden]="selectedValue.length === 0" class="alert alert-danger w-50" role="alert">
        <div class="d-flex justify-content-between align-items-center gap-2">
          <p style="margin-bottom: 0">
            Đã chọn {{ selectedValue.length }} phần tử
          </p>
          <div>
            <button
            class="btn btn-light px-4 cursor-pointer me-3"
            (click)="onCheckBox()">
            Hủy
          </button>
          <button class="btn btn-danger px-4 cursor-pointer" (click)="onDelete(selectedValue)">
            Xóa
          </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Header -->
  <div class="d-flex justify-content-between">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a class="text-dark text-decoration-none" routerLink="/admin">Admin</a>
        </li>
        <li class="breadcrumb-item">
          <a class="text-dark text-decoration-none" routerLink="/organization">Tài khoản</a>
        </li>
      </ol>
    </nav>
    <div class="d-flex gap-2">
      <button
        class="p-button-success p-button-sm"
        pButton
        type="button"
        icon="pi pi-download"
        iconPos="left"
        (click)="onDownloadTemplate()"
        label="Download Template"
      >
      </button>
      <button class="btn btn-success" data-bs-toggle="modal" data-bs-target="#import-modal">
        <p class="d-flex align-items-center gap-2 text-white m-0">
          <span class="material-symbols-outlined"> drive_folder_upload </span>
          Import excel
        </p>
        <input type="file" name="file" [hidden]="true" />
      </button>
      <button (click)="onCancel()" class="d-flex gap-2 btn btn-primary" data-bs-toggle="modal" data-bs-target="#add-modal">
        <i class="material-symbols-outlined"> person_add </i>
        Thêm mới
      </button>
    </div>
  </div>
  <div class="table-wrapper my-4">
    <!-- Table -->
    <div class="table-responsive border border-bottom-0 rounded-top">
      <div class="table-header bg-light rounded-top">
        <div class="d-flex justify-content-between align-items-center p-2">
          <div class="d-flex gap-2">
            <select name="filterValue" class="form-select" style="max-width: 200px" (change)="onFilter($event)">
              <option [value]="1" selected>Lọc theo</option>
              <option [value]="0">Tất cả</option>
              <option [value]="1">Hoạt động</option>
              <option [value]="-1">Không hoạt động</option>
            </select>
            <select class="form-select" style="max-width: 200px" (change)="onSort($event)">
              <option value="">Sắp xếp theo</option>
              <option value="id_asc">id tăng dần</option>
              <option value="id_desc">id giảm dần</option>
              <option value="name_asc">Từ a - z</option>
              <option value="name_desc">Từ z - a</option>
              <option value="create_desc">Tài khoản mới thêm</option>
              <option value="update_desc">Tài khoản mới sửa</option>
            </select>
          </div>
          <input type="text" class="form-control" #searchTerm placeholder="Tìm kiếm..." style="max-width: 260px"
            (keyup)="onSearchChange(searchTerm.value)" />
        </div>
      </div>
      <table class="table table-main">
        <thead>
          <tr>
            <th><input [checked]="checkAll" type="checkbox" name="" class="form-check-input" (change)="onCheckAll($event)" /></th>
            <th class="text-secondary">STT</th>
            <th class="text-secondary">Tên tài khoản</th>
            <th class="text-secondary">Trạng thái</th>
            <th class="text-secondary">Ngày tạo</th>
            <th class="text-secondary">Ngày sửa</th>
            <th class="text-secondary">Thao tác</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let user of users; index as index">
            <td>
              <input [checked]="checked" type="checkbox" class="form-check-input" [value]="user.id" [checked]="checkedCheckBox[index]" (change)="onCheckboxValue($event, index)" />
            </td>
            <td>{{ (pageNumber * 10) + index + 1 }}</td>
            <td>{{ user.username }}</td>
            <td>
              <div class="d-inline-block bg-opacity-25 p-2 rounded" [ngClass]="{
                  'text-success bg-success': user.status === 1,
                  'text-danger bg-danger ': user.status === -1,
                  'text-warning bg-warning': user.status === null
                }">
                {{ user.status === 1 ? "Hoạt động" : "Không hoạt động" }}
              </div>
            </td>
            <td>{{ user.createTime | date: "dd/MM/yyyy" }}</td>
            <td>{{ user.updateTime | date: "dd/MM/yyyy" }}</td>
            <td>
              <i class="material-symbols-outlined" data-bs-toggle="modal" data-bs-target="#edit-modal"
                (click)="getUserById(user.id)" [hidden]="user.status === -1">
                edit
              </i>
              <i class="material-symbols-outlined mx-2" (click)="onDelete(user.id)" [hidden]="user.status === -1">
                delete
              </i>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="table-footer d-flex align-items-center border-bottom">
        <a [hidden]="totalItem === 0" class="ms-3" style="text-decoration: none">Hiển thị từ {{(pageNumber * 10) + 1}} - {{(pageNumber * 10)+numberOfElements}}/{{totalItem}} tài khoản</a>
        <a [hidden]="totalItem > 0" class="ms-3" style="text-decoration: none">Không có dữ liệu</a>
        <div style="width: 70%; float: right;">
          <p-paginator
          [rows]="itemPerPage"
          [pageLinkSize]="totalPage"
          [totalRecords]="totalItem"
          (onPageChange)="onPageChange($event)"
        ></p-paginator>
        </div>
    </div>
  </div>

  <!-- Add-modal -->
  <div class="modal fade" id="add-modal" tabindex="-1" aria-labelledby="add-modal" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="add-modal">Thêm mới tài khoản</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form #addForm="ngForm" enctype="multipart/form-data">
            <div>
              <label for="username">Tên tài khoản <span style="color: red">*</span></label>
              <input type="text" name="username" pattern="^[a-zA-Z0-9]+$" [(ngModel)]="user.username" #username="ngModel"
                class="form-control my-1" placeholder="nhập tên tài khoản" required />
            </div>
            <div *ngIf="username.invalid && (username.dirty || username.touched)">
              <p class="text-danger">Vui lòng nhập tên tài khoản và viết liền không dấu</p>
            </div>
            <div class="my-2">
              <label for="password">Mật khẩu <span style="color: red">*</span></label>
              <div class="input-group my-2">
                <input [type]="hidePass ? 'password' : 'text'" name="password" [(ngModel)]="user.password" #password="ngModel"
                class="form-control" placeholder="nhập mật khẩu" pattern="^[a-zA-Z0-9]+$" tabindex="2" required />
                <span class="show-btn input-group-text" id="show-btn" (click)="showPassword()" style="background-color: #fff;"><i [ngClass]="hidePass ? 'fas fa-eye' : 'fas fa-eye-slash'"></i></span>
              </div>
            </div>
            <div *ngIf="password.invalid && (password.dirty || password.touched)">
              <p class="text-danger">Vui lòng nhập mật khẩu và viết liền không dấu</p>
            </div>
            <div class="my-2">
              <label for="repassword">Nhập lại mật khẩu <span style="color: red">*</span></label>
              <div class="input-group my-2">
                <input [type]="hideRepassword ? 'password' : 'text'" name="repassword" [(ngModel)]="user.repassword" #repassword="ngModel" pattern="^[a-zA-Z0-9]+$"
                class="form-control" placeholder="nhập lại mật khẩu" required />
                <span class="show-btn input-group-text" id="show-btn" (click)="showRePassword()" style="background-color: #fff;"><i [ngClass]="hideRepassword ? 'fas fa-eye' : 'fas fa-eye-slash'"></i></span>
              </div>
            </div>
            <div *ngIf="repassword.invalid && (repassword.dirty || repassword.touched)">
              <p class="text-danger">Vui lòng nhập lại mật khẩu và viết liền không dấu</p>
            </div>
            <div *ngIf="repassword.value && repassword.value.length > 0 && repassword.value !== password.value">
              <p class="text-danger">Mật khẩu chưa trùng khớp!!</p>
            </div>
          </form>
          <div class="py-2">
            <label for="idRole">Vai trò <span style="color: red">*</span></label>
            <select name="idRole" [(ngModel)]="role.id" #idRole="ngModel" class="form-select my-2" required>
              <option [value]="0" disabled>Chọn vai trò</option>
              <option *ngFor="let role of roles" [value]="role.id">
                {{ role.name }}
              </option>
            </select>
          </div>
          <div *ngIf="
            idRole.invalid &&
              (idRole.dirty || idRole.touched)
            ">
            <p class="text-danger" *ngIf="idRole.errors?.['required']">
              Vui lòng chọn vai trò
            </p>
          </div>
          <div class="d-flex justify-content-end gap-2">
            <button class="btn btn-outline-secondary" (click)="resetForm()"  data-bs-dismiss="modal">
              Hủy
            </button>
            <button [disabled]="addForm.invalid || idRole.value === 0 || repassword.value !== password.value" type="submit" class="btn btn-primary" data-bs-dismiss="modal"
              (click)="onSubmit(addForm.value)">
              Lưu
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Edit-modal -->
  <div class="modal fade" id="edit-modal" tabindex="-1" aria-labelledby="edit-modal" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="edit-modal">Sửa tài khoản</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form #formEdit="ngForm" enctype="multipart/form-data">
            <div class="my-2">
              <label for="username">Tên tài khoản</label>
              <input type="text" disabled="true" name="username" [value]="userDetail.username" [(ngModel)]="userDetail.username"
                class="form-control my-2" placeholder="nhập tên tài khoản" />
            </div>
            <div class="my-2">
              <label for="passwordnew">Mật khẩu mới <span style="color: red">*</span></label>
              <div class="input-group my-2">
                <input [type]="hidePassNew ? 'password' : 'text'" pattern="^[a-zA-Z0-9]+$" name="password" [(ngModel)]="userDetail.password" #passwordnew="ngModel"
                class="form-control" placeholder="nhập mật khẩu mới" required />
                <span class="show-btn input-group-text" id="show-btn" (click)="showPasswordNew()" style="background-color: #fff;"><i [ngClass]="hidePassNew ? 'fas fa-eye' : 'fas fa-eye-slash'"></i></span>
              </div>
            </div>
            <div *ngIf="passwordnew.invalid && (passwordnew.dirty || passwordnew.touched)">
              <p class="text-danger">Vui lòng nhập mật khẩu mới và viết liền không dấu</p>
            </div>
            <div class="my-2">
              <label for="repasswordnew">Nhập lại mật khẩu mới <span style="color: red">*</span></label>
              <div class="input-group my-2">
                <input [type]="hideRepasswordNew ? 'password' : 'text'" pattern="^[a-zA-Z0-9]+$" name="repasswordnew" [(ngModel)]="userDetail.repassword" #repasswordnew="ngModel"
                class="form-control" placeholder="nhập mật khẩu" required />
                <span class="show-btn input-group-text" id="show-btn" (click)="showRePasswordNew()" style="background-color: #fff;"><i [ngClass]="hideRepasswordNew ? 'fas fa-eye' : 'fas fa-eye-slash'"></i></span>
              </div>
            </div>
            <div *ngIf="repasswordnew.invalid && (repasswordnew.dirty || repasswordnew.touched)">
              <p class="text-danger">Vui lòng nhập lại mật khẩu mới và viết liền không dấu</p>
            </div>
            <div *ngIf="repasswordnew.value && repasswordnew.value.length > 0 && repasswordnew.value !== passwordnew.value">
              <p class="text-danger">Mật khẩu chưa trùng khớp!!</p>
            </div>
          </form>
          <div class="py-2">
            <label for="idRoleNew">Vai trò</label>
            <select name="idRoleNew" [(ngModel)]="role.id" #idRoleNew="ngModel" class="form-select my-2" required>
              <option [value]="0" disabled>Chọn vai trò</option>
              <option *ngFor="let role of roles" [value]="role.id">
                {{ role.name }}
              </option>
            </select>
          </div>
          <div *ngIf="
          idRoleNew.invalid &&
              (idRoleNew.dirty || idRoleNew.touched)
            ">
            <p class="text-danger" *ngIf="idRoleNew.errors?.['required']">
              Vui lòng chọn vai trò
            </p>
          </div>
          <div class="d-flex justify-content-end gap-2">
            <button class="btn btn-outline-secondary" data-bs-dismiss="modal">
              Hủy
            </button>
            <button [disabled]="formEdit.invalid || repasswordnew.value !== passwordnew.value" type="submit" class="btn btn-primary" data-bs-dismiss="modal"
              (click)="onEdit(userDetail.id, formEdit.value, idRoleNew.value)">
              Lưu
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Import-modal -->
  <div class="modal fade" id="import-modal" tabindex="-1" aria-labelledby="import-modal" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="import-modal">Import excel</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form #formImport="ngForm" enctype="multipart/form-data">
            <div class="py-2">
              <label for="file">File excel</label>
              <input #inputImport type="file" name="file" class="form-control my-2" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              (change)="onSelectedFile($event)" />
            </div>
            <div class="d-flex justify-content-end gap-2">
              <button class="btn btn-outline-secondary" data-bs-dismiss="modal">
                Hủy
              </button>
              <button [disabled]="inputImport.value === ''" type="submit" class="btn btn-primary" data-bs-dismiss="modal"
                (click)="onUploadFile(inputImport)">
                Lưu
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
