import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({ providedIn: 'root' })
export class ToastService {
  constructor(private messageService: MessageService) {}

  success(summary: string, message: string) {
    this.showMessage('success', summary, message);
  }

  error(summary: string, message: string) {
    this.showMessage('error', summary, message);
  }

  info(summary: string, message: string) {
    this.showMessage('info', summary, message);
  }

  warn(summary: string, message: string) {
    this.showMessage('warn', summary, message);
  }

  showMessage(type: string, summary: string, message: string) {
    this.messageService.add({
      severity: type,
      summary: summary,
      detail: message
    });
  }
}
