import { Router, Routes } from '@angular/router';
import MENUS from './menus.json';
import { UrlUtils } from './url.util';
import { environment } from 'src/environments/environment';

export interface Menu {
  link: string;
  icon: string;
  title: string;
}

export interface PathAndRole {
  path: string;
  roles: string[];
}

export class MenuUtils {
  public static getMenus(router: Router, scope: string[] | null): Menu[] {
    if (scope != null) {
      const pathAndRoles = this.getPathAndRole(router.config);

      return MENUS.filter((x) => {
        x.link = x.link.replace(`/${environment.URL_PREFIX}`, '');

        const matchPath = pathAndRoles.find(
          (y) => UrlUtils.trimSpash(y.path) === UrlUtils.getUrl(x.link)
        );

        if (matchPath == null || matchPath.roles == null) return false;

        const matchRole = matchPath.roles.filter((x) => {
          return scope.indexOf(x) !== -1;
        });

        return matchRole !== null && matchRole.length > 0;
      }).map((x) => {
        x.link = `/${UrlUtils.getUrl(x.link)}`;
        return x;
      });
    }

    return [];
  }

  private static getPathAndRole(
    config: Routes,
    parentString: string = ''
  ): PathAndRole[] {
    let result: PathAndRole[] = [];
    for (let i = 0; i < config.length; i++) {
      const route = config[i];

      const item: PathAndRole = {
        path: parentString + '/' + route.path,
        roles: route.data?.['role'],
      };
      result.push(item);

      if (route.children) {
        const currentPath = route.path
          ? parentString + '/' + route.path
          : parentString + '';

        result = result.concat(
          this.getPathAndRole(route.children, currentPath)
        );
      }
    }

    return result;
  }
}
