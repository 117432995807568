import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { news } from '../models/news';
import { events } from '../models/news';
import { response } from '../models/response';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class NewsService {
  private apiUrl = environment.apiUrl;
  private token = this.authService.getToken();
  private httpOptions = {
    headers: {
      Authorization: `Bearer ${this.token}`
    }
  }

  constructor(
    private authService: AuthService,
    private http: HttpClient
  ) { }

  getListNews(page: number): Observable<response<news[]>> {
    return this.http.get<response<news[]>>(`${this.apiUrl}/news/select?page=${page}`, this.httpOptions)
  }

  getUpcomingEvents(): Observable<events[]> {
    return this.http.get<events[]>(`${this.apiUrl}/news/upcomingEvents`, this.httpOptions)
  }

  selectAll(page: number, status : number, search: string, sort: string): Observable<response<news[]>> {
    return this.http.get<response<news[]>>(`${this.apiUrl}/news/select?page=${page}&status=${status}&search=${search}&sort=${sort}`, this.httpOptions)
  }

  searchNews(keyword: string): Observable<response<news[]>> {
    return this.http.get<response<news[]>>(`${this.apiUrl}/news/select?search=${keyword}`, this.httpOptions)
  }

  filterNews(status: number): Observable<response<news[]>> {
    return this.http.get<response<news[]>>(`${this.apiUrl}/news/select?status=${status}`, this.httpOptions);
  }

  sortNews(order: string): Observable<response<news[]>> {
    return this.http.get<response<news[]>>(`${this.apiUrl}/news/select?sort=${order}`, this.httpOptions);
  }

  getNews(id: number | undefined): Observable<news> {
    return this.http.get<news>(`${this.apiUrl}/news/selectOne?id=${id}`, this.httpOptions)
  }

  getNewsBySlug(slug: string): Observable<news> {
    return this.http.get<news>(`${this.apiUrl}/news/selectSlug?slug=${slug}`, this.httpOptions)
  }

  createNews(data: news, image: any) {
    const formData = new FormData();
    formData.append('news', new Blob([
      JSON.stringify(data)
    ], {
      type: 'application/json'
    }));
    formData.append('image', image)
    return this.http.post(`${this.apiUrl}/news/create`, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`
      },
      responseType: 'text'
    })
  }

  editNewsById(id: number | undefined, data: news, image: string | Blob) {
    const formData = new FormData();
    formData.append('news', new Blob([
      JSON.stringify(data)
    ], {
      type: 'application/json'
    }));
    formData.append('image', image)
    return this.http.post(`${this.apiUrl}/news/update?id=${id}`, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`
      },
      responseType: 'text'
    })
  }

  deleteNewsById(id: number[] | undefined) {
    return this.http.post(`${this.apiUrl}/news/delete`, id, {
      headers: {
        Authorization: `Bearer ${this.token}`
      },
      responseType: 'text'
    })
  }

  countNews(): Observable<number> {
    return this.http.get<number>(`${this.apiUrl}/news/countNews`, this.httpOptions);
  }

}
