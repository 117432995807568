<div>
  <p-toast></p-toast>
  <app-header></app-header>

  <!-- SUPPORTER BLOCK -->
  <div class="container">
    <div>
      <h2 class="contact-title text-center">Hỗ trợ từ chúng tôi</h2>
      <div id="card" class="d-flex justify-content-evenly text-center pt-4">
        <button id="supporter_block" pButton pRipple type="button"
          class="p-button-raised p-button-text p-button-plain mb-0" (click)="onClickInfo($event)">
          <div class="sb_body">
            <i class="material-symbols-outlined">Apartment</i>
            <h5>Thông tin liên hệ</h5>
            <p>
              Các thông tin liên quan của trụ sở chính Smart Solutions.
            </p>
          </div>
          <div class="sb_footer text-secondary"><i class="pi pi-chevron-down"></i></div>
        </button>
        <button id="supporter_block" pButton pRipple type="button" class="p-button-raised p-button-text p-button-plain"
          (click)="onClickEmail($event)">
          <div class="sb_body">
            <i class="material-symbols-outlined">Mail</i>
            <h5>Email cho chúng tôi</h5>
            <p>
              Hãy gửi email cho chúng tôi để nhận phản hồi & tư vấn thắc mắc
            </p>
          </div>
          <div class="sb_footer text-secondary"><i class="pi pi-chevron-down"></i></div>
        </button>
        <button id="supporter_block" pButton pRipple class="p-button-raised p-button-text p-button-plain"
          (click)="onClickSupport($event)">
          <div class="sb_body">
            <i class="material-symbols-outlined">Question_Answer</i>
            <h5>Hỗ trợ trực tuyến</h5>
            <p>
              Sẵn sàng tư vấn và giải đáp mọi thắc mắc trực tuyến của bạn
            </p>
          </div>
          <div class="sb_footer text-secondary"><i class="pi pi-chevron-down"></i></div>
        </button>
      </div>
      <div class="row">
        <div id="contact_info" class="col-4"></div>
        <div id="send_email" class="col-4"></div>
        <div id="online_support" class="col-4"></div>
      </div>
    </div>
    <div *ngIf="this.selectValue == 1" class="contact-infor">
      <h2 class="text-center">Trụ sở chính</h2>
      <div class="contact-infor-block row">
        <div class="py-2 col-6">
          <h5 class="pb-2">Trụ sở chính</h5>
          <span>Tầng 4,số 11 Ngõ 21 Đường Lê Văn Lương, Nhân Chính, Thanh Xuân, Hà Nội, Việt Nam</span>
          <div class="py-2">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d465.585112651816!2d105.80716499445647!3d21.005424588137434!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135ac98cd393721%3A0x321f74659498dcf!2zMTEgTmcuIDIxIMSQLiBMw6ogVsSDbiBMxrDGoW5nLCBOaMOibiBDaMOtbmgsIFRoYW5oIFh1w6JuLCBIw6AgTuG7mWksIFZp4buHdCBOYW0!5e0!3m2!1svi!2s!4v1726191340344!5m2!1svi!2s"
              width="450" height="200" style="border:0;" allowfullscreen="" loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
        <div class="py-2 col-6">
          <h5 class="pb-2">Hỗ trợ</h5>
          <span>Hãy liên hệ với Smart Solutions để chúng tôi có thể tư vấn trực tiếp cho bạn về sản phẩm và
            dịch vụ bạn quan tâm. Chúng tôi luôn sẵn lòng lắng nghe và hỗ trợ bạn !</span>
          <div class="d-flex align-items-center py-2">
            <i class="pi pi-phone"></i> &nbsp; 0384376297
          </div>
          <div class="d-flex align-items-center">
            <i class="pi pi-envelope"></i> &nbsp; hrsmartsolutionsvn@gmail.com
          </div>
        </div>
      </div>

    </div>

    <!-- FORM GỬI MAIL  -->
    <div *ngIf="this.selectValue == 2" class="contact_email">
      <h2 class="text-center">Gửi email cho chúng tôi</h2>
      <form class="email-us-form" #formAdd="ngForm" (submit)="onSubmit(formAdd.value)">
        <div class="py-2">
          <label for="name">Họ và tên<span style="color: red">*</span></label>
          <input type="text" pInputText class="p-inputtext-lg" name="name" class="form-control my-2"
            placeholder="Nhập họ tên của bạn" #name="ngModel" [(ngModel)]="contact.name" />
        </div>
        <!-- <div *ngIf="name.invalid && (name.dirty || name.touched)">
                  <p class="text-danger" *ngIf="name.errors?.['required']">
                    Vui lòng nhập họ và tên
                  </p>
                </div> -->
        <div class="row">
          <div class="py-2 col-6">
            <label for="email">Email<span style="color: red">*</span></label>
            <input type="email" pInputText class="p-inputtext-lg" name="email" class="form-control my-2"
              placeholder="Nhập địa chỉ email" #email="ngModel" [(ngModel)]="contact.email" />
          </div>
          <div class="py-2 col-6">
            <label for="phoneNumber">Điện thoại di động<span style="color: red">*</span></label>
            <input type="text" pInputText class="p-inputtext-lg" name="phoneNumber" class="form-control my-2"
              placeholder="Nhập số điện thoại" #phoneNumber="ngModel" [(ngModel)]="contact.phoneNumber" />
          </div>
        </div>
        <div class="py-2">
          <label for="subject">Tiêu đề Email<span style="color: red">*</span></label>
          <input type="text" pInputText class="p-inputtext-lg" name="subject" class="form-control my-2"
            placeholder="Nhập tiêu đề email" #subject="ngModel" [(ngModel)]="contact.subject" />
        </div>
        <div class="py-2">
          <label for="content">Nội dung<span style="color: red">*</span></label>
          <textarea name="content" pInputTextarea class="form-control my-2" [cols]="0" [rows]="10"
            placeholder="Nhập nội dung email" #content="ngModel" [(ngModel)]="contact.content"></textarea>
        </div>
        <div class="contact-email-note py-2">
          <hr>
          <p>Yêu cầu hỗ trợ của Quý khách sẽ được tiếp nhận xử lý theo quy định hiện hành của Smart Solutions.
            Trường hợp có yêu cầu hỗ trợ khẩn cấp, Quý khách vui lòng liên hệ tổng đài của Trung tâm Chăm
            sóc khách hàng Smart Solutions theo hotline (+84) 986 788 882 / (+84) 988 042 000.</p>
        </div>
        <div class="d-flex justify-content-end gap-2">
          <p-button type="submit" label="Gửi đi"></p-button>
        </div>
      </form>
    </div>
    <div *ngIf="this.selectValue == 3" class="contact-support_online">
      <h2 class="text-center">Hỗ trợ trực tuyến</h2>
      <div class="alert alert-primary d-flex align-items-center" style="margin: 20px 25px;" role="alert">
        <i class="pi pi-info-circle"></i> &nbsp; Tính năng này sắp được ra mắt trong thời gian tới !
      </div>
    </div>
  </div>

  <!-- FOOTER -->
  <div>
    <app-footer></app-footer>
  </div>
</div>
