import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from './constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  private string: string;
  title: string = 'My Angular App';
  constructor(public translate: TranslateService) {
    translate.addLangs(['vi', 'en', 'js']);
    translate.setDefaultLang('vi');
    title: this.string = 'My Angular App'; // Khai báo thuộc tính title
    // const browserLang = translate.getBrowserLang();
    translate.use(Constants.getLang());
  }
}
