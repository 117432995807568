import { Component, OnInit } from '@angular/core';
import {
  RecruitmentInfoDto,
  searchRecruitment
} from "../../models/job";
import {JobListService} from "../../services/job-list.service";
import {response} from "../../models/response";
import {HttpErrorResponse} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-job-detail',
  templateUrl: './job-detail.component.html',
  styleUrls: ['./job-detail.component.css']
})
export class JobDetailComponent implements OnInit {

  jobs: RecruitmentInfoDto[] = [];
  jobDetail: RecruitmentInfoDto | null = null;
  itemPerPage = 5;
  pageNumber = 0;

  constructor(
    private jobListService: JobListService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const idParam = params.get('id');
      const id = idParam ? parseInt(idParam, 10) : undefined;
      this.getJobDetail(id);
    });
    this.loadRecruitments();
  }

  private getJobDetail(id: number | undefined): void {
    this.jobListService.getJobDetail(id).subscribe({
      next: (data: RecruitmentInfoDto) => {
        this.jobDetail = data;
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      }
    })
  }

  loadRecruitments(page: number = 0): void {
    const searchRecruitment: searchRecruitment = {
      pageNumber: page,
      pageSize: this.itemPerPage
    };
    this.searchRecruitments(searchRecruitment);
  }



  private searchRecruitments(params: searchRecruitment): void {
    this.jobListService.getRecruitments(params).subscribe({
      next: (response: response<RecruitmentInfoDto[]>) => {
        this.jobs = response.content;
      },
      error: (error) => this.handleError('Search failed', error)
    });
  }

  private handleError(message: string, error: any): void {
    console.error(`${message}:`, error);
    // You could add more sophisticated error handling here, like showing a user-friendly message
  }
}
