import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MenuUtils } from './menu.util';
import { Router } from '@angular/router';
import {AuthService} from "./services/auth.service";

@Injectable({ providedIn: 'root' })
export class MenuService {
  menu$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  constructor(private authService: AuthService, private router: Router) {
    this.updateMenu();
  }

  updateMenu() {
    try {
      const menus = MenuUtils.getMenus(
        this.router,
        this.authService.getScopes()
      );
      console.log('menu', menus, this.authService.getScopes());

      this.menu$.next(menus);
    } catch (e) {
      console.error(e);
    }
  }
}
