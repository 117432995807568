<div class="container-fluid p-4">
  <p-toast></p-toast>
  <p-confirmDialog
    [style]="{ width: '50vw' }"
    [baseZIndex]="10000"
  ></p-confirmDialog>
  <!-- Alert -->
  <div>
    <div class="fixed-bottom d-flex justify-content-center">
      <div
        [hidden]="selectedValue.length === 0"
        class="alert alert-danger w-50"
        role="alert"
      >
        <div class="d-flex justify-content-between align-items-center gap-2">
          <p style="margin-bottom: 0">
            Đã chọn {{ selectedValue.length }} phần tử
          </p>
          <div>
            <div>
              <button
              class="btn btn-light px-4 cursor-pointer me-3"
              (click)="onCheckBox()"
            >
              Hủy
            </button>
            <button
            class="btn btn-danger px-4 cursor-pointer"
            (click)="onDelete(selectedValue)"
          >
            Xóa
          </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Header -->
  <div class="d-flex justify-content-between">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a class="text-dark text-decoration-none" routerLink="/admin"
            >Admin</a
          >
        </li>
        <li class="breadcrumb-item">
          <a class="text-dark text-decoration-none" routerLink="/organization"
            >Nhân viên</a
          >
        </li>
      </ol>
    </nav>
    <div class="d-flex gap-2">
      <button
        class="p-button-success p-button-sm"
        pButton
        type="button"
        icon="pi pi-download"
        iconPos="left"
        (click)="onDownloadTemplate()"
        label="Download Template"
      >
      </button>
      <button
        class="btn btn-success"
        data-bs-toggle="modal"
        data-bs-target="#import-modal"
      >
        <p class="d-flex align-items-center gap-2 text-white m-0">
          <span class="material-symbols-outlined"> drive_folder_upload </span>
          Import excel
        </p>
        <input type="file" name="file" [hidden]="true" />
      </button>
      <button
        class="d-flex gap-2 btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#add-modal"
      >
        <i class="material-symbols-outlined"> person_add </i>
        Thêm mới
      </button>
    </div>
  </div>
  <!-- Table -->

  <div class="table-wrapper my-4">
    <div class="table-responsive border rounded">
      <div class="table-header bg-light rounded-top">
        <div class="d-flex justify-content-between align-items-center p-2">
          <div class="d-flex gap-2">
            <select
              (change)="onFilter($event)"
              class="form-select"
              style="max-width: 200px"
            >
              <option [value]="1">Lọc theo</option>
              <option [value]="0">Tất cả</option>
              <option [value]="1">Hoạt động</option>
              <option [value]="-1">Không hoạt động</option>
            </select>
            <select
              class="form-select"
              style="max-width: 200px"
              (change)="onSort($event)"
            >
              <option value="">Sắp xếp theo</option>
              <option value="id_asc">id tăng dần</option>
              <option value="id_desc">id giảm dần</option>
              <option value="name_asc">Từ a - z</option>
              <option value="name_desc">Từ z - a</option>
              <option value="create_desc">Nhân viên mới thêm</option>
              <option value="update_desc">Nhân viên mới sửa</option>
            </select>
          </div>
          <input
            type="text"
            class="form-control"
            style="max-width: 260px"
            placeholder="Tìm kiếm..."
            #searchInput
            (keyup)="onSearch(searchInput.value)"
          />
        </div>
      </div>
      <table class="table table-responsive table-body">
        <thead>
          <tr>
            <th>
              <input
              [checked]="checkAll"
                type="checkbox"
                name="id"
                class="form-check-input m-0"
                (change)="onCheckAll($event)"
                id=""
              />
            </th>
            <th class="text-secondary">STT</th>
            <th class="text-secondary">Tên nhân viên</th>
            <th class="text-secondary">Hình ảnh</th>
            <th class="text-secondary">Email</th>
            <th class="text-secondary">Địa chỉ</th>
            <th class="text-secondary">Số điện thoại</th>
            <th class="text-secondary">Trạng thái</th>
            <th class="text-secondary">Tên tài khoản</th>
            <th class="text-secondary">Tên tổ chức</th>
            <th class="text-secondary">Thao tác</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let employee of employees; index as index">
            <td>
              <input
                [checked]="checked"
                type="checkbox"
                class="form-check-input m-0"
                [value]="employee.id"
                [checked]="checkedCheckBox[index]"
                (change)="onCheckboxValue($event, index)"
              />
            </td>
            <td>{{ (pageNumber * 10) + index + 1 }}</td>
            <td class="text-truncate" style="max-width: 50px">
              {{ employee.name }}
            </td>
            <td>
              <img
                [src]="employee.image"
                style="width: 50px; height: 50px"
                class="img-fluid rounded-circle"
              />
            </td>

            <td class="text-truncate" style="max-width: 100px">
              {{ employee.email }}
            </td>
            <td class="text-truncate" style="max-width: 100px">
              {{ employee.address }}
            </td>
            <td>{{ employee.phoneNumber }}</td>
            <td>
              <div
                class="d-inline-block bg-success bg-opacity-25 text-success p-2 rounded"
                [ngClass]="{
                  'text-success bg-success': employee.status === 1,
                  'text-danger bg-danger ': employee.status === -1,
                  'text-warning bg-warning': employee.status === null
                }"
              >
                {{ employee.status === 1 ? "Hoạt động" : "Không hoạt động" }}
              </div>
            </td>
            <td>{{ employee.user?.username }}</td>
            <td>{{ employee.organizationId?.name }}</td>
            <td>
              <i
              [hidden]="employee.status === -1"
                class="material-symbols-outlined"
                data-bs-toggle="modal"
                data-bs-target="#edit-modal"
                (click)="getById(employee.id)"
              >
                edit
              </i>
              <i
              [hidden]="employee.status === -1"
                class="material-symbols-outlined mx-2"
                (click)="onDelete(employee.id)"
              >
                delete
              </i>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="table-footer d-flex align-items-center">
        <a [hidden]="totalItem===0" class="ms-3" style="text-decoration: none">Hiển thị từ {{(pageNumber * 10) + 1}} - {{(pageNumber * 10)+numberOfElements}}/{{totalItem}} nhân viên</a>
        <a [hidden]="totalItem > 0" class="ms-3" style="text-decoration: none">Không có dữ liệu</a>
        <div style="width: 70%; float: right;">
          <p-paginator
          [rows]="itemPerPage"
          [pageLinkSize]="totalPage"
          [totalRecords]="totalItem"
          (onPageChange)="onPageChange($event)"
        ></p-paginator>
        </div>
    </div>
  </div>

  <!-- Add-modal -->
  <div
    class="modal fade"
    id="add-modal"
    tabindex="-1"
    aria-labelledby="add-modal"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="add-modal">Thêm mới nhân viên</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form #formAdd="ngForm" enctype="multipart/form-data">
            <div class="py-2">
              <label for="name">Tên nhân viên <span style="color: red">*</span></label>
              <input
                type="text"
                name="name"
                [(ngModel)]="employee.name"
                #name="ngModel"
                class="form-control my-2"
                placeholder="nhập tên nhân viên"
                required
              />
            </div>
            <div *ngIf="name.invalid && (name.dirty || name.touched)">
              <p class="text-danger" *ngIf="name.errors?.['required']">
                Vui lòng nhập tên nhân viên
              </p>
            </div>
            <div class="py-2">
              <label for="email">Email <span style="color: red">*</span></label>
              <input
                type="email"
                name="email"
                [(ngModel)]="employee.email"
                #email="ngModel"
                class="form-control my-2"
                placeholder="nhập email"
                required
              />
            </div>
            <div *ngIf="email.invalid && (email.dirty || email.touched)">
              <p class="text-danger" *ngIf="email.errors?.['required']">
                Vui lòng nhập email
              </p>
            </div>
            <div class="py-2">
              <label for="email">Hình ảnh <span style="color: red">*</span></label>
              <input
                type="file"
                name="image"
                class="form-control"
                (change)="onChangeFile($event)"
              />
            </div>
            <div class="py-2">
              <label for="address">Địa chỉ <span style="color: red">*</span></label>
              <input
                type="text"
                name="address"
                [(ngModel)]="employee.address"
                #address="ngModel"
                class="form-control my-2"
                placeholder="nhập địa chỉ"
                required
              />
            </div>
            <div *ngIf="address.invalid && (address.dirty || address.touched)">
              <p class="text-danger" *ngIf="address.errors?.['required']">
                Vui lòng nhập địa chỉ
              </p>
            </div>
            <div class="py-2">
              <label for="phoneNumber">Số điện thoại <span style="color: red">*</span></label>
              <input
                type="text"
                name="phoneNumber"
                [(ngModel)]="employee.phoneNumber"
                #phoneNumber="ngModel"
                class="form-control my-2"
                placeholder="nhập số điện thoại"
                required
              />
            </div>
            <div
              *ngIf="
                phoneNumber.invalid &&
                (phoneNumber.dirty || phoneNumber.touched)
              "
            >
              <p class="text-danger" *ngIf="phoneNumber.errors?.['required']">
                Vui lòng nhập số điện thoại
              </p>
            </div>
            <div class="py-2">
              <label for="organizationId">Tổ chức <span style="color: red">*</span></label>
              <select
                name="organizationId"
                [(ngModel)]="employee.organizationId"
                #organizationId="ngModel"
                class="form-select my-2"
                required
              >
                <option [value]="0">Chọn tổ chức</option>
                <option
                  *ngFor="let organization of organizations"
                  [value]="organization.id"
                >
                  {{ organization.name }}
                </option>
              </select>
            </div>
            <div
              *ngIf="
                organizationId.invalid &&
                (organizationId.dirty || organizationId.touched)
              "
            >
              <p
                class="text-danger"
                *ngIf="organizationId.errors?.['required']"
              >
                Vui lòng chọn tên tổ chức
              </p>
            </div>
            <div class="py-2">
              <label for="userId">Tên tài khoản <span style="color: red">*</span></label>
              <select
                name="userId"
                [(ngModel)]="employee.user.id"
                #userId="ngModel"
                class="form-select my-2"
                required
              >
                <option [value]="0">Chọn tên tài khoản</option>
                <option *ngFor="let user of users" [value]="user.id">
                  {{ user.username }}
                </option>
              </select>
            </div>
            <div *ngIf="userId.invalid && (userId.dirty || userId.touched)">
              <p class="text-danger" *ngIf="userId.errors?.['required']">
                Vui lòng chọn tên tài khoản
              </p>
            </div>
            <div class="d-flex justify-content-end gap-2">
              <button class="btn btn-outline-secondary" data-bs-dismiss="modal">
                Hủy
              </button>
              <button
                [disabled]="formAdd.invalid"
                type="submit"
                class="btn btn-primary"
                data-bs-dismiss="modal"
                (click)="onSubmit(formAdd.value)"
              >
                Thêm Mới
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- Edit-modal -->
  <div
    class="modal fade"
    id="edit-modal"
    tabindex="-1"
    aria-labelledby="edit-modal"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="edit-modal">Sửa nhân viên</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form #formEdit="ngForm" enctype="multipart/form-data">
            <div class="py-2">
              <label for="name">Tên nhân viên</label>
              <input
                type="text"
                name="name"
                #name="ngModel"
                [(ngModel)]="employeeDetail.name"
                [value]="employeeDetail.name"
                class="form-control my-2"
                placeholder="nhập tên nhân viên"
              />
            </div>
            <div *ngIf="name.invalid && (name.dirty || name.touched)">
              <p class="text-danger" *ngIf="name.errors?.['required']">
                Vui lòng nhập tên
              </p>
            </div>
            <div class="py-2">
              <label for="image">Hình ảnh</label>
              <input
                type="file"
                name="image"
                class="form-control my-2"
                (change)="onChangeFile($event)"
              />
            </div>
            <div class="py-2">
              <label for="email">Email</label>
              <input
                type="email"
                name="email"
                #email="ngModel"
                [(ngModel)]="employeeDetail.email"
                [value]="employeeDetail.email"
                class="form-control my-2"
                placeholder="nhập email"
              />
            </div>
            <div *ngIf="email.invalid && (email.dirty || email.touched)">
              <p class="text-danger" *ngIf="email.errors?.['required']">
                Vui lòng nhập email
              </p>
            </div>
            <div class="py-2">
              <label for="address">Địa chỉ</label>
              <input
                type="text"
                name="address"
                #address="ngModel"
                [(ngModel)]="employeeDetail.address"
                [value]="employeeDetail.address"
                class="form-control my-2"
                placeholder="nhập địa chỉ"
              />
            </div>
            <div *ngIf="address.invalid && (address.dirty || address.touched)">
              <p class="text-danger" *ngIf="address.errors?.['required']">
                Vui lòng nhập địa chỉ
              </p>
            </div>
            <div class="py-2">
              <label for="phoneNumber">Số điện thoại</label>
              <input
                type="string"
                name="phoneNumber"
                #phoneNumber="ngModel"
                [(ngModel)]="employeeDetail.phoneNumber"
                [value]="employeeDetail.phoneNumber"
                class="form-control my-2"
                placeholder="nhập số điện thoại"
              />
            </div>
            <div
              *ngIf="
                phoneNumber.invalid &&
                (phoneNumber.dirty || phoneNumber.touched)
              "
            >
              <p class="text-danger" *ngIf="phoneNumber.errors?.['required']">
                Vui lòng nhập số điện thoại
              </p>
            </div>
            <div class="py-2">
              <label for="organizationId">Tổ chức</label>
              <select
                name="organizationId"
                class="form-select my-2"
                [(ngModel)]="employeeDetail.organizationId.id"
                [value]="employeeDetail.organizationId.id"
              >
                <option [value]="0">Chọn tổ chứcc</option>
                <option
                  *ngFor="let organization of organizations"
                  [value]="organization.id"
                >
                  {{ organization.name }}
                </option>
              </select>
            </div>
            <div
              *ngIf="
                organizationId.invalid &&
                (organizationId.dirty || organizationId.touched)
              "
            >
              <p
                class="text-danger"
                *ngIf="organizationId.errors?.['required']"
              >
                Vui lòng chọn tên tổ chức
              </p>
            </div>
            <div class="py-2">
              <label for="userId">Tên tài khoản</label>
              <select
                name="userId"
                class="form-select my-2"
                #userId="ngModel"
                [(ngModel)]="employeeDetail.user.id"
                [value]="employeeDetail.user.id"
              >
                <option [value]="0">Chọn tên tài khoản</option>
                <option *ngFor="let user of users" [value]="user.id">
                  {{ user.username }}
                </option>
              </select>
            </div>
            <div *ngIf="userId.invalid && (userId.dirty || userId.touched)">
              <p class="text-danger" *ngIf="userId.errors?.['required']">
                Vui lòng chọn tên tài khoản
              </p>
            </div>
            <div class="d-flex justify-content-end gap-2">
              <button class="btn btn-outline-secondary" data-bs-dismiss="modal">
                Hủy
              </button>
              <button
                type="submit"
                class="btn btn-primary"
                data-bs-dismiss="modal"
                [disabled]="formEdit.invalid"
                (click)="onEdit(employeeDetail.id, formEdit.value)"
              >
                Lưu
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- Import-modal -->
  <div
    class="modal fade"
    id="import-modal"
    tabindex="-1"
    aria-labelledby="import-modal"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="import-modal">Import excel</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form #formImport="ngForm" enctype="multipart/form-data">
            <div class="py-2">
              <label for="file">File excel</label>
              <input
                type="file"
                name="file"
                class="form-control my-2"
                (change)="onChangeFile($event)"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                #inputImport
              />
            </div>
            <div class="d-flex justify-content-end gap-2">
              <button class="btn btn-outline-secondary" data-bs-dismiss="modal">
                Hủy
              </button>
              <button
                type="submit"
                class="btn btn-primary"
                data-bs-dismiss="modal"
                [disabled]="inputImport.value === ''"
                (click)="onUploadFile(formImport.value, inputImport)"
              >
                Lưu
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
