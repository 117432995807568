<div class="modal fade" id="applyDialogModal" tabindex="-1"
     aria-labelledby="applyDialogModal" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="m-0 fw-bold">Ứng tuyển
            <span class="text-primary">{{ title }}</span></h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal"
                  aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <h6 class="fw-bold mt-2">
            <i class="pi pi-file-pdf text-primary"></i>
            Chọn CV để ứng tuyển
          </h6>

          <div class="file-upload-container">
            <input type="file" #fileInput (change)="onFileSelected($event)"
                   style="display: none;"/>

            <!-- Vùng kéo thả -->
            <div class="drop-zone"
                 (dragover)="onDragOver($event)"
                 (dragleave)="onDragLeave($event)"
                 (drop)="onFileDrop($event)"
                 (click)="fileInput.click()">
              <div *ngIf="file" class="h-25"></div>
              <div *ngIf="file === undefined" class="mt-5"></div>
              <div class="fw-bold">
                <i class="pi pi-cloud-upload pe-2"></i>
                <span>Tải lên CV từ máy tính,
              chọn hoặc kéo thả</span>
              </div>
              <span>Hỗ trợ định dạng .doc, .docx, pdf có kích thước dưới 5MB</span>
              <div *ngIf="file" class="file-info fw-bold">
                <span
                class="text-primary"><i
                class="pi pi-file pe-2"></i>{{ file.name }}</span>
                <button pButton pRipple type="button" icon="pi pi-trash"
                        class="p-button-rounded p-button-danger p-button-text ms-3"
                        (click)="deleteFile($event)"></button>
              </div>
            </div>
          </div>
          <small *ngIf="!fileValid" class="block text-danger">Chưa
            chọn file
          </small>
          <div class="row mt-4 mb-2">
            <div class="col-6">
            <span class="text-primary">Vui lòng nhập đủ thông
              tin sau:</span>
            </div>
          </div>
          <div class="field">
            <label for="username" class="block">Họ và tên<span
              class="text-danger">*</span>
            </label>
            <input id="username" type="username" class="col-12"
                   aria-describedby="username-help" pInputText
                   [(ngModel)]="contact.name"/>
            <small *ngIf="!contactValid.name" id="username-help"
                   class="block text-danger">Họ tên
              không được để trống</small>
          </div>

          <div class="row mt-4">
            <div class="col-6">
              <label for="email" class="form-label">Email<span
                class="text-danger">*</span></label>
            </div>
            <div class="col-6">
              <label for="phoneNumber" class="form-label">Số điện thoại<span
                class="text-danger">*</span></label>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <input id="email" type="email" class="form-control"
                     aria-describedby="email-help" pInputText
                     [(ngModel)]="contact.email"
                     (ngModelChange)="this.emailValid = true"/>
            </div>
            <div class="col-6">
              <input id="phoneNumber" type="phoneNumber" class="form-control"
                     aria-describedby="phoneNumber-help" pInputText
                     [(ngModel)]="contact.phoneNumber"
                     (ngModelChange)="this.phoneNumberValid = true"/>
            </div>
          </div>
          <div *ngIf="!contactValid.email || !contactValid.phoneNumber"
               class="row">
            <div class="col-6">
              <small *ngIf="!contactValid.email" id="email-help"
                     class="block text-danger">Email
                không được để trống</small>
            </div>
            <div class="col-6">
              <small *ngIf="!contactValid.phoneNumber" id="phoneNumber-help" class="block text-danger">Số
                điện thoại không được để trống</small>
            </div>
          </div>
          <div *ngIf="!emailValid || !phoneNumberValid"
               class="row">
            <div class="col-6">
              <small *ngIf="!emailValid" class="block text-danger">Email
                không đúng định dạng</small>
            </div>
            <div class="col-6">
              <small *ngIf="!phoneNumberValid" class="block text-danger">Số
                điện thoại không đúng định dạng</small>
            </div>
          </div>
          <div class="mt-4 pt-1 d-flex justify-content-end gap-2">
            <button pButton pRipple type="button" label="Hủy"
                    data-bs-dismiss="modal"
                    class="p-button-sm p-button-raised p-button-text p-button-plain"
                    (click)="onCancel()"></button>
            <button pButton pRipple type="button" label="Nộp hồ sơ ứng tuyển"
                    class="p-button-sm ms-2"
                    (click)="onSubmit()"
            ></button>
          </div>
          <ng-content></ng-content>
        </div>
      </div>
  </div>
</div>

