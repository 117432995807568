<div class="container-fluid p-4">
  <p-toast> </p-toast>
  <p-confirmDialog
    [style]="{ width: '50vw' }"
    [baseZIndex]="10000"
  ></p-confirmDialog>
  <!-- Alert -->
  <div>
    <div class="fixed-bottom d-flex justify-content-center">
      <div
        [hidden]="selectedValue.length === 0"
        class="alert alert-danger w-50"
        role="alert"
      >
        <div class="d-flex justify-content-between align-items-center gap-2">
          <p style="margin-bottom: 0">
            Đã chọn {{ selectedValue.length }} phần tử
          </p>
          <div>
            <button
            class="btn btn-light px-4 cursor-pointer me-3"
            (click)="onCheckBox()"
          >
            Hủy
          </button>

            <button
            class="btn btn-danger px-4 cursor-pointer"
            (click)="onDelete(selectedValue)"
          >
            Xóa
          </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Header -->
  <div class="d-flex justify-content-between">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a class="text-dark text-decoration-none" routerLink="/admin"
            >Admin</a
          >
        </li>
        <li class="breadcrumb-item">
          <a class="text-dark text-decoration-none" routerLink="/organization"
            >Sản Phẩm</a
          >
        </li>
      </ol>
    </nav>
    <div class="d-flex gap-2">
      <button
        class="d-flex gap-2 btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#add-modal"
      >
        <i class="material-symbols-outlined"> person_add </i>
        Thêm mới
      </button>
    </div>
  </div>
  <!-- Table -->
  <div class="table-wrapper mt-4">
    <div class="table-responsive border border-bottom-0 rounded-top">
      <div class="table-header rounded-top">
        <div
          class="d-flex bg-light justify-content-between align-items-center p-2"
        >
          <div class="d-flex gap-2">
            <select
            name="filterValue"
            class="form-select"
            style="max-width: 200px"
            (change)="onFilter($event)"
          >
            <option [value]="1" selected>Lọc theo</option>
            <option [value]="0">Tất cả</option>
            <option [value]="1">Hoạt động</option>
            <option [value]="-1">Không hoạt động</option>
          </select>

          <select
              class="form-select"
              style="max-width: 200px"
              (change)="onSort($event)"
            >
              <option value="">Sắp xếp theo</option>
              <option value="id_ASC">id tăng dần</option>
              <option value="id_DESC">id giảm dần</option>
              <option value="name_ASC">Từ a - z</option>
              <option value="name_DESC">Từ z - a</option>
              <option value="createTime_DESC">Sản phẩm mới thêm</option>
              <option value="updateTime_DESC">Sản phẩm mới sửa</option>
            </select>
          </div>
          <input
            type="text"
            name="searchValue"
            class="form-control"
            placeholder="Tìm kiếm..."
            style="max-width: 260px"
            #searchInput
            (input)="onSearch(searchInput.value)"
          />
        </div>
        <table class="table table-responsive table-body">
          <thead>
            <tr>
              <th>
                <input type="checkbox" [checked]="checkAll" name="" class="form-check-input" (change)="onCheckAll($event)" />
              </th>
              <th class="col text-secondary">STT</th>
              <th class="col text-secondary">Tên sản phẩm</th>
              <th class="col text-secondary">Tên tiếng việt của sản phẩm</th>
              <th class="col text-secondary">Ảnh nền</th>
              <th class="col text-secondary">Ảnh Avatar</th>
              <th class="col text-secondary">Trạng thái</th>
              <th class="col text-secondary">Thông tin</th>
              <th class="col text-secondary">Tính năng</th>
              <th class="col text-secondary">Ngày tạo</th>
              <th class="col text-secondary">Ngày sửa</th>
              <th class="col text-secondary">Người tạo</th>
              <th class="col text-secondary">Người sửa</th>
              <th class="col text-secondary">Thao tác</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let product of products; index as index">
              <td>
                <input
                  [checked]="checked"
                  type="checkbox"
                  name=""
                  class="form-check-input"
                  [value]="product.id"
                  [checked]="checkedCheckBox[index]"
                  (change)="onCheckedValue($event, index)"
                />
              </td>
              <td>{{ (pageNumber * 10) + index + 1 }}</td>
              <td>{{ product.name }}</td>
              <td>{{ product.detailShort }}</td>
              <td>
                <img [src]="product.backGroundImage" alt="" width="50" height="50" />
              </td>
              <td>
                <img [src]="product.avatarImage" alt="" width="50" height="50" />
              </td>
              <td>
                <div
                  class="d-inline-block bg-success bg-opacity-25 text-success p-2 rounded"
                  [ngClass]="{
                    'text-success bg-success': product.status === 1,
                    'text-danger bg-danger ': product.status === 0,
                    'text-warning bg-warning': product.status === null
                  }"
                >
                  {{ product.status === 1 ? "Hoạt động" : "Không hoạt động" }}
                </div>
              </td>
              <td class="text-truncate" style="max-width: 150px">
                {{ product.information }}
              </td>
              <td class="text-truncate" style="max-width: 150px">{{ product.feature }}</td>
              <td>{{ product.createTime | date: "dd/MM/yyyy" }}</td>
              <td>{{ product.updateTime | date: "dd/MM/yyyy" }}</td>
              <td>{{ product.createBy }}</td>
              <td>{{ product.updateBy }}</td>
              <td>
                <i
                [hidden]="product.status === -1"
                  class="material-symbols-outlined"
                  data-bs-toggle="modal"
                  data-bs-target="#edit-modal"
                  (click)="getById(product.id)"
                >
                  edit
                </i>
                <i
                [hidden]="product.status === -1"
                  class="material-symbols-outlined mx-2"
                  (click)="onDelete(product.id)"
                >
                  delete
                </i>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="table-footer d-flex align-items-center border-bottom">
          <a [hidden]="totalItem === 0" class="ms-3" style="text-decoration: none">Hiển thị từ {{(pageNumber * 10) + 1}} - {{(pageNumber * 10)+ numberOfElements}}/{{totalItem}} dịch vụ</a>
          <a [hidden]="totalItem > 0" class="ms-3" style="text-decoration: none">Không có dữ liệu</a>
          <div style="width: 70%; float: right;">
            <p-paginator
            [rows]="itemPerPage"
            [pageLinkSize]="totalPage"
            [totalRecords]="totalItem"
            (onPageChange)="onPageChange($event)"
          ></p-paginator>
          </div>
      </div>
    </div>

    <!-- Add-modal -->
    <div
      class="modal fade"
      id="add-modal"
      tabindex="-1"
      aria-labelledby="add-modal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="add-modal">Thêm mới sản phẩm</h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form #formAdd="ngForm" (submit)="onSubmit(formAdd.value)">
              <div class="py-2">
                <label for="name">Tên Sản Phẩm <span style="color: red">*</span></label>
                <input
                  type="text"
                  name="name"
                  class="form-control my-2"
                  #name="ngModel"
                  [(ngModel)]="product.name"
                  placeholder="nhập tên sản phẩm"
                  required
                />
              </div>
              <div *ngIf="name.invalid && (name.dirty || name.touched)">
                <p class="text-danger" *ngIf="name.errors?.['required']">
                  Vui lòng nhập tên sản phẩm
                </p>
              </div>
              <div class="py-2">
                <label for="detailShort">Tên tiếng việt của Sản Phẩm <span style="color: red">*</span></label>
                <input
                  type="text"
                  name="detailShort"
                  class="form-control my-2"
                  #detailShort="ngModel"
                  [(ngModel)]="product.detailShort"
                  placeholder="nhập tên tiếng việt của sản phẩm"
                  required
                />
              </div>
              <div *ngIf="detailShort.invalid && (detailShort.dirty || detailShort.touched)">
                <p class="text-danger" *ngIf="detailShort.errors?.['required']">
                  Vui lòng nhập tên tiếng việt của Sản Phẩm
                </p>
              </div>
              <div class="py-2">
                <label for="backGroundImage">Ảnh nền<span style="color: red">*</span></label>
                <input
                  type="file"
                  name="backGroundImage"
                  class="form-control my-2"
                  (change)="onChangeFileBackGroundImage($event)"
                />
              </div>

              <div class="py-2">
                <label for="avatarImage">Ảnh Avatar <span style="color: red">*</span></label>
                <input
                  type="file"
                  name="avatarImage"
                  class="form-control my-2"
                  (change)="onChangeFileAvatarImage($event)"
                />
              </div>
              <div class="py-2">
                <label class="mb-2" for="information">Mô tả<span style="color:
                red">*
                </span></label>
                <ckeditor
                  class="form-cheditor"
                  name="information"
                  #information="ngModel"
                  [editor]="editor"
                  [(ngModel)]="product.information"
                  required
                ></ckeditor>
              </div>
              <div
                *ngIf="
                  information.invalid &&
                  (information.dirty || information.touched)
                "
              >
                <p class="text-danger" *ngIf="information.errors?.['required']">
                  Vui lòng nhập thông tin
                </p>
              </div>
              <div class="py-2">
                <label class="mb-2" for="feature">Tính năng <span style="color: red">*
                </span></label>
                <ckeditor
                  class="form-cheditor"
                  name="feature"
                  #feature="ngModel"
                  [editor]="editor"
                  [(ngModel)]="product.feature"
                  required
                ></ckeditor>
              </div>
              <div
                *ngIf="
                  feature.invalid &&
                  (feature.dirty || feature.touched)
                "
              >
                <p class="text-danger" *ngIf="feature.errors?.['required']">
                  Vui lòng nhập tính năng
                </p>
              </div>
              <div class="d-flex justify-content-end gap-2">
                <button
                  type="button"
                  class="btn btn-outline-secondary"
                  data-bs-dismiss="modal"
                >
                  Hủy
                </button>
                <button
                  type="submit"
                  class="btn btn-primary"
                  data-bs-dismiss="modal"
                  [disabled]="formAdd.invalid"
                >
                  Thêm Mới
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Edit-modal -->
    <div
      class="modal fade"
      id="edit-modal"
      tabindex="-1"
      aria-labelledby="edit-modal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="edit-modal">Sửa sản phẩm</h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form
              #formEdit="ngForm"
              (submit)="onEdit(productDetail.id, formEdit.value)"
            >
              <div class="py-2">
                <label for="name">Tên sản phẩm</label>
                <input
                  type="text"
                  name="name"
                  #name="ngModel"
                  [value]="productDetail.name"
                  [(ngModel)]="productDetail.name"
                  class="form-control my-2"
                  placeholder="nhập tên sản phẩm"
                  required
                />
              </div>
              <div *ngIf="name.invalid && (name.dirty || name.touched)">
                <p class="text-danger" *ngIf="name.errors?.['required']">
                  Vui lòng nhập tên sản phẩm
                </p>
              </div>

              <div class="py-2">
                <label for="name">Tên tiếng việt của sản phẩm</label>
                <input
                  type="text"
                  name="detailShort"
                  #detailShort="ngModel"
                  [value]="productDetail.detailShort"
                  [(ngModel)]="productDetail.detailShort"
                  class="form-control my-2"
                  placeholder="nhập tên tiếng việt của sản phẩm"
                  required
                />
              </div>
              <div *ngIf="detailShort.invalid && (detailShort.dirty || detailShort.touched)">
                <p class="text-danger" *ngIf="detailShort.errors?.['required']">
                  Vui lòng nhập tên tiếng việt của sản phẩm
                </p>
              </div>

              <div class="py-2">
                <label for="backGroundImage">Ảnh nền</label>
                <input
                  type="file"
                  name="backGroundImage"
                  class="form-control my-2"
                  placeholder="chọn ảnh nền"
                  (change)="onChangeFileBackGroundImage($event)"
                  #backGroundImage
                />
              </div>

              <div class="py-2">
                <label for="avatarImage">Ảnh Avatar</label>
                <input
                  type="file"
                  name="avatarImage"
                  class="form-control my-2"
                  placeholder="chọn ảnh avatar"
                  (change)="onChangeFileAvatarImage($event)"
                  #avatarImage
                />
              </div>
              <div class="py-2">
                <label class="mb-2" for="information">Mô tả</label>
                <ckeditor
                  class="form-cheditor"
                  name="information"
                  #information="ngModel"
                  [editor]="editor"
                  [(ngModel)]="productDetail.information"
                  required
                ></ckeditor>
              </div>
              <div
                *ngIf="
                  information.invalid &&
                  (information.dirty || information.touched)
                "
              >
                <p
                  class="text-danger py-2"
                  *ngIf="information.errors?.['required']"
                >
                  Vui lòng nhập thông tin
                </p>
              </div>

              <div class="py-2">
                <label class="mb-2" for="feature">Tính năng</label>
                <ckeditor
                  class="form-cheditor"
                  name="feature"
                  #feature="ngModel"
                  [editor]="editor"
                  [(ngModel)]="productDetail.feature"
                  required
                ></ckeditor>
              </div>
              <div
                *ngIf="
                  feature.invalid &&
                  (feature.dirty || feature.touched)
                "
              >
                <p
                  class="text-danger py-2"
                  *ngIf="feature.errors?.['required']"
                >
                  Vui lòng nhập tính năng
                </p>
              </div>

              <div class="d-flex justify-content-end gap-2">
                <button
                  type="button"
                  class="btn btn-outline-secondary"
                  data-bs-dismiss="modal"
                >
                  Hủy
                </button>
                <button
                  type="submit"
                  class="btn btn-primary"
                  data-bs-dismiss="modal"
                >
                  Lưu
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    </div>
  </div>
</div>
