import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { contact, contactData } from 'src/app/models/contact';
import { news } from 'src/app/models/news';
import { response } from 'src/app/models/response';
import { ContactService } from 'src/app/services/contact.service';
import { NewsService } from 'src/app/services/news.service';
import {ToastService} from "../../toast.service";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  contacts: contact[] = [];
  listNews: news[] = [];
  indexCheckBox: any[] = [];
  checkedCheckBox: any[] = [];
  contact: contactData = {
    name: '',
    email: '',
    phoneNumber: '',
    subject: '',
    content: '',
  }

  contactDetail: contactData = {
    name: '',
    email: '',
    phoneNumber: '',
    subject: '',
    content: '',
  }
  totalPage = 0;
  totalItem = 0;
  itemPerPage = 0;
  pageNumber = 0;
  checked = false;
  statusContact =1;
  checkAll = false;
  search = '';
  sort = 'create_desc';
  fileToUpload: string | Blob = '';
  listId: any = [];
  numberOfElements = 0;
  searchTerm = new Subject<string>()
  constructor(
    private contactService: ContactService,
    private newsService: NewsService,
    private toast: ToastService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit(): void {
    this.getAll()
    this.getNews()
    this.searchTerm.pipe(
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe((keyword: string) => {
      if (keyword.length === 0) {
        return this.getAll()
      }
      this.contactService.searchContact(keyword).subscribe({
        next: (data: response<contact[]>) => {
          this.contacts = data.content;
          this.pageNumber = data.pageable.pageNumber;
        this.totalPage = data.totalPages;
        this.totalItem = data.totalElements;
        this.itemPerPage = data.numberOfElements;
        this.numberOfElements = data.numberOfElements;
        },
        error: (err: HttpErrorResponse) => {
          console.log(err);
        }
      })
    })
  }

  getAll(): void {
    this.contactService.getContacts(1).subscribe({
      next: (data: response<contact[]>) => {
        this.contacts = data.content;
        this.totalItem = data.totalElements;
        this.totalPage = data.totalPages;
        this.itemPerPage = data.numberOfElements;
        this.pageNumber = data.pageable.pageNumber;
        this.numberOfElements = data.numberOfElements;
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      }
    })
  }

  getById(id: number | undefined): void {
    this.contactService.getContact(id).subscribe({
      next: (data: contact) => {
        this.contactDetail = data;
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      }
    })
  }

  getNews(): void {
    this.newsService.getListNews(1).subscribe({
      next: (data: response<news[]>) => {
        this.listNews = data.content;
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      }
    })
  }

  onCheckboxValue(e: any, index : number): void {
    const id = +(e.target as HTMLInputElement).value;
    if (e.target.checked) {
      this.listId.push(id);
      this.checkedCheckBox[index] = true;
      this.indexCheckBox.push(index);
      if (this.listId.length === this.itemPerPage) {
        this.checkAll = true;
      }else{
        this.checkAll = false;
      }
    } else {
      this.listId = this.listId.filter((item: number) => item !== id)
      this.indexCheckBox = this.indexCheckBox.filter((item: number) => item !== index);
      if (this.listId.length === this.itemPerPage) {
        this.checkAll = true;
      }else{
        this.checkAll = false;
      }
    }
  }

  onPageChange(e: any): void {
    const page = e.page + 1;
    this.contactService.selectContacts(page, this.statusContact, this.search, this.sort).subscribe({
      next: (data: response<contact[]>) => {
        this.contacts = data.content;
        this.pageNumber = data.pageable.pageNumber;
        this.numberOfElements = data.numberOfElements;
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      }
    })
  }

  onFilter(e: Event): void {
    this.statusContact= +(e.target as HTMLInputElement).value;
    this.contactService.filterContacts(this.statusContact).subscribe({
      next: (data: response<contact[]>) => {
        this.contacts = data.content;
        this.pageNumber = data.pageable.pageNumber;
        this.totalPage = data.totalPages;
        this.totalItem = data.totalElements;
        this.itemPerPage = data.numberOfElements;
        this.numberOfElements = data.numberOfElements;
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      }
    })
  }

  onSort(e: Event): void {
    this.sort = (e.target as HTMLInputElement).value;
    this.contactService.sortContacts(this.sort).subscribe({
      next: (data: response<contact[]>) => {
        this.contacts = data.content;
        this.pageNumber = data.pageable.pageNumber;
        this.totalPage = data.totalPages;
        this.totalItem = data.totalElements;
        this.itemPerPage = data.numberOfElements;
        this.numberOfElements = data.numberOfElements;
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      }
    })
  }

  onSearch(keyword: string): void {
    this.search = keyword;
    this.searchTerm.next(keyword)
  }

  onEdit(id: number | undefined, data: contact): void {
    this.contactService.updateContactById(id, data).subscribe({
      next: () => {
        this.getAll();
        this.toast.success('Cập nhật liên hệ', 'Cập nhật thành công');
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
        this.toast.error('Cập nhật liên hệ', 'Có lỗi xảy ra khi cập nhật');
      }
    })
  }

  onDelete(id: (number | number[]) | undefined): void {
    this.confirmationService.confirm({
      header: 'Xóa liên hệ',
      message: 'Bạn có chắc muốn xóa không?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Đồng ý',
      rejectLabel: 'Hủy',
      accept: () => {
        if (this.listId.length > 0) {
          this.contactService.deleteContactById(this.listId).subscribe({
            next: () => {
              this.getAll()
              this.listId = [];
              this.checked = false;
              this.checkAll =  false;
              this.indexCheckBox.forEach(i => {
                this.checkedCheckBox[i] = false
              })
              this.indexCheckBox = [];
              this.toast.success('Xóa liên hệ', 'Xóa thành công');
            },
            error: (err: HttpErrorResponse) => {
              console.log(err);
              this.toast.error('Xóa liên hệ', 'Có lỗi xảy ra khi xóa');
            }
          })
        } else {
          this.listId.push(id)
          this.contactService.deleteContactById(this.listId).subscribe({
            next: () => {
              this.listId = [];
              this.getAll();
              this.checked = false;
              this.checkAll = false;
              this.indexCheckBox.forEach(i => {
                this.checkedCheckBox[i] = false
              })
              this.indexCheckBox = [];
              this.toast.success('Xóa liên hệ', 'Xóa thành công');
              },
            error: (err: HttpErrorResponse) => {
              console.log(err);
              this.toast.error('Xóa liên hệ', 'Có lỗi xảy ra khi xóa');
            }
          })
        }
      }
    })
  }

  onCheckAll(e: any): void {
    if (e.target.checked) {
      this.listId = [];
      this.contacts.forEach(n => {
        return this.listId.push(n.id);
      });
      this.checked = true;
      this.checkAll = true;
    } else {
      this.listId = [];
      this.checked = false;
      this.checkAll = false;
      for (let index = 0; index <= 10; index++) {
        this.checkedCheckBox[index] = false;
      }
    }
  }

  onCheckBox(){
    this.checked = false;
    this.checkAll = false;
    this.listId = []
    this.indexCheckBox.forEach(i => {
      this.checkedCheckBox[i] = false
    })
    this.indexCheckBox = [];
  }
}
