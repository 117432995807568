import {
  Component,
  OnInit,
} from '@angular/core';
import {JobListService} from "../services/job-list.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import { Router } from '@angular/router';
import {lookUpValue, RecruitmentInfoDto, searchRecruitment} from "../models/job";
import {response} from "../models/response";
import {MessageService} from "primeng/api";

interface Job {
  title: string;
  description: string;
  tag: string;
  salary: string;
  deadline: string;
  buttonText: string;
}

@Component({
  selector: 'app-job-list',
  templateUrl: './job-list.component.html',
  styleUrls: ['./job-list.component.css']
})
export class JobListComponent implements OnInit {
  searchForm!: FormGroup;
  skills: lookUpValue[] = [];
  jobs: RecruitmentInfoDto[] = [];
  levels: lookUpValue[] = [];
  selectedSkills: any[] = [];
  selectedLevel: any = null;
  totalPage = 0;
  totalItem = 0;
  itemPerPage = 6;
  pageNumber = 0;
  numberOfElements = 0;

  constructor(
    private jobListService: JobListService,
    private fb: FormBuilder,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.initSearchForm();
    this.loadFilters();
    this.loadRecruitments();
  }

  private initSearchForm(): void {
    this.searchForm = this.fb.group({
      search: [''],
      skillIds: [[]],
      levelIds: [null]
    });
  }

  loadFilters(): void {
    const searchCriteria = {lcoIds: [2], pageNumber: 0, pageSize: 999};
    const searchExperience = {lcoIds: [3], pageNumber: 0, pageSize: 999};

    this.jobListService.getSkills(searchCriteria).subscribe({
      next: (data) => this.skills = data.content,
      error: (error) => this.handleError('Failed to load skills', error)
    });

    this.jobListService.getSkills(searchExperience).subscribe({
      next: (data) => this.levels = data.content,
      error: (error) => this.handleError('Failed to load experiences', error)
    });
  }

  loadRecruitments(page: number = 0): void {
    const searchRecruitment: searchRecruitment = {
      pageNumber: page,
      pageSize: this.itemPerPage
    };
    this.searchRecruitments(searchRecruitment);
  }

  onPageChange(event: any): void {
    this.pageNumber = event.page;
    this.itemPerPage = event.rows;
    this.performSearch(this.pageNumber);
  }

  onSubmit(): void {
    this.performSearch();
  }

  onFilterChange(): void {
    this.performSearch();
  }

  performSearch(page: number = 0): void {
    const searchParams: searchRecruitment = {
      search: this.searchForm.get('search')?.value || '',
      skillIds: this.selectedSkills.map(skill => skill.id),
      levelIds: this.selectedLevel ? [this.selectedLevel.id] : undefined,
      pageNumber: page,
      pageSize: this.itemPerPage,
    };

    this.searchRecruitments(searchParams);
  }

  private searchRecruitments(params: searchRecruitment): void {
    this.jobListService.getRecruitments(params).subscribe({
      next: (response: response<RecruitmentInfoDto[]>) => {
        this.jobs = response.content;
        this.updatePaginationInfo(response);
      },
      error: (error) => this.handleError('Search failed', error)
    });
  }

  private updatePaginationInfo(response: any): void {
    this.pageNumber = response.pageable.pageNumber;
    this.totalPage = response.totalPages;
    this.totalItem = response.totalElements;
    this.numberOfElements = response.numberOfElements;
  }

  private handleError(message: string, error: any): void {
    console.error(`${message}:`, error);
    // You could add more sophisticated error handling here, like showing a user-friendly message
  }

}
