<div class="shadow p-1" style="display: block">
  <p class="text-center">{{ title }}</p>
  <canvas
    baseChart
    width="{{ width }}"
    height="300"
    [type]="'bar'"
    [data]="barChartData"
    [options]="barChartOptions"
    [legend]="barChartLegend"
  >
    >
  </canvas>
</div>
