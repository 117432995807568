import {HttpErrorResponse} from '@angular/common/http';
import {Component, OnInit, ViewChild} from '@angular/core';
import {ConfirmationService} from 'primeng/api';
import {debounceTime, distinctUntilChanged, Subject} from 'rxjs';
import {response} from 'src/app/models/response';
import {LookUpValueService} from 'src/app/services/look-up-value.service';
import {ToastService} from "../../toast.service";
import {NgForm} from '@angular/forms';
import {look_up_value} from "../../models/look_up_value";
import {look_up_value_search} from "../../models/dto/look_up_value_search";
import {lookUpValue} from "../../models/job";
import {look_up_code} from "../../models/look_up_code";

@Component({
  selector: 'app-look-up-code',
  templateUrl: './look-up-value.component.html',
  styleUrls: ['./look-up-value.component.css']
})
export class LookUpValueComponent implements OnInit {
  @ViewChild('formAdd') formAdd: NgForm;
  look_up_values : look_up_value[] = [];
  look_up_value: look_up_value = {
    lcoName: '',
    lvaMean: '',
    lcoId: 0
  }
  look_up_valueDetail: look_up_value = {
    lcoName: '',
    lvaMean: '',
    lcoId: undefined,
  }
  numberOfElements = 0;
  checked = false;
  totalPage = 10;
  itemPerPage = 10;
  totalItem = 10;
  pageNumber = 0;
  selectedValue: any = [];
  indexCheckBox: any[] = [];
  checkedCheckBox: any[] = [];
  searchTerm = new Subject<string>();
  search = '';
  sort = 'create_desc';
  checkAll = false;
  sortColumn = '';
  sortDirection = '';
  lcos: look_up_code[] = [];
  statusDf: number | undefined = 1;

  constructor(
    private lookUpValueService: LookUpValueService,
    private confirmationService: ConfirmationService,
    private toast: ToastService
  ) {
    this.formAdd = new NgForm([], []);
  }

  ngOnInit(): void {
    this.getAll()
    this.loadFilters();
    this.searchTerm.pipe(
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe((value: string) => {
      if (value.length === 0) {
        this.getAll()
      }
      const searchParams: look_up_value_search = {
        lvaMean: value,
      };
      this.lookUpValueService.searchLookUpValue(searchParams).subscribe({
        next: (data: response<look_up_value[]>) => {
          this.look_up_values = data.content;
          this.pageNumber = data.pageable.pageNumber;
          this.totalPage = data.totalPages;
          this.totalItem = data.totalElements;
          this.itemPerPage = data.numberOfElements;
          this.numberOfElements = data.numberOfElements;
        },
        error: (err: HttpErrorResponse) => {
          console.log(err);
          this.toast.error("Tìm kiếm cấu hình danh mục dùng chung", 'Có lỗi xảy ra khi tìm kiếm');
        }
      })
    })
  }

  loadFilters(): void {
    const searchCriteria = {pageNumber: 0, pageSize: 999};

    this.lookUpValueService.getLcos(searchCriteria).subscribe({
      next: (data) => this.lcos = data.content,
      error: (error) => this.handleError('Failed to load lookUpCode', error)
    });
  }

  getAll(): void {
    const searchParams: look_up_value_search = {
      status: 1,
    };
    this.lookUpValueService.searchLookUpValue(searchParams).subscribe({
      next: (data: response<look_up_value[]>) => {
        this.look_up_values = data.content;
        this.itemPerPage = data.numberOfElements
        this.totalItem = data.totalElements;
        this.totalPage = data.totalPages;
        this.pageNumber = data.pageable.pageNumber;
        this.numberOfElements = data.numberOfElements;
        console.log(data);

      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      }
    })
  }

  onFilter(e: Event): void {
    const searchParams: look_up_value_search = {
      status: +(e.target as HTMLInputElement).value,
      pageNumber: this.pageNumber,
    };
    this.statusDf = searchParams.status;
    this.lookUpValueService.searchLookUpValue(searchParams).subscribe({
      next: (data: response<look_up_value[]>) => {
        this.look_up_values = data.content;
        this.itemPerPage = data.numberOfElements
        this.totalItem = data.totalElements;
        this.totalPage = data.totalPages;
        this.pageNumber = data.pageable.pageNumber;
        this.numberOfElements = data.numberOfElements;
        console.log(data);

      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
        this.toast.error('Lọc danh mục', 'Có lỗi xảy khi sắp xếp');
      }
    })
  }

  onGetById(id: number | undefined): void {
    this.lookUpValueService.getLookUpCodeById(id).subscribe({
      next: (data: any) => {
        this.look_up_valueDetail = data;
        console.log(data);

      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      }
    })
  }

  onPageChange(e: any): void {
    this.pageNumber = e.page;
    const searchParams: look_up_value_search = {
      pageNumber: e.page,
    };
    this.lookUpValueService.searchLookUpValue(searchParams).subscribe({
      next: (data: response<look_up_value[]>) => {
        this.look_up_values = data.content;
        this.pageNumber = data.pageable.pageNumber;
        this.numberOfElements = data.numberOfElements;
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      }
    })
  }

  onSearch(keyword: string): void {
    this.search = keyword;
    this.searchTerm.next(keyword)
  }

  onSort(e: Event): void {
    this.sort = (e.target as HTMLInputElement).value;
    if (this.sort === '') {
      this.getAll();
    } else {
      this.sortColumn = this.sort.slice(0, this.sort.indexOf("_"));
      this.sortDirection = this.sort.slice(this.sort.indexOf("_") + 1);
      const searchParams: look_up_value_search = {
        sortColumn: this.sortColumn,
        sortDirection: this.sortDirection,
        pageNumber: this.pageNumber,
        status : this.statusDf,
      };
      this.sort = (e.target as HTMLInputElement).value;
      this.lookUpValueService.searchLookUpValue(searchParams).subscribe({
        next: (data: response<look_up_value[]>) => {
          this.look_up_values = data.content;
          this.pageNumber = data.pageable.pageNumber;
          this.totalPage = data.totalPages;
          this.totalItem = data.totalElements;
          this.itemPerPage = data.numberOfElements;
          this.numberOfElements = data.numberOfElements;
        },
        error: (err: HttpErrorResponse) => {
          console.log(err);
          this.toast.error('Sắp xếp danh mục', 'Có lỗi xảy khi sắp xếp');
        }
      })
    }
  }

  onCheckAll(e: any): void {
    if (e.target.checked) {
      this.selectedValue = [];

      this.look_up_values.forEach(n => {
        return this.selectedValue.push(n.id);
      });
      this.checked = true;
      this.checkAll = true;
    } else {
      this.selectedValue = [];
      this.checked = false;
      this.checkAll = false;
      for (let index = 0; index <= 10; index++) {
        this.checkedCheckBox[index] = false;
      }
    }
    console.log(this.selectedValue);
  }

  onCheckboxValue(e: any, index: number): void {
    const id = +(e.target as HTMLInputElement).value;
    if (e.target.checked) {
      this.selectedValue.push(id)
      this.checkedCheckBox[index] = true;
      this.indexCheckBox.push(index);
      if (this.selectedValue.length === this.itemPerPage) {
        this.checkAll = true;
      } else {
        this.checkAll = false;
      }
    } else {
      this.indexCheckBox = this.indexCheckBox.filter((item: number) => item !== index);
      this.selectedValue = this.selectedValue.filter((item: number) => item !== id);
      if (this.selectedValue.length === this.itemPerPage) {
        this.checkAll = true;
      } else {
        this.checkAll = false;
      }
    }

  }

  onSubmit(data: look_up_value) {
    this.lookUpValueService.createLookUpValue(data).subscribe({
      next: () => {
        this.getAll();
        this.formAdd.resetForm();
        this.toast.success('Thêm danh mục dùng chung', 'Thêm thành công');
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
        this.toast.error('Thêm danh mục dùng chung', 'Có lỗi xảy ra khi thêm mới');
      }
    })
  }

  onEdit(id: number | undefined, data: look_up_value) {
    if (data.lcoId === null){
      data.lcoId = this.look_up_valueDetail.lcoId;
    }
    this.lookUpValueService.updateLookUpValueById(id, data).subscribe({
      next: (data: any) => {
        this.getAll();
        this.toast.success('Sửa danh mục dùng chung', 'Sửa thành công');
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
        this.toast.error('Sửa danh mục dùng chung', 'Có lỗi xảy ra khi sửa');
      }
    })
  }

  onDelete(id: number | undefined) {
    this.confirmationService.confirm({
      header: 'Xóa danh mục dùng chung',
      message: 'Bạn có muốn xóa không?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Đồng ý',
      rejectLabel: 'Hủy',
      accept: () => {
        if (this.selectedValue.length === 0) {
          this.selectedValue.push(id);
          this.lookUpValueService.deletelookUpValueById(this.selectedValue).subscribe({
            next: () => {
              this.getAll()
              this.selectedValue = [];
              this.checked = false;
              this.checkAll = false;
              this.indexCheckBox.forEach(i => {
                this.checkedCheckBox[i] = false
              })
              this.indexCheckBox = [];
              this.toast.success('Xóa danh mục dùng chung', 'Xóa thành công');
            },
            error: (err: HttpErrorResponse) => {
              console.log(err);
              this.toast.error('Xóa danh mục dùng chung', 'Có lỗi xảy ra khi xóa');
            }
          })
        } else {
          this.lookUpValueService.deletelookUpValueById(this.selectedValue).subscribe({
            next: () => {
              this.getAll()
              this.selectedValue = [];
              this.checked = false;
              this.checkAll = false;
              this.indexCheckBox.forEach(i => {
                this.checkedCheckBox[i] = false
              })
              this.indexCheckBox = [];
              this.toast.success('Xóa danh mục dùng chung', 'Xóa thành công');
            },
            error: (err: HttpErrorResponse) => {
              console.log(err);
              this.toast.error('Xóa danh mục dùng chung', 'Có lỗi xảy ra khi xóa');
            }
          })
        }
      }
    })
  }

  onCheckBox() {
    this.checked = false;
    this.checkAll = false;
    this.selectedValue = []
    this.indexCheckBox.forEach(i => {
      this.checkedCheckBox[i] = false
    })
    this.indexCheckBox = [];
  }

  private handleError(message: string, error: any): void {
    console.error(`${message}:`, error);
    // You could add more sophisticated error handling here, like showing a user-friendly message
  }
}

