import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { partner } from 'src/app/models/partner';
import { PartnerService } from 'src/app/services/partner.service';
import {ToastService} from "../../toast.service";
import { NgForm } from '@angular/forms';
@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.css']
})
export class PartnerComponent implements OnInit {
  @ViewChild('formAdd') formAdd: NgForm;
  partners: partner[] = [];
  partner: partner = {
    name: '',
    image: ''
  }
  partnerDetail: partner = {
    name: '',
    image: '',
  }
  fileToUpload: string | Blob = '';
  selectedValue: any = [];
  checked = false;
  itemPerPage = 10;
  totalItem = 10;
  totalPage = 10;
  pageNumber = 0;
  statusPartner = 1;
  search ='';
  checkAll = false;
  sort = 'create_desc';
  numberOfElements = 0;
  indexCheckBox :any[] = [];
  checkedCheckBox :any[] = [];
  searchTerm = new Subject<string>();
  constructor(
    private partnerService: PartnerService,
    private confirmationService: ConfirmationService,
    private toast: ToastService
  ) { 
    this.formAdd = new NgForm([], []);
  }

  ngOnInit(): void {
    this.getAll()
    this.searchTerm.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
    ).subscribe((keyword: string) => {
      if (keyword.length === 0) {
        this.getAll()
      }
      this.partnerService.searchPartner(keyword).subscribe({
        next: (data: any) => {
          this.partners = data.content;
          this.pageNumber = data.pageable.pageNumber;
        this.totalPage = data.totalPages;
        this.totalItem = data.totalElements;
        this.itemPerPage = data.numberOfElements;
        this.numberOfElements = data.numberOfElements;
        },
        error: (err: HttpErrorResponse) => {
          console.log(err);
        }
      })
    })
  }

  getAll() {
    this.partnerService.getPartners(1).subscribe({
      next: (data: any) => {
        console.log(data);
        this.partners = data.content;
        this.totalItem = data.totalElements;
        this.totalPage = data.totalPages;
        this.itemPerPage = data.numberOfElements;
        this.pageNumber = data.pageable.pageNumber;
        this.numberOfElements = data.numberOfElements;
      },
      error: (error: HttpErrorResponse) => {
        console.log(error);
      }
    })
  }

  onFilter(e: Event) {
    this.statusPartner = +(e.target as HTMLInputElement).value;
    this.partnerService.filterPartner(this.statusPartner).subscribe({
      next: (data: any) => {
        this.partners = data.content;
        this.pageNumber = data.pageable.pageNumber;
        this.totalPage = data.totalPages;
        this.totalItem = data.totalElements;
        this.itemPerPage = data.numberOfElements;
        this.numberOfElements = data.numberOfElements;
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
        this.toast.error('Lọc đối tác', 'Có lỗi xảy ra khi lọc');
      }
    })
  }


  onSort(e: Event) {
    this.sort = (e.target as HTMLInputElement).value;
    this.partnerService.sortPartner(this.sort).subscribe({
      next: (data: any) => {
        this.partners = data.content;
        this.pageNumber = data.pageable.pageNumber;
        this.totalPage = data.totalPages;
        this.totalItem = data.totalElements;
        this.itemPerPage = data.numberOfElements;
        this.numberOfElements = data.numberOfElements;
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
        this.toast.error('Sắp xếp đối tác', 'Có lỗi xảy ra khi sắp xếp');
      }
    })
  }

  sortUpdate(){
    this.partnerService.sortPartner('update_desc').subscribe({
      next: (data: any) => {
        this.partners = data.content;
        this.pageNumber = data.pageable.pageNumber;
        this.totalPage = data.totalPages;
        this.totalItem = data.totalElements;
        this.itemPerPage = data.numberOfElements;
        this.numberOfElements = data.numberOfElements;
      }
    })
  }

  getById(id: number | undefined) {
    this.partnerService.getPartnerById(id).subscribe({
      next: (data) => {
        console.log(data);
        this.partnerDetail = data;
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      }
    })
  }

  onSearch(value: string) {
    this.search = value;
    this.searchTerm.next(value);
  }

  onPageChange(e: any) {
    const page = e.page + 1;
    this.partnerService.selectAll(this.statusPartner,page, this.search, this.sort).subscribe({
      next: (data: any) => {
        this.partners = data.content;
        this.pageNumber = data.pageable.pageNumber;
        this.numberOfElements = data.numberOfElements;
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      }
    })
  }

  onCheckValue(e: any, index: number) {
    const id = +(e.target as HTMLInputElement).value;
    if (e.target.checked) {
      this.selectedValue.push(id);
      this.checkedCheckBox[index] = true;
      this.indexCheckBox.push(index);
      if (this.selectedValue.length === this.itemPerPage) {
        this.checkAll = true;
      }else{
        this.checkAll = false;
      }
    } else {
      this.selectedValue = this.selectedValue.filter((item: number) => item !== id);
      this.indexCheckBox = this.indexCheckBox.filter((item: number) => item !== index);
      if (this.selectedValue.length === this.itemPerPage) {
        this.checkAll = true;
      }else{
        this.checkAll = false;
      }
    }
  }

  onChangeFile(e: any) {
    this.fileToUpload = e.target.files[0];
    console.log(this.fileToUpload);
  }

  onUploadFile() {
    this.partnerService.uploadFile(this.fileToUpload).subscribe({
      next: () => {
        this.getAll();
        this.toast.success('Import excel', 'Import thành công');
      },
      error: (err) => {
        console.log(err);
        this.toast.error('Import excel', 'Có lỗi xảy ra khi import');
      }
    })
  }

  onSubmit(data: partner) {
    this.partnerService.createPartner(data, this.fileToUpload).subscribe({
      next: (data) => {
        this.getAll();
        this.formAdd.resetForm();
        this.toast.success('Thêm đối tác', 'Thêm thành công');
      },
      error: (error) => {
        console.log(error);
        this.toast.error('Thêm đối tác', 'Có lỗi xảy ra khi thêm mới');
      }
    })
  }

  onEdit(id: number | undefined, data: partner) {
    this.partnerService.updatePartnerById(id, data, this.fileToUpload).subscribe({
      next: (data) => {
        this.sortUpdate();
        this.toast.success('Sửa đối tác', 'Sửa thành công');
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
        this.toast.error('Sửa đối tác', 'Có lỗi xảy ra khi sửa');
      },
    })
  }

  onDelete(id: number | undefined) {
    this.confirmationService.confirm({
      header: 'Xóa đối tác',
      message: 'Bạn có chắc muốn xóa không?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Đồng ý',
      rejectLabel: 'Hủy',
      accept: () => {
        if (this.selectedValue.length > 0) {
          this.partnerService.deletePartnerById(this.selectedValue).subscribe({
            next: (data) => {
              this.getAll();
              this.selectedValue = [];
              this.checked = false;
              this.checkAll = false;
              this.indexCheckBox.forEach(i => {
                this.checkedCheckBox[i] = false
              })
              this.indexCheckBox = [];
              this.toast.success('Xóa đối tác', 'Xóa thành công');
            },
            error: (error) => {
              console.log(error);
              this.toast.error('Xóa đối tác', 'Có lỗi xảy ra khi xóa');
            }
          })
        } else {
          this.selectedValue.push(id)
          this.partnerService.deletePartnerById(this.selectedValue).subscribe({
            next: (data) => {
              this.getAll();
              this.selectedValue = [];
              this.checked = false;
              this.checkAll = false;
              this.indexCheckBox.forEach(i => {
                this.checkedCheckBox[i] = false
              })
              this.indexCheckBox = [];
              this.toast.success('Xóa đối tác', 'Xóa thành công');
            },
            error: (error) => {
              console.log(error);
              this.toast.error('Xóa đối tác', 'Có lỗi xảy ra khi xóa');
            }
          })
        }
      }
    })
  }

  onCheckAll(e: any): void {
    if (e.target.checked) {
      this.selectedValue = [];
      this.partners.forEach(n => {
        return this.selectedValue.push(n.id);
      });
      this.checked = true;
      this.checkAll = true;
    } else {
      this.selectedValue = [];
      this.checked = false;
      this.checkAll = false;
      for (let index = 0; index <= 10; index++) {
        this.checkedCheckBox[index] = false;
      }
    }
  }

  onCheckBox(){
    this.checked = false;
    this.checkAll = false;
    this.selectedValue = []
    this.indexCheckBox.forEach(i => {
      this.checkedCheckBox[i] = false
    })
    this.indexCheckBox = [];
  }
}
