import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {look_up_code} from '../models/look_up_code';
import {look_up_code_search} from '../models/dto/look_up_code_search';
import {response} from '../models/response';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class LookUpCodeService {
  private apiUrl = environment.apiUrl;
  private token = this.authService.getToken();
  private httpOptions = {
    headers: {
      Authorization: `Bearer ${this.token}`
    },
  }
  constructor(
    private authService: AuthService,
    private http: HttpClient
  ) { }

  searchLookUpCode(data: look_up_code_search): Observable<response<look_up_code[]>> {
    return this.http.post<response<look_up_code[]>>(`${this.apiUrl}/lookUpCode/search`, data, this.httpOptions)
  }

  getLookUpCodeById(id: number | undefined): Observable<look_up_code> {
    return this.http.get<look_up_code>(`${this.apiUrl}/lookUpCode/detail/${id}`, this.httpOptions)
  }

  createLookUpCode(data: look_up_code) {
    return this.http.post(`${this.apiUrl}/lookUpCode/create`, data, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
      responseType: 'text'
    })
  }

  updateLookUpCodeById(id: number | undefined, data: look_up_code) {
    return this.http.post(`${this.apiUrl}/lookUpCode/update/${id}`, data, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
      responseType: 'text'
    })
  }

  deleteLookUpCodeById(ids: number | undefined) {
    return this.http.post(`${this.apiUrl}/lookUpCode/delete`,ids , {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
      responseType: 'text'
    })
  }

}
