import {HttpErrorResponse} from '@angular/common/http';
import {Component, OnInit, ViewChild} from '@angular/core';
import {ConfirmationService} from 'primeng/api';
import {debounceTime, distinctUntilChanged, Subject} from 'rxjs';
import {response} from 'src/app/models/response';
import {product, product_search} from 'src/app/models/product';
import {ProductService} from 'src/app/services/product.service';
import {ToastService} from "../../toast.service";
import {NgForm} from '@angular/forms';
import {look_up_value_search} from "../../models/dto/look_up_value_search";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

@Component({
  selector: 'app-service',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {
  @ViewChild('formAdd') formAdd: NgForm;
  @ViewChild('backGroundImage') backGroundImageInput: any;
  @ViewChild('avatarImage') avatarImageInput: any;
  products: product[] = [];
  product: product = {
    id: undefined,
    name: '',
    information: "",
    detailShort: "",
    avatarImage: "",
    feature: "",
    status: undefined,
    backGroundImage: "",
    createTime: "",
    updateTime: "",
    createBy: "",
    updateBy: "",
  }
  productDetail: product = {
    id: undefined,
    name: '',
    information: "",
    detailShort: "",
    avatarImage: "",
    feature: "",
    status: undefined,
    backGroundImage: "",
    createTime: "",
    updateTime: "",
    createBy: "",
    updateBy: "",
  }
  numberOfElements = 0;
  backGroundImage: any = null;
  avatarImage: any = null;
  selectedValue: any = [];
  indexCheckBox: any[] = [];
  checkedCheckBox: any[] = [];
  totalPage = 0;
  totalItem = 0;
  itemPerPage = 0;
  pageNumber = 0;
  statusNews = 1;
  checked = false;
  checkAll = false;
  sort = 'create_desc';
  search = '';
  searchTerm = new Subject<string>();
  sortColumn = '';
  sortDirection = '';
  editor = ClassicEditor;

  constructor(
    private productService: ProductService,
    private confirmationService: ConfirmationService,
    private toast: ToastService
  ) {
    this.formAdd = new NgForm([], []);
  }

  ngOnInit(): void {
    this.getAll()
    this.searchTerm.pipe(
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe((value: string) => {
      if (value.length === 0) {
        this.getAll()
      }
      const searchParams: product_search = {
        name: value,
      }
      this.productService.searchProduct(searchParams).subscribe({
        next: (data: response<product[]>) => {
          this.products = data.content;
          this.pageNumber = data.pageable.pageNumber;
          this.totalPage = data.totalPages;
          this.totalItem = data.totalElements;
          this.itemPerPage = data.numberOfElements;
          this.numberOfElements = data.numberOfElements;
        },
        error: (err: HttpErrorResponse) => {
          console.log(err);
        }
      })
    })
  }

  getAll() {
    const searchParams: product_search = {}
    this.productService.searchProduct(searchParams).subscribe({
      next: (data: response<product[]>) => {
        this.products = data.content;
        this.itemPerPage = data.numberOfElements;
        this.totalItem = data.totalElements;
        this.totalPage = data.totalPages;
        this.pageNumber = data.pageable.pageNumber;
        this.numberOfElements = data.numberOfElements;
      },
      error: (err) => {
        console.log(err);
      }
    })
  }

  getById(id: number | undefined) {
    this.productService.getProductById(id).subscribe({
      next: (data: product) => {
        this.productDetail = data;
        console.log(data);

      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      }
    })
  }

  onSort(e: Event) {
    this.sort = (e.target as HTMLInputElement).value;
    if (this.sort === '') {
      this.getAll();
    } else {
      this.sortColumn = this.sort.slice(0, this.sort.indexOf("_"));
      this.sortDirection = this.sort.slice(this.sort.indexOf("_") + 1);
      const searchParams: product_search = {
        sortColumn: this.sortColumn,
        sortDirection: this.sortDirection,
      };
      this.productService.searchProduct(searchParams).subscribe({
        next: (data: response<product[]>) => {
          this.products = data.content;
          this.pageNumber = data.pageable.pageNumber;
          this.totalPage = data.totalPages;
          this.totalItem = data.totalElements;
          this.itemPerPage = data.numberOfElements;
          this.numberOfElements = data.numberOfElements;
        },
        error: (err: HttpErrorResponse) => {
          this.toast.error('Sắp xếp dịch vụ', 'Có lỗi xảy ra khi sắp xếp');
        }
      })
    }
  }

  onPageChange(e: any) {
    const page = e.page;
    const searchParams: product_search = {
      pageNumber: page,
    }
    this.productService.searchProduct(searchParams).subscribe({
      next: (data: response<product[]>) => {
        this.products = data.content;
        this.pageNumber = data.pageable.pageNumber;
        this.numberOfElements = data.numberOfElements;
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      }
    })
  }

  onFilter(e: Event) {
    this.statusNews = +(e.target as HTMLInputElement).value;
    const searchParams: product_search = {
      status: this.statusNews,
    }
    this.productService.searchProduct(searchParams).subscribe({
      next: (data: response<product[]>) => {
        this.products = data.content;
        this.pageNumber = data.pageable.pageNumber;
        this.totalPage = data.totalPages;
        this.totalItem = data.totalElements;
        this.itemPerPage = data.numberOfElements;
        this.numberOfElements = data.numberOfElements;
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      }
    })
  }

  onCheckedValue(e: any, index: number) {
    const id = +(e.target as HTMLInputElement).value;
    console.log(id);
    if (e.target.checked) {
      this.selectedValue.push(id)
      this.checkedCheckBox[index] = true;
      this.indexCheckBox.push(index);
      if (this.selectedValue.length === this.itemPerPage) {
        this.checkAll = true;
      } else {
        this.checkAll = false;
      }
    } else {
      const removedId = this.selectedValue.filter((item: number) => item !== id);
      this.selectedValue = removedId;
      this.indexCheckBox = this.indexCheckBox.filter((item: number) => item !== index);
      if (this.selectedValue.length === this.itemPerPage) {
        this.checkAll = true;
      } else {
        this.checkAll = false;
      }
    }
  }

  onSearch(keyword: string) {
    this.searchTerm.next(keyword)
  }

  onChangeFileBackGroundImage(e: any) {
    this.backGroundImage = e.target.files[0];
    console.log(this.backGroundImage);
  }

  onChangeFileAvatarImage(e: any) {
    this.avatarImage = e.target.files[0];
    console.log(this.avatarImage);
  }

  onSubmit(data: product) {
    this.productService.createProduct(data, this.backGroundImage, this.avatarImage).subscribe({
      next: () => {
        this.getAll();
        this.formAdd.resetForm();
        this.toast.success('Thêm sản phẩm', 'Thêm mới thành công');
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
        this.toast.error('Thêm sản phẩm', 'Có lỗi xảy ra khi thêm mới');
      }
    })
  }

  onEdit(id: number | undefined, data: product) {
    this.productService.updateProductById(id, data, this.backGroundImage, this.avatarImage).subscribe({
      next: () => {
        this.toast.success('Sửa sản phẩm', 'Sửa thành công');
        this.getAll();
        this.backGroundImageInput.nativeElement.value = '';
        this.avatarImageInput.nativeElement.value = '';
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
        this.toast.error('Sửa sản phẩm', 'Có lỗi xảy ra khi sửa');
      }
    })
  }

  onDelete(id: number | undefined) {
    this.confirmationService.confirm({
      header: 'Xóa dịch vụ',
      message: 'Bạn có muốn xóa không?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Đồng ý',
      rejectLabel: 'Hủy',
      accept: () => {
        if (this.selectedValue.length === 0) {
          this.selectedValue.push(id);
          this.productService.deleteProductById(this.selectedValue).subscribe({
            next: () => {
              this.getAll();
              this.selectedValue = [];
              this.checked = false;
              this.checkAll = false;
              this.indexCheckBox.forEach(i => {
                this.checkedCheckBox[i] = false
              })
              this.indexCheckBox = [];
              this.toast.success('Xóa sản phẩm', 'Xóa thành công');
            },
            error: (err) => {
              console.log(err);
              this.toast.error('Xóa sản phẩm', 'Có lỗi xảy ra khi xóa');
            }
          })
        } else {
          this.productService.deleteProductById(this.selectedValue).subscribe({
            next: () => {
              this.getAll();
              this.selectedValue = [];
              this.checked = false;
              this.checkAll = false;
              this.indexCheckBox.forEach(i => {
                this.checkedCheckBox[i] = false
              })
              this.indexCheckBox = [];
              this.toast.success('Xóa sản phẩm', 'Xóa thành công');
            },
            error: (err) => {
              console.log(err);
              this.toast.error('Xóa sản phẩm', 'Có lỗi xảy ra khi xóa');
            }
          })
        }
      }
    })
  }

  onCheckAll(e: any): void {
    if (e.target.checked) {
      this.selectedValue = [];
      this.products.forEach(n => {
        return this.selectedValue.push(n.id);
      });
      this.checked = true;
      this.checkAll = true;
    } else {
      this.selectedValue = [];
      this.checked = false;
      this.checkAll = false;
      for (let index = 0; index <= 10; index++) {
        this.checkedCheckBox[index] = false;
      }
    }
  }

  onCheckBox() {
    this.checked = false;
    this.checkAll = false;
    this.selectedValue = []
    this.indexCheckBox.forEach(i => {
      this.checkedCheckBox[i] = false
    })
    this.indexCheckBox = [];
  }

  protected readonly null = null;
}
