import {Component, HostListener, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Constants} from '../constants';
import {PartnerService} from "../services/partner.service";
import {partner} from "../models/partner";
import {HttpErrorResponse} from "@angular/common/http";
import {ProductService} from "../services/product.service";
import {product_search} from "../models/product";

// export interface Product {
//   id?: string;
//   code?: string;
//   name?: string;
//   description?: string;
//   price?: number;
//   quantity?: number;
//   inventoryStatus?: string;
//   category?: string;
//   image?: string;
//   rating?: number;
// }
@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css'],
})
export class LandingPageComponent implements OnInit {
  partners: partner[] = [
    {
      name: 'MB Bank',
      image: '/assets/partners/1.png'
    },
    {
      name: 'Viettel',
      image: '/assets/partners/2.png'
    },
    {
      name: 'BIDV',
      image: '/assets/partners/3.png'
    },
    {
      name: 'aWS',
      image: '/assets/partners/4.png'
    },
    {
      name: 'IBM',
      image: '/assets/partners/5.png'
    },
    {
      name: 'Google',
      image: '/assets/partners/6.png'
    },
  ];
  products!: any[];
  // products: any[] = [
  //   {
  //     background: '/assets/product1.png',
  //     logo: '/assets/ic1.png',
  //     name: 'Sản phẩm 1', inventoryStatus: 'LOWSTOCK',
  //     description: 'Sản phẩm cho công ty 1'
  //   },
  //   {
  //     inventoryStatus: 'OUTOFSTOCK',
  //     background: '/assets/product2.png',
  //     logo: '/assets/ic2.png',
  //     name: 'Sản phẩm 2',
  //     description: 'Sản phẩm cho công ty 2'
  //   },
  //   {
  //     background: '/assets/product3.png',
  //     logo: '/assets/ic3.png',
  //     name: 'Sản phẩm 3',
  //     description: 'Sản phẩm cho công ty 3',
  //     inventoryStatus: 'INSTOCK',
  //   },
  //   {
  //     background: '/assets/product1.png',
  //     logo: '/assets/ic1.png',
  //     name: 'Sản phẩm 4', inventoryStatus: 'LOWSTOCK',
  //     description: 'Sản phẩm cho công ty 1'
  //   },
  //   {
  //     inventoryStatus: 'OUTOFSTOCK',
  //     background: '/assets/product2.png',
  //     logo: '/assets/ic2.png',
  //     name: 'Sản phẩm 5',
  //     description: 'Sản phẩm cho công ty 2'
  //   },
  //   {
  //     background: '/assets/product3.png',
  //     logo: '/assets/ic3.png',
  //     name: 'Sản phẩm 6',
  //     description: 'Sản phẩm cho công ty 3',
  //     inventoryStatus: 'INSTOCK',
  //   },
  // ];
  //
  // mainFunc = [
  //   {
  //     icon: '/assets/lp4.png',
  //     title: "title.scope.financial",
  //     description: "content.scope.financial",
  //   },
  //   {
  //     icon: '/assets/lp5.png',
  //     title: "title.scope.governance",
  //     description: "content.scope.governance",
  //   },
  //   {
  //     icon: '/assets/lp6.png',
  //     title: "title.scope.ecommerce",
  //     description: "content.scope.ecommerce",
  //   },
  //   {
  //     icon: '/assets/lp7.png',
  //     title: "title.scope.telecom",
  //     description: "content.scope.telecom",
  //   },
  // ]

  currentIndex = 0;
  private timer: any;
  // products: Product[];
  responsiveOptions!: any[];
  productChunks: any[][] = [];
  chunkSize = 3;

  constructor(
    private partnerService: PartnerService,
    public translate: TranslateService,
    private productService: ProductService
  ) {
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.setChunkSize(event.target.innerWidth);
    this.updateProductChunks();
  }

  setChunkSize(width: number) {
    if (width < 768) {
      this.chunkSize = 1;
    } else if (width < 992) {
      this.chunkSize = 2;
    } else {
      this.chunkSize = 3;
    }
  }

  ngOnInit(): void {
    // this.getAllPartners();
    this.getAllProduct();
    this.setChunkSize(window.innerWidth);
  }

  // chunkArray(array: any[], size: number): any[][] {
  //   const chunks = [];
  //   for (let i = 0; i < array.length; i += size) {
  //     chunks.push(array.slice(i, i + size));
  //   }
  //   return chunks;
  // }

  setLang(langCode: string) {
    this.translate.use(langCode);
    Constants.setLang(langCode);
  }

  // getAllPartners() {
  //   this.partnerService.getPartners(1).subscribe({
  //     next: (data: any) => {
  //       console.log(data);
  //       this.partners = data.content;
  //     },
  //     error: (error: HttpErrorResponse) => {
  //       console.log(error);
  //     }
  //   })
  // }
  getAllProduct() {
    const listAll: product_search = {
      status: 1,
      pageSize: 10,
      pageNumber: 0,
    };

    this.productService.searchProduct(listAll).subscribe({
      next: (data: any) => {
        console.log(data);
        this.products = data.content;

        // Chỉ gọi chunkArray khi products đã có giá trị
        if (this.products && this.products.length > 0) {
          this.productChunks = this.chunkArray(this.products, 3);
          console.log("Chunks: ", this.productChunks);
        }
      },
      error: (error: HttpErrorResponse) => {
        console.log(error);
      }
    });
  }


  updateProductChunks() {
    this.productChunks = this.chunkArray(this.products, this.chunkSize);
  }

  chunkArray(array: any[], size: number): any[][] {
    const chunks = [];
    for (let i = 0; i < array.length; i += size) {
      chunks.push(array.slice(i, i + size));
    }
    return chunks;
  }

  preSlide() {
    this.currentIndex = (this.currentIndex - 1 + this.productChunks.length) % this.productChunks.length;
  }

  nextSlide() {
    this.currentIndex = (this.currentIndex + 1) % this.productChunks.length;
  }

  ngOnDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

}
