<div>
  <p-toast></p-toast>
  <app-header></app-header>
  <div class="container">
    <div class="container-fluid product-intro">
      <div class="row name-container">
        <div class="col-4 name-and-image">
          <h3 class="fw-bold text-uppercase">{{productDetail?.name}}</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-4 name-and-image">
          <div class="avatar-container">
            <img [src]="productDetail?.avatarImage" class="avatar bg-light">
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid p-4 my-4">
      <div class="row title-and-tag">
        <h1 class="col-6 fw-bold m-0">{{productDetail?.name}}</h1>
        <div class="col-2 tag">
          <i class="pi pi-tag pe-2"></i><span>Nổi bật</span>
        </div>
        <span>{{productDetail?.detailShort}}</span>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row">
        <div class="product-left-content ps-0">
          <div class="product-card my-4 p-4">
            <div class="product-tag col-3">
              <span>Mô tả phần mềm</span>
            </div>
            <div class="mt-4">
              <span [innerHTML]="productDetail?.information"></span>
            </div>
          </div>
          <div class="product-card my-5 p-4">
            <div class="product-tag col-3">
              <span>Chức năng chính</span>
            </div>
            <div class="mt-4">
              <p class="p-0" [innerHTML]="productDetail?.feature">
            <span></span>
            </div>
          </div>
        </div>
        <div class="product-right-content pe-0">
          <div class="product-card my-4 p-4">
            <div class="product-tag col-6">
              <span>Sản phẩm khác</span>
            </div>
            <div class="product-listings" *ngFor="let product of products">
              <div class="my-3 p-2 p-card"
                   [routerLink]="['/product/product-detail', product.id]">
                <p class="m-2 fw-bold product-title">{{ product.name }}</p>
                <p class="mx-2 product-detailShort">{{product.detailShort}}</p>
              </div>
            </div>
            <div class="pt-2 text-center">
              <a [routerLink]="['/product']"
                 class="fw-bold text-decoration-none">Xem
                thêm
                <i class="ms-3 pi pi-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <app-footer></app-footer>
</div>
