import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { response } from 'src/app/models/response';
import { service } from 'src/app/models/service';
import { ServiceService } from 'src/app/services/service.service';
import {ToastService} from "../../toast.service";
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.css']
})
export class ServiceComponent implements OnInit {
  @ViewChild('formAdd') formAdd: NgForm;
  services: service[] = [];
  service: service = {
    name: '',
    information: '',
    type: 0
  }
  serviceDetail: service = {
    name: '',
    information: '',
    type: 0
  }
  numberOfElements = 0;
  fileToUpload: any = null;
  selectedValue: any = [];
  indexCheckBox: any[] = [];
  checkedCheckBox: any[] = [];
  totalPage = 0;
  totalItem = 0;
  itemPerPage = 0;
  pageNumber = 0;
  statusNews = 1;
  checked = false;
  checkAll = false;
  sort = 'create_desc';
  search = '';
  searchTerm = new Subject<string>();
  serviceData = [
    {
      id: 1,
      name: 'Ngân Hàng'
    },
    {
      id: 2,
      name: 'Viễn Thông'
    },
    {
      id: 3,
      name: 'Bất động sản'
    },
    {
      id: 4,
      name: 'Viễn Thông'
    }
  ]
  constructor(
    private serviceService: ServiceService,
    private confirmationService: ConfirmationService,
    private toast: ToastService
  ) { 
    this.formAdd = new NgForm([], []);
  }

  ngOnInit(): void {
    this.getAll()
    this.searchTerm.pipe(
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe((value: string) => {
      if (value.length === 0) {
        this.getAll()
      }
      this.serviceService.searchService(value).subscribe({
        next: (data: response<service[]>) => {
          this.services = data.content;
          this.pageNumber = data.pageable.pageNumber;
        this.totalPage = data.totalPages;
        this.totalItem = data.totalElements;
        this.itemPerPage = data.numberOfElements;
        this.numberOfElements = data.numberOfElements;
        },
        error: (err: HttpErrorResponse) => {
          console.log(err);
        }
      })
    })
  }

  getAll() {
    this.serviceService.getServices(1, 1).subscribe({
      next: (data: response<service[]>) => {
        this.services = data.content;
        this.itemPerPage = data.numberOfElements;
        this.totalItem = data.totalElements;
        this.totalPage = data.totalPages;
        this.pageNumber = data.pageable.pageNumber;
        this.numberOfElements = data.numberOfElements;
      },
      error: (err) => {
        console.log(err);
      }
    })
  }

  getById(id: number | undefined) {
    this.serviceService.getService(id).subscribe({
      next: (data: service) => {
        this.serviceDetail = data;
        console.log(data);

      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      }
    })
  }

  onSort(e : Event){
    this.sort = (e.target as HTMLInputElement).value;
    this.serviceService.sortServices(this.sort).subscribe({
      next: (data: response<service[]>) => {
        this.services = data.content;
        this.pageNumber = data.pageable.pageNumber;
        this.totalPage = data.totalPages;
        this.totalItem = data.totalElements;
        this.itemPerPage = data.numberOfElements;
        this.numberOfElements = data.numberOfElements;
      },
      error: (err: HttpErrorResponse) => {
        this.toast.error('Sắp xếp dịch vụ', 'Có lỗi xảy ra khi sắp xếp');
      }
    })
  }

  sortUpdate(){
    this.serviceService.sortServices('update_desc').subscribe({
      next: (data: response<service[]>) => {
        this.services = data.content;
        this.pageNumber = data.pageable.pageNumber;
        this.totalPage = data.totalPages;
        this.totalItem = data.totalElements;
        this.itemPerPage = data.numberOfElements;
        this.numberOfElements = data.numberOfElements;
      }
    })
  }

  onPageChange(e: any) {
    const page = e.page + 1;
    this.serviceService.selectAll(this.statusNews, page,this.search, this.sort).subscribe({
      next: (data: response<service[]>) => {
        this.services = data.content;
        this.pageNumber = data.pageable.pageNumber;
        this.numberOfElements = data.numberOfElements;
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      }
    })
  }

  onFilter(e: Event) {
    this.statusNews = +(e.target as HTMLInputElement).value;
    this.serviceService.getServices(this.statusNews, 1).subscribe({
      next: (data: response<service[]>) => {
        this.services = data.content;
        this.pageNumber = data.pageable.pageNumber;
        this.totalPage = data.totalPages;
        this.totalItem = data.totalElements;
        this.itemPerPage = data.numberOfElements;
        this.numberOfElements = data.numberOfElements;
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      }
    })
  }

  onCheckedValue(e: any, index: number) {
    const id = +(e.target as HTMLInputElement).value;
    console.log(id);
    if (e.target.checked) {
      this.selectedValue.push(id)
      this.checkedCheckBox[index] = true;
      this.indexCheckBox.push(index);
      if (this.selectedValue.length === this.itemPerPage) {
        this.checkAll = true;
      }else{
        this.checkAll = false;
      }
    } else {
      const removedId = this.selectedValue.filter((item: number) => item !== id);
      this.selectedValue = removedId;
      this.indexCheckBox = this.indexCheckBox.filter((item: number) => item !== index);
      if (this.selectedValue.length === this.itemPerPage) {
        this.checkAll = true;
      }else{
        this.checkAll = false;
      }
    }
  }

  onSearch(keyword: string) {
    this.searchTerm.next(keyword)
  }

  onChangeFile(e: any) {
    this.fileToUpload = e.target.files[0];
    console.log(this.fileToUpload);
  }

  onUploadFile() {
    this.serviceService.uploadFile(this.fileToUpload).subscribe({
      next: () => {
        this.getAll();
        this.toast.success('Import excel', 'Import thành công');
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
        this.toast.error('Import excel', 'Có lỗi xảy ra khi import');
      }
    })
  }

  onSubmit(data: service) {
    this.serviceService.createService(data, this.fileToUpload).subscribe({
      next: () => {
        this.getAll();
        this.formAdd.resetForm();
        this.toast.success('Thêm dịch vụ', 'Thêm mới thành công');
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
        this.toast.error('Thêm dịch vụ', 'Có lỗi xảy ra khi thêm mới');
      }
    })
  }

  onEdit(id: number | undefined, data: service) {
    this.serviceService.updateServiceById(id, data, this.fileToUpload).subscribe({
      next: () => {
        this.sortUpdate();
        this.toast.success('Sửa dịch vụ', 'Sửa thành công');
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
        this.toast.error('Sửa dịch vụ', 'Có lỗi xảy ra khi sửa');
      }
    })
  }

  onDelete(id: number | undefined) {
    this.confirmationService.confirm({
      header: 'Xóa dịch vụ',
      message: 'Bạn có muốn xóa không?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Đồng ý',
      rejectLabel: 'Hủy',
      accept: () => {
        if (this.selectedValue.length === 0) {
          this.selectedValue.push(id);
          this.serviceService.deleteServiceById(this.selectedValue).subscribe({
            next: () => {
              this.getAll();
              this.selectedValue = [];
              this.checked = false;
              this.checkAll = false;
              this.indexCheckBox.forEach(i => {
                this.checkedCheckBox[i] = false
              })
              this.indexCheckBox = [];
              this.toast.success('Xóa dịch vụ', 'Xóa thành công');
            },
            error: (err) => {
              console.log(err);
              this.toast.error('Xóa dịch vụ', 'Có lỗi xảy ra khi xóa');
            }
          })
        }
        this.serviceService.deleteServiceById(this.selectedValue).subscribe({
          next: () => {
            this.getAll();
            this.selectedValue = [];
            this.checked = false;
            this.checkAll = false;
              this.indexCheckBox.forEach(i => {
                this.checkedCheckBox[i] = false
              })
              this.indexCheckBox = [];
            this.toast.success('Xóa dịch vụ', 'Xóa thành công');
          },
          error: (err) => {
            console.log(err);
            this.toast.error('Xóa dịch vụ', 'Có lỗi xảy ra khi xóa');
          }
        })
      }
    })
  }

  onCheckAll(e: any): void {
    if (e.target.checked) {
      this.selectedValue = [];
      this.services.forEach(n => {
        return this.selectedValue.push(n.id);
      });
      this.checked = true;
      this.checkAll = true;
    } else {
      this.selectedValue = [];
      this.checked = false;
      this.checkAll = false;
      for (let index = 0; index <= 10; index++) {
        this.checkedCheckBox[index] = false;
      }
    }
  }

  onCheckBox(){
    this.checked = false;
    this.checkAll = false;
    this.selectedValue = []
    this.indexCheckBox.forEach(i => {
      this.checkedCheckBox[i] = false
    })
    this.indexCheckBox = [];
  }
}
