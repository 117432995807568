import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { response } from '../models/response';
import { service } from '../models/service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {
  private apiUrl = environment.apiUrl;
  private token = this.authService.getToken();
  private httpOptions = {
    headers: {
      Authorization: `Bearer ${this.token}`
    },
  }
  constructor(
    private authService: AuthService,
    private http: HttpClient
  ) { }

  getServices(status: number, page: number): Observable<response<service[]>> {
    return this.http.get<response<service[]>>(`${this.apiUrl}/service/select?status=${status}&page=${page}&sort=create_desc`, this.httpOptions)
  }

  selectAll(status: number, page: number,search: string, sort: string): Observable<response<service[]>> {
    return this.http.get<response<service[]>>(`${this.apiUrl}/service/select?status=${status}&page=${page}&sort=${sort}&search=${search}`, this.httpOptions)
  }

  sortServices(sort: string): Observable<response<service[]>> {
    return this.http.get<response<service[]>>(`${this.apiUrl}/service/select?sort=${sort}`, this.httpOptions)
  }

  getService(id: number | undefined): Observable<service> {
    return this.http.get<service>(`${this.apiUrl}/service/selectOne?id=${id}`, this.httpOptions)
  }

  searchService(keyword: string): Observable<response<service[]>> {
    return this.http.get<response<service[]>>(`${this.apiUrl}/service/select?search=${keyword}`, this.httpOptions)
  }

  createService(data: service, image: File) {
    const formData: FormData = new FormData();
    formData.append('name', data.name);
    formData.append('image', image)
    formData.append('information', data.information);
    formData.append('type', String(data.type))
    return this.http.post(`${this.apiUrl}/service/create`, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`
      },
      responseType: 'text'
    });
  }

  uploadFile(file: File): Observable<service> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post<service>(`${this.apiUrl}/service/create`, formData, this.httpOptions);
  }

  updateServiceById(id: number | undefined, data: service, file: string | Blob) {
    const formData: FormData = new FormData();
    formData.append('name', data.name);
    formData.append('image', file)
    formData.append('information', data.information);
    formData.append('type', String(data.type))
    return this.http.post(`${this.apiUrl}/service/update?id=${id}`, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`
      },
      responseType: 'text'
    });
  }

  deleteServiceById(id: number[] | undefined) {
    return this.http.post(`${this.apiUrl}/service/delete`, id, {
      headers: {
        Authorization: `Bearer ${this.token}`
      },
      responseType: 'text'
    });
  }

  countService(): Observable<number> {
    return this.http.get<number>(`${this.apiUrl}/service/countService`, this.httpOptions);
  }

}
