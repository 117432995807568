<div class="container-fluid p-4">
  <p-toast></p-toast>
  <p-confirmDialog
    [style]="{ width: '50vw' }"
    [baseZIndex]="10000"
  ></p-confirmDialog>
  <!-- Alert -->
  <div>
    <div class="fixed-bottom d-flex justify-content-center">
      <div
        [hidden]="selectedValue.length === 0"
        class="alert alert-danger w-50"
        role="alert"
      >
        <div class="d-flex justify-content-between align-items-center gap-2">
          <p style="margin-bottom: 0">
            Đã chọn {{ selectedValue.length }} phần tử
          </p>
          <div>
            <div>
              <button
                class="btn btn-light px-4 cursor-pointer me-3"
                (click)="onCheckBox()"
              >
                Hủy
              </button>
              <button
                class="btn btn-danger px-4 cursor-pointer"
                (click)="onDelete(selectedValue)"
              >
                Xóa
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Header -->
    <div class="d-flex justify-content-between">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a class="text-dark text-decoration-none" routerLink="/admin"
            >Admin</a
            >
          </li>
          <li class="breadcrumb-item">
            <a class="text-dark text-decoration-none" routerLink="/organization"
            >Tuyển dụng</a
            >
          </li>
        </ol>
      </nav>
      <div class="d-flex gap-2">

        <button (click)="toggleTable()"
                class="btn btn-danger px-4 cursor-pointer">
          {{ isFirstTableVisible ? 'Xem danh sách ứng viên' : 'Xem danh sách tuyển dụng' }}
        </button>

        <button
          class="d-flex gap-2 btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#add-modal"
        >
          <i class="material-symbols-outlined"> person_add </i>
          Thêm mới
        </button>
      </div>
    </div>
    <!-- Table -->

    <div class="table-wrapper my-4">
      <!--      bảng tuyển dụng-->
      <div *ngIf="isFirstTableVisible" class="table-responsive border rounded">
        <div class="table-header bg-light rounded-top">
          <div class="d-flex justify-content-between align-items-center p-2">
            <div class="d-flex gap-2">
              <select
                (change)="onFilter($event)"
                class="form-select"
                style="max-width: 200px"
              >
                <option [value]="1">Lọc theo</option>
                <option [value]="0">Tất cả</option>
                <option [value]="1">Hoạt động</option>
                <option [value]="-1">Không hoạt động</option>
              </select>
              <select
                class="form-select"
                style="max-width: 200px"
                (change)="onSort($event)"
              >
                <option value="">Sắp xếp theo</option>
                <option value="id_ASC">id tăng dần</option>
                <option value="id_DESC">id giảm dần</option>
                <option value="title_ASC">Từ a - z</option>
                <option value="title_DESC">Từ z - a</option>
                <option value="createTime_DESC">Thông tin mới thêm</option>
                <option value="updateTime_DESC">Thông tin mới sửa</option>
              </select>
            </div>
            <input
              type="text"
              class="form-control"
              style="max-width: 260px"
              placeholder="Tìm kiếm..."
              #searchTerm
              (keyup)="onSearch(searchTerm.value)"
            />
          </div>
        </div>
        <table class="table table-responsive table-body">
          <thead>
          <tr>
            <th>
              <input
                [checked]="checkAll"
                type="checkbox"
                name="id"
                class="form-check-input m-0"
                (change)="onCheckAll($event)"
                id=""
              />
            </th>
            <th class="text-secondary">ID</th>
            <th class="text-secondary">Tiêu đề</th>
            <th class="text-secondary">Nội dung</th>
            <th class="text-secondary">Tóm tắt nội dung</th>
            <th class="text-secondary">Mức lương</th>
            <th class="text-secondary">Ngôn ngữ lập trình</th>
            <th class="text-secondary">Kinh nghiệm</th>
            <th class="text-secondary">Trình độ</th>
            <th class="text-secondary">Số lượng</th>
            <th class="text-secondary">Ngày bắt đầu</th>
            <th class="text-secondary">Ngày kết thúc</th>
            <th class="text-secondary">Trạng thái</th>
            <th class="text-secondary">Thao tác</th>
          </tr>
          </thead>

          <tbody>
          <tr *ngFor="let recruitment of recruitments; index as index">
            <td>
              <input
                [checked]="checked"
                type="checkbox"
                class="form-check-input m-0"
                [value]="recruitment.id"
                [checked]="checkedCheckBox[index]"
                (change)="onCheckboxValue($event, index)"
              />
            </td>

            <td>{{ (pageNumber * 10) + index + 1 }}</td>

            <td class="text-truncate" style="max-width: 100px">
              {{ recruitment.title }}
            </td>
            <td class="text-truncate" style="max-width: 100px">
              {{ recruitment.content }}
            </td>
            <td class="text-truncate" style="max-width: 100px">
              {{ recruitment.contentShort }}
            </td>
            <td class="text-truncate" style="max-width: 100px">
              {{ recruitment.salaryName }}
            </td>
            <td class="text-truncate" style="max-width: 100px">
              {{ recruitment.skillNames }}
            </td>
            <td class="text-truncate" style="max-width: 100px">
              {{ recruitment.experienceName }}
            </td>
            <td class="text-truncate" style="max-width: 100px">
              {{ recruitment.levelName }}
            </td>
            <td class="text-truncate" style="max-width: 100px">
              {{ recruitment.quantity }}
            </td>
            <td class="text-truncate" style="max-width: 100px">
              {{ recruitment.recruitmentFromStr }}
            </td>
            <td class="text-truncate" style="max-width: 100px">
              {{ recruitment.recruitmentToStr }}
            </td>
            <td>
              <div class="d-inline-block bg-opacity-25 p-2 rounded" [ngClass]="{
                  'text-success bg-success': recruitment.status === 1,
                  'text-danger bg-danger ': recruitment.status === 0,
                  'text-warning bg-warning': recruitment.status === null
                }">
                {{ recruitment.status === 1 ? "Hoạt động" : "Không hoạt động" }}
              </div>
            </td>
            <td>
              <i
                [hidden]="recruitment.id === -1"
                class="material-symbols-outlined"
                data-bs-toggle="modal"
                data-bs-target="#edit-modal"
                (click)="getById(recruitment.id)"
              >
                edit
              </i>
              <i
                [hidden]="recruitment.id === -1"
                class="material-symbols-outlined mx-2"
                (click)="onDelete(recruitment.id)"
              >
                delete
              </i>
            </td>
          </tr>
          </tbody>
        </table>

        <div class="table-footer d-flex align-items-center">
          <a [hidden]="totalItem===0" class="ms-3" style="text-decoration: none">Hiển thị từ {{ (pageNumber * 10) + 1 }}
            - {{ (pageNumber * 10) + numberOfElements }}/{{ totalItem }} thông tin </a>
          <a [hidden]="totalItem > 0" class="ms-3" style="text-decoration: none">Không có dữ liệu</a>
          <div style="width: 70%; float: right;">
            <p-paginator
              [rows]="itemPerPage"
              [pageLinkSize]="totalPage"
              [totalRecords]="totalItem"
              (onPageChange)="onPageChange($event)"
            ></p-paginator>
          </div>
        </div>
      </div>

      <!--      bảng ứng viên-->

      <div *ngIf="!isFirstTableVisible" class="table-responsive border rounded">
        <div class="table-header bg-light rounded-top">
          <div class="d-flex justify-content-between align-items-center p-2">
            <div class="d-flex gap-2">
              <select
                (change)="onFilterContact($event)"
                class="form-select"
                style="max-width: 200px"
              >
                <option [value]="1">Lọc theo</option>
                <option [value]="0">Tất cả</option>
                <option [value]="1">Hoạt động</option>
                <option [value]="-1">Không hoạt động</option>
              </select>
              <select
                class="form-select"
                style="max-width: 200px"
                (change)="onSortContact($event)"
              >
                <option value="">Sắp xếp theo</option>
                <option value="id_ASC">id tăng dần</option>
                <option value="id_DESC">id giảm dần</option>
                <option value="name_ASC">Từ a - z</option>
                <option value="name_DESC">Từ z - a</option>
                <option value="createTime_DESC">Thông tin mới thêm</option>
                <option value="updateTime_DESC">Thông tin mới sửa</option>
              </select>
            </div>
            <input
              type="text"
              class="form-control"
              style="max-width: 260px"
              placeholder="Tìm kiếm..."
              #searchTerm
              (keyup)="onSearchContract(searchTerm.value)"
            />
          </div>
        </div>
        <table class="table table-responsive table-body">
          <thead>
          <tr>
            <th>
              <input
                [checked]="checkAll"
                type="checkbox"
                name="id"
                class="form-check-input m-0"
                (change)="onCheckAll($event)"
                id=""
              />
            </th>
            <th class="text-secondary">STT</th>
            <th class="text-secondary">Tên liên hệ</th>
            <th class="text-secondary">Tiêu đề</th>
            <th class="text-secondary">Nội dung</th>
            <th class="text-secondary">Email</th>
            <th class="text-secondary">Số điện thoại</th>
            <th class="text-secondary">Bài ứng tuyển</th>
            <th class="text-secondary">Ngày tạo</th>
            <th class="text-secondary">Xem CV ứng tuyển</th>
          </tr>
          </thead>

          <tbody>
          <tr *ngFor="let contact of contacts; index as index">
            <td>
              <input
                [checked]="checked"
                type="checkbox"
                class="form-check-input"
                [value]="contact.id"
                [checked]="checkedCheckBox[index]"
                (change)="onCheckboxValue($event , index)"
              />
            </td>
            <td>{{ (pageNumber * 10) + index + 1 }}</td>
            <td>{{ contact.name }}</td>
            <td class="text-truncate" style="width: 50px">
              {{ contact.subject }}
            </td>
            <td class="text-truncate" style="max-width: 150px">
              {{ contact.content }}
            </td>
            <td class="text-truncate" style="max-width: 100px">{{ contact.email }}</td>
            <td class="text-truncate" style="max-width: 100px">{{ contact.phoneNumber }}</td>
            <td class="text-truncate" style="max-width: 100px">{{ contact.recruitmentTitle }}</td>
            <td>{{ contact.createTime | date: "dd/MM/yyyy" }}</td>
            <td>
              <i
                [hidden]="contact.status === -1"
                class="material-symbols-outlined"
                data-bs-toggle="modal"
                data-bs-target="#view-modal"
                (click)="openPDF(contact.file)"
              >
                visibility
              </i>
            </td>
          </tr>
          </tbody>

        </table>

        <div class="table-footer d-flex align-items-center">
          <a [hidden]="totalItemContact===0" class="ms-3" style="text-decoration: none">Hiển thị từ {{ (pageNumberContact * 10) + 1 }}
            - {{ (pageNumberContact * 10) + numberOfElementsContact }}/{{ totalItemContact }} thông tin </a>
          <a [hidden]="totalItemContact > 0" class="ms-3" style="text-decoration: none">Không có dữ liệu</a>
          <div style="width: 70%; float: right;">
            <p-paginator
              [rows]="itemPerPageContact"
              [pageLinkSize]="totalPageContact"
              [totalRecords]="totalItemContact"
              (onPageChange)="onPageChangeContract($event)"
            ></p-paginator>
          </div>
        </div>
      </div>


      <!-- Add-modal -->
      <div
        class="modal fade"
        id="add-modal"
        tabindex="-1"
        aria-labelledby="add-modal"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="add-modal">Thêm mới tuyển dụng</h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <form #formAdd="ngForm">
                <div class="py-2">
                  <label for="title">Tiêu đề <span style="color: red">*</span></label>
                  <input
                    type="text"
                    name="title"
                    [(ngModel)]="recruitment.title"
                    #title="ngModel"
                    class="form-control my-2"
                    placeholder="nhập tiêu đề"
                    required
                  />
                </div>
                <div *ngIf="title.invalid && (title.dirty || title.touched)">
                  <p class="text-danger" *ngIf="title.errors?.['required']">
                    Vui lòng nhập tiêu đề tuyển dụng
                  </p>
                </div>
                <div class="py-2">
                  <label for="content">Nội dung <span style="color: red">*</span></label>
                  <div class="my-2">
                    <ckeditor
                      class="form-content"
                      name="content"
                      #content="ngModel"
                      [editor]="editor"
                      [(ngModel)]="recruitment.content"
                      required
                    ></ckeditor>
                  </div>
                </div>
                <div *ngIf="content.invalid && (content.dirty || content.touched)">
                  <p class="text-danger" *ngIf="content.errors?.['required']">
                    Vui lòng nhập nội dung
                  </p>
                </div>

                <div class="py-2">
                  <label for="contentShort">Tóm tắt nội dung <span style="color: red">*</span></label>
                  <input
                    type="text"
                    name="contentShort"
                    [(ngModel)]="recruitment.contentShort"
                    #contentShort="ngModel"
                    class="form-control my-2"
                    placeholder="nhập tóm tắt nội dung"
                    required
                  />
                </div>
                <div *ngIf="contentShort.invalid && (contentShort.dirty || contentShort.touched)">
                  <p class="text-danger" *ngIf="contentShort.errors?.['required']">
                    Vui lòng nhập tóm tắt nội dung
                  </p>
                </div>

                <div class="py-2">
                  <label for="salaryId">Mức lương <span style="color: red">*</span></label>
                  <select
                    name="salaryId"
                    [(ngModel)]="recruitment.salaryId"
                    #salaryId="ngModel"
                    class="form-select my-2"
                    required
                  >
                    <option [value]="0" disabled>Chọn mức lương</option>
                    <option
                      *ngFor="let salary of salarys"
                      [value]="salary.id"
                    >
                      {{ salary.lvaMean }}
                    </option>
                  </select>
                </div>
                <div
                  *ngIf="
                salaryId.invalid &&
                (salaryId.dirty || salaryId.touched)
              "
                >
                  <p
                    class="text-danger"
                    *ngIf="salaryId.errors?.['required']"
                  >
                    Vui lòng chọn mức lương
                  </p>
                </div>
                <div class="py-2">
                  <label for="experienceId">Kinh nghiệm <span style="color: red">*</span></label>
                  <select
                    name="experienceId"
                    [(ngModel)]="recruitment.experienceId"
                    #experienceId="ngModel"
                    class="form-select my-2"
                    required
                  >
                    <option [value]="0" disabled>Chọn kinh nghiệm</option>
                    <option *ngFor="let experience of experiences" [value]="experience.id">
                      {{ experience.lvaMean }}
                    </option>
                  </select>
                </div>
                <div *ngIf="experienceId.invalid && (experienceId.dirty || experienceId.touched)">
                  <p class="text-danger" *ngIf="experienceId.errors?.['required']">
                    Vui lòng chọn kinh nghiệm
                  </p>
                </div>

                <div class="py-2">
                  <label for="levelId">Trình độ <span style="color: red">*</span></label>
                  <select
                    name="levelId"
                    [(ngModel)]="recruitment.levelId"
                    #levelId="ngModel"
                    class="form-select my-2"
                    required
                  >
                    <option [value]="0" disabled>Chọn trình độ</option>
                    <option *ngFor="let level of levels" [value]="level.id">
                      {{ level.lvaMean }}
                    </option>
                  </select>
                </div>
                <div *ngIf="levelId.invalid && (levelId.dirty || levelId.touched)">
                  <p class="text-danger" *ngIf="levelId.errors?.['required']">
                    Vui lòng chọn trình độ
                  </p>
                </div>

                <div class="py-2">
                  <label for="skillIds">Ngôn ngữ lập trình <span style="color: red">*</span></label>

                  <div>
                    <div
                      class="my-2">
                      <p-multiSelect
                        [style]="{'height': '38px', 'width': '100%'}"
                        *ngIf="skills && recruitment.skillIds"
                        [options]="skills"
                        [(ngModel)]="recruitment.skillIds"
                        [filter]=false
                        (onChange)="onCheckboxChange($event)"
                        defaultLabel="Chọn ngôn ngữ lập trình"
                        optionLabel="lvaMean"
                        selectedItemsLabel="{0} ngôn ngữ đã chọn">
                      </p-multiSelect>
                    </div>
                  </div>
                </div>



                <div class="py-2">
                  <label for="quantity">Số lượng <span style="color: red">*</span></label>
                  <input
                    type="number"
                    name="quantity"
                    [(ngModel)]="recruitment.quantity"
                    #quantity="ngModel"
                    class="form-control my-2"
                    placeholder="nhập nội dung"
                    required
                  />
                </div>
                <div *ngIf="quantity.invalid && (quantity.dirty || quantity.touched)">
                  <p class="text-danger" *ngIf="quantity.errors?.['required']">
                    Vui lòng nhập số lượng
                  </p>
                </div>

                <div class="py-2">
                  <label for="recruitmentFrom">Ngày bắt đầu <span style="color: red">*</span></label>
                  <input
                    type="date"
                    name="recruitmentFrom"
                    [(ngModel)]="recruitment.recruitmentFrom"
                    #recruitmentFrom="ngModel"
                    class="form-control my-2"
                    required
                  />
                </div>

                <div class="py-2">
                  <label for="recruitmentTo">Ngày kết thúc <span style="color: red">*</span></label>
                  <input
                    type="date"
                    name="recruitmentTo"
                    [(ngModel)]="recruitment.recruitmentTo"
                    #recruitmentTo="ngModel"
                    class="form-control my-2"
                    required
                  />
                </div>

                <div class="d-flex justify-content-end gap-2">
                  <button class="btn btn-outline-secondary" data-bs-dismiss="modal">
                    Hủy
                  </button>
                  <button
                    [disabled]="formAdd.invalid"
                    type="submit"
                    class="btn btn-primary"
                    data-bs-dismiss="modal"
                    (click)="onSubmit(formAdd.value)"
                  >
                    Thêm Mới
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <!-- Edit-modal -->
      <div
        class="modal fade"
        id="edit-modal"
        tabindex="-1"
        aria-labelledby="edit-modal"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="edit-modal">Sửa tuyển dụng</h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <form #formEdit="ngForm">

                <div class="py-2">
                  <label for="title">Tiêu đề <span style="color: red">*</span></label>
                  <input
                    type="text"
                    name="title"
                    [(ngModel)]="recruitmentDetail.title"
                    #title="ngModel"
                    class="form-control my-2"
                    placeholder="nhập tiêu đề"
                    required
                  />
                </div>
                <div *ngIf="title.invalid && (title.dirty || title.touched)">
                  <p class="text-danger" *ngIf="title.errors?.['required']">
                    Vui lòng nhập tiêu đề tuyển dụng
                  </p>
                </div>
                <div class="py-2">
                  <label for="content">Nội dung <span style="color: red">*</span></label>
                  <div class="my-2">
                    <ckeditor
                      class="form-content"
                      name="content"
                      #content="ngModel"
                      [editor]="editor"
                      [(ngModel)]="recruitmentDetail.content"
                      required
                    ></ckeditor>
                  </div>
                </div>
                <div *ngIf="content.invalid && (content.dirty || content.touched)">
                  <p class="text-danger" *ngIf="content.errors?.['required']">
                    Vui lòng nhập nội dung
                  </p>
                </div>

                <div class="py-2">
                  <label for="contentShort">Tóm tắt nội dung <span style="color: red">*</span></label>
                  <input
                    type="text"
                    name="contentShort"
                    [(ngModel)]="recruitmentDetail.contentShort"
                    #contentShort="ngModel"
                    class="form-control my-2"
                    placeholder="nhập tóm tắt nội dung"
                    required
                  />
                </div>
                <div *ngIf="contentShort.invalid && (contentShort.dirty || contentShort.touched)">
                  <p class="text-danger" *ngIf="contentShort.errors?.['required']">
                    Vui lòng nhập tóm tắt nội dung
                  </p>
                </div>

                <div class="py-2">
                  <label for="salaryId">Mức lương <span style="color: red">*</span></label>
                  <select
                    name="salaryId"
                    [(ngModel)]="recruitmentDetail.salaryId"
                    #salaryId="ngModel"
                    class="form-select my-2"
                    required
                  >
                    <option [value]="0" disabled>Chọn mức lương</option>
                    <option
                      *ngFor="let salary of salarys"
                      [value]="salary.id"
                    >
                      {{ salary.lvaMean }}
                    </option>
                  </select>
                </div>
                <div
                  *ngIf="
                salaryId.invalid &&
                (salaryId.dirty || salaryId.touched)
              "
                >
                  <p
                    class="text-danger"
                    *ngIf="salaryId.errors?.['required']"
                  >
                    Vui lòng chọn mức lương
                  </p>
                </div>
                <div class="py-2">
                  <label for="experienceId">Kinh nghiệm <span style="color: red">*</span></label>
                  <select
                    name="experienceId"
                    [(ngModel)]="recruitmentDetail.experienceId"
                    #experienceId="ngModel"
                    class="form-select my-2"
                    required
                  >
                    <option [value]="0" disabled>Chọn kinh nghiệm</option>
                    <option *ngFor="let experience of experiences" [value]="experience.id">
                      {{ experience.lvaMean }}
                    </option>
                  </select>
                </div>
                <div *ngIf="experienceId.invalid && (experienceId.dirty || experienceId.touched)">
                  <p class="text-danger" *ngIf="experienceId.errors?.['required']">
                    Vui lòng chọn kinh nghiệm
                  </p>
                </div>

                <div class="py-2">
                  <label for="levelId">Trình độ <span style="color: red">*</span></label>
                  <select
                    name="levelId"
                    [(ngModel)]="recruitmentDetail.levelId"
                    #levelId="ngModel"
                    class="form-select my-2"
                    required
                  >
                    <option [value]="0" disabled>Chọn trình độ</option>
                    <option *ngFor="let level of levels" [value]="level.id">
                      {{ level.lvaMean }}
                    </option>
                  </select>
                </div>
                <div *ngIf="levelId.invalid && (levelId.dirty || levelId.touched)">
                  <p class="text-danger" *ngIf="levelId.errors?.['required']">
                    Vui lòng chọn trình độ
                  </p>
                </div>

                <div class="py-2">
                  <label for="skillIds">Ngôn ngữ lập trình <span style="color: red">*</span></label>

                    <div
                      class="my-2">
                      <p-multiSelect
                        [style]="{'height': '38px', 'width': '100%'}"
                        *ngIf="skills && recruitmentDetail.skills"
                        [options]="skills"
                        [(ngModel)]="recruitmentDetail.skills"
                        [filter]="false"
                        (onChange)="onCheckboxChange($event)"
                        [ngModelOptions]="{standalone: true}"
                        selectedItemsLabel="{0} ngôn ngữ đã chọn"
                        defaultLabel="Chọn ngôn ngữ lập trình"
                        optionLabel="lvaMean">
                      </p-multiSelect>
                    </div>
                  </div>

                <div class="py-2">
                  <label for="quantity">Số lượng <span style="color: red">*</span></label>
                  <input
                    type="number"
                    name="quantity"
                    [(ngModel)]="recruitmentDetail.quantity"
                    #quantity="ngModel"
                    class="form-control my-2"
                    placeholder="nhập nội dung"
                    required
                  />
                </div>
                <div *ngIf="quantity.invalid && (quantity.dirty || quantity.touched)">
                  <p class="text-danger" *ngIf="quantity.errors?.['required']">
                    Vui lòng nhập số lượng
                  </p>
                </div>

                <div class="py-2">
                  <label for="recruitmentFrom">Ngày bắt đầu <span style="color: red">*</span></label>
                  <input
                    type="date"
                    name="recruitmentFrom"
                    [(ngModel)]="recruitmentDetail.recruitmentFrom"
                    #recruitmentFrom="ngModel"
                    class="form-control my-2"
                    required
                    (change)="onDateChange()"
                  />
                </div>

                <div class="py-2">
                  <label for="recruitmentTo">Ngày kết thúc <span style="color: red">*</span></label>
                  <input
                    type="date"
                    name="recruitmentTo"
                    [(ngModel)]="recruitmentDetail.recruitmentTo"
                    #recruitmentTo="ngModel"
                    class="form-control my-2"
                    required
                    (change)="onDateChange()"
                  />
                  <div *ngIf="recruitmentTo.errors?.['min']" class="text-danger">
                    Ngày kết thúc phải sau ngày bắt đầu
                  </div>
                </div>

                <div class="d-flex justify-content-end gap-2">
                  <button class="btn btn-outline-secondary" data-bs-dismiss="modal">
                    Hủy
                  </button>
                  <button
                    type="submit"
                    class="btn btn-primary"
                    data-bs-dismiss="modal"
                    [disabled]="formEdit.invalid"
                    (click)="onEdit(recruitmentDetail.id, formEdit.value)"
                  >
                    Lưu
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
