export class RoleUtils {
  public static isAccepted(role: string[], scope: string[] | null): boolean {
    if (scope == null) return false;

    const matchRole = role.filter((x) => {
      return scope.indexOf(x) !== -1;
    });

    return matchRole !== null && matchRole.length > 0;
  }
}
