<div class="container-fluid p-4">
  <p-toast></p-toast>
  <p-confirmDialog
    [style]="{ width: '50vw' }"
    [baseZIndex]="10000"
  ></p-confirmDialog>
  <!-- Alert -->
  <div>
    <div class="fixed-bottom d-flex justify-content-center">
      <div
        [hidden]="selectedValue.length === 0"
        class="alert alert-danger w-50"
        role="alert"
      >
        <div class="d-flex justify-content-between align-items-center gap-2">
          <p class="my-0">Đã chọn {{ selectedValue.length }} phần tử</p>
          <div>
            <button
            class="btn btn-light px-4 cursor-pointer me-3"
            (click)="onCheckBox()"
          >
            Hủy
          </button>

            <button
            class="btn btn-danger px-4 cursor-pointer"
            (click)="onDelete(selectedValue)"
          >
            Xóa
          </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Header -->
  <div class="d-flex justify-content-between">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a class="text-dark text-decoration-none" routerLink="/admin"
            >Admin</a
          >
        </li>
        <li class="breadcrumb-item">
          <a class="text-dark text-decoration-none" routerLink="/organization"
            >Đối tác</a
          >
        </li>
      </ol>
    </nav>
    <div class="d-flex gap-2">
      <button
        class="d-flex gap-2 btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#add-modal"
      >
        <i class="material-symbols-outlined"> person_add </i>
        Thêm mới
      </button>
    </div>
  </div>
  <!-- Table -->
  <div class="table-wrapper my-4">
    <div class="table-responsive border border-bottom-0 rounded-top">
      <div class="table-header bg-light rounded-top">
        <div class="d-flex justify-content-between align-items-center p-2">
          <div class="d-flex gap-2">
            <select
              (change)="onFilter($event)"
              class="form-select"
              style="max-width: 200px"
            >
              <option [value]="1">Lọc theo</option>
              <option [value]="0">Tất cả</option>
              <option [value]="1">Hoạt động</option>
              <option [value]="-1">Không hoạt động</option>
            </select>
            <select
              class="form-select"
              style="max-width: 200px"
              (change)="onSort($event)"
            >
              <option value="">Sắp xếp theo</option>
              <option value="id_asc">id tăng dần</option>
              <option value="id_desc">id giảm dần</option>
              <option value="name_asc">Từ a - z</option>
              <option value="name_desc">Từ z - a</option>
              <option value="create_desc">Nhân viên mới thêm</option>
              <option value="update_desc">Nhân viên mới sửa</option>
            </select>
          </div>
          <input
            type="text"
            class="form-control"
            placeholder="Tìm kiếm..."
            style="max-width: 260px"
            #searchInput
            (input)="onSearch(searchInput.value)"
          />
        </div>
        <!--main-Table -->
      </div>
      <table class="table table-responsive table-body">
        <thead>
          <tr>
            <th><input [checked]="checkAll" type="checkbox" class="form-check-input" (change)="onCheckAll($event)" /></th>
            <th class="text-secondary">STT</th>
            <th class="text-secondary">Tên đối tác</th>
            <th class="text-secondary">Hình ảnh</th>
            <th class="text-secondary">Trạng thái</th>
            <th class="text-secondary">Ngày tạo</th>
            <th class="text-secondary">Ngày sửa</th>
            <th class="text-secondary">Người tạo</th>
            <th class="text-secondary">Người sửa</th>
            <th class="text-secondary">Thao tác</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let partner of partners; index as index">
            <td>
              <input
              [checked]="checked"
                type="checkbox"
                class="form-check-input"
                [value]="partner.id"
                [checked]="checkedCheckBox[index]"
                (change)="onCheckValue($event, index)"
              />
            </td>
            <td>{{ (pageNumber * 10) + index + 1 }}</td>
            <td>{{ partner.name }}</td>
            <td>
              <img
                [src]="partner.image"
                class="image-fluid"
                style="width: 50px"
              />
            </td>
            <td>
              <div
                class="d-inline-block bg-success bg-opacity-25 text-success p-2 rounded"
                [ngClass]="{
                  'text-success bg-success': partner.status === 1,
                  'text-danger bg-danger ': partner.status === -1,
                  'text-warning bg-warning': partner.status === null
                }"
              >
                {{ partner.status === 1 ? "Hoạt động" : "Không hoạt động" }}
              </div>
            </td>
            <td>{{ partner.createTime | date: "dd/MM/yyyy" }}</td>
            <td>{{ partner.updateTime | date: "dd/MM/yyyy" }}</td>
            <td>{{ partner.createBy }}</td>
            <td>{{ partner.updateBy }}</td>
            <td>
              <i
              [hidden]="partner.status === -1"
                class="material-symbols-outlined"
                data-bs-toggle="modal"
                data-bs-target="#edit-modal"
                (click)="getById(partner.id)"
              >
                edit
              </i>
              <i
                [hidden]="partner.status === -1"
                class="material-symbols-outlined mx-2"
                (click)="onDelete(partner.id)"
              >
                delete
              </i>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="table-footer d-flex align-items-center border-bottom">
        <a [hidden]="totalItem === 0" class="ms-3" style="text-decoration: none">Hiển thị từ {{(pageNumber * 10) + 1}} - {{(pageNumber * 10)+ numberOfElements}}/{{totalItem}} đối tác</a>
        <a [hidden]="totalItem > 0" class="ms-3" style="text-decoration: none">Không có dữ liệu</a>
        <div style="width: 70%; float: right;">
          <p-paginator
          [rows]="itemPerPage"
          [pageLinkSize]="totalPage"
          [totalRecords]="totalItem"
          (onPageChange)="onPageChange($event)"
        ></p-paginator>
        </div>
    </div>
  </div>

  <!-- Add-modal -->
  <div
    class="modal fade"
    id="add-modal"
    tabindex="-1"
    aria-labelledby="add-modal"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="add-modal">Thêm mới đối tác</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form
            #formAdd="ngForm"
            (submit)="onSubmit(formAdd.value)"
            enctype="multipart/form-data"
          >
            <div class="py-2">
              <label for="name">Tên đối tác <span style="color: red">*</span></label>
              <input
                type="text"
                name="name"
                #name="ngModel"
                [(ngModel)]="partner.name"
                class="form-control my-2"
                placeholder="nhập tên đối tác"
                required
              />
            </div>
            <div *ngIf="name.invalid && (name.dirty || name.touched)">
              <p class="text-danger" *ngIf="name.errors?.['required']">
                Vui lòng nhập tên đối tác
              </p>
            </div>
            <div class="py-2">
              <label for="image">Hình ảnh <span style="color: red">*</span></label>
              <input
                type="file"
                name="image"
                #image="ngModel"
                (change)="onChangeFile($event)"
                [(ngModel)]="partner.image"
                class="form-control my-2"
                required
              />
            </div>
            <div *ngIf="image.invalid && (image.dirty || image.touched)">
              <p class="text-danger" *ngIf="image.errors?.['required']">
                Vui lòng chọn ảnh đối tác
              </p>
            </div>
            <div class="d-flex justify-content-end gap-2">
              <button
                type="button"
                class="btn btn-outline-secondary"
                data-bs-dismiss="modal"
              >
                Hủy
              </button>
              <button
                type="submit"
                class="btn btn-primary"
                data-bs-dismiss="modal"
                [disabled]="formAdd.invalid"
              >
                Thêm Mới
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- Edit-modal -->
  <div
    class="modal fade"
    id="edit-modal"
    tabindex="-1"
    aria-labelledby="edit-modal"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="edit-modal">Sửa đối tác</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form
            #formEdit="ngForm"
            (submit)="onEdit(partnerDetail.id, formEdit.value)"
          >
            <div class="py-2">
              <label for="name">Tên đối tác <span style="color: red">*</span></label>
              <input
                type="text"
                name="name"
                #name="ngModel"
                [value]="partnerDetail.name"
                [(ngModel)]="partnerDetail.name"
                class="form-control my-2"
                placeholder="nhập tên đối tác"
                required
              />
            </div>
            <div *ngIf="name.invalid && (name.dirty || name.touched)">
              <p class="text-danger" *ngIf="name.errors?.['required']">
                Vui lòng nhập tên đối tác
              </p>
            </div>
            <div class="py-2">
              <label for="image">Hình ảnh</label>
              <input
                type="file"
                name="image"
                (change)="onChangeFile($event)"
                [(ngModel)]="partnerDetail.image"
                class="form-control my-2"
              />
            </div>
            <div *ngIf="image.invalid && (image.dirty || image.touched)">
              <p class="text-danger" *ngIf="image.errors?.['required']">
                Vui lòng chọn ảnh
              </p>
            </div>
            <div class="d-flex justify-content-end gap-2">
              <button
                type="button"
                class="btn btn-outline-secondary"
                data-bs-dismiss="modal"
              >
                Hủy
              </button>
              <button
                type="submit"
                class="btn btn-primary"
                data-bs-dismiss="modal"
                [disabled]="formEdit.invalid"
              >
                Lưu
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- Import-modal -->
  <div
    class="modal fade"
    id="import-modal"
    tabindex="-1"
    aria-labelledby="import-modal"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="import-modal">Import excel</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form #formImport="ngForm" (submit)="onUploadFile()">
            <div class="py-2">
              <label for="name">File excel</label>
              <input
                type="file"
                name="file"
                (change)="onChangeFile($event)"
                class="form-control my-2"
              />
            </div>
            <div class="d-flex justify-content-end gap-2">
              <button
                type="button"
                class="btn btn-outline-secondary"
                data-bs-dismiss="modal"
              >
                Hủy
              </button>
              <button
                type="submit"
                class="btn btn-primary"
                data-bs-dismiss="modal"
              >
                Import
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
