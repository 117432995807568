<div>
  <p-toast></p-toast>
  <app-header></app-header>

  <div class="container">
    <div class="container-fluid company-intro p-0">
      <div class="row">
        <div class="col-7 company-intro-content">
          <h2>Join us</h2>
          <h1 class="pb-4 text-primary">Smart Solutions VietNam</h1>
          <h2>Bạn đang tìm một nơi để khai phá bản thân? Hãy tham gia với chúng
            tôi ngay nào !</h2>
          <p>Với mức tiêu chuẩn công nghệ tiên tiến và cơ hội thăng tiến, Smart
            Solutions hứa hẹn mang đến những trải nghiệm
            tuyệt vời nhất và cơ hội phát huy tối đa khả năng của mình.</p>
        </div>
        <div class="col-5 company-intro-image">
          <img class="image" src="/assets/recruitment_job.png">
        </div>
      </div>
    </div>


    <div class="container-fluid search-and-filter p-5">
    <div class="row mb-5 text-center">
      <h2>Are You Ready To <span class="text-primary">Join Our Team?</span>
      </h2>
    </div>
    <form [formGroup]="searchForm" (ngSubmit)="onSubmit()">
      <div class="row mx-5 my-4 px-3 ">
        <div class="col-12 md:col-4">
          <div class="p-inputgroup search-bar">
            <input type="text" pInputText placeholder="Tìm kiếm"
                   formControlName="search"
                   class="pi pi-search gray-icon">
            <button type="submit" pButton pRipple class="search-button">
              <i class="pi pi-search gray-icon"></i>
            </button>
          </div>
        </div>
      </div>
    </form>

    <div class="row">
      <div
        class="filters d-flex justify-content-center align-items-center gap-3">
        <p-multiSelect
          *ngIf="skills"
          [options]="skills"
          [(ngModel)]="selectedSkills"
          [filter]=false
          (onChange)="onFilterChange()"
          defaultLabel="Kỹ năng"
          optionLabel="lvaMean"
          selectedItemsLabel="{0} items selected">
        </p-multiSelect>

        <p-dropdown [options]="levels" [(ngModel)]="selectedLevel"
                    (onChange)="onFilterChange()"
                    placeholder="Kinh nghiệm" optionLabel="lvaMean"
                    [showClear]="true"></p-dropdown>
      </div>
    </div>
  </div>

    <div class="job-listings" *ngFor="let job of jobs">
        <div class="my-4 p-4 p-card job-card">
          <div class="row m-2 mt-3 job-title align-items-center">
            <a [routerLink]="['/job/job-detail', job.id]"
               class="col-9 m-0 fw-bold fs-5 text-dark text-decoration-none">{{ job.title }}</a>
            <div class="col-3 d-flex justify-content-end">
              <button pButton pRipple type="button" label="Apply Now"
                      class="m-0 p-button-outlined p-button-rounded p-button-info"
                      data-bs-toggle="modal"
                      data-bs-target="#applyDialogModal"></button>
              <app-apply-dialog [title]="job.title"
                                [recruitmentId]="job.id"></app-apply-dialog>
            </div>


          </div>

          <div class="row m-2 mb-3 d-flex align-items-center">
            <a [routerLink]="['/job/job-detail', job.id]"
               class="col-9 m-0 job-content text-dark text-decoration-none">{{ job.contentShort }}</a>
            <div
              class="col-3 m-auto d-flex justify-content-end">
            <span class="d-flex align-items-center">
              <i class="pi pi-clock"></i>
              <span class="ms-2">Hạn nộp: {{ job.recruitmentTo | date:
                "dd/MM/yyyy" }}</span>
              </span>
            </div>
          </div>
      </div>
    </div>

    <div class="table-footer d-flex align-items-center">
      <div style="width: 100%; float: none;">
        <p-paginator
          [rows]="itemPerPage"
          [totalRecords]="totalItem"
          [rowsPerPageOptions]="[6, 12, 18, 24]"
          [showCurrentPageReport]="true"
          [first]="pageNumber * itemPerPage"
          (onPageChange)="onPageChange($event)"
        ></p-paginator>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
