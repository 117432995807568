<div class="container-fluid p-4">
  <p-toast></p-toast>
  <p-confirmDialog
    [style]="{ width: '50vw' }"
    [baseZIndex]="10000"
  ></p-confirmDialog>
  <!-- Alert -->
  <div>
    <div class="fixed-bottom d-flex justify-content-center">
      <div
        *ngIf="listId.length > 0"
        class="alert alert-danger w-50"
        role="alert"
      >
        <div class="d-flex justify-content-between align-items-center gap-2">
          <p style="margin-bottom: 0">Đã chọn {{ listId.length }} phần tử</p>
          <div>
            <button
            class="btn btn-light px-4 cursor-pointer me-3"
            (click)="onCheckBox()"
          >
            Hủy
          </button>

            <button
            class="btn btn-danger px-4 cursor-pointer"
            (click)="onDelete(listId)"
          >
            Xóa
          </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Header -->
  <div class="d-flex justify-content-between">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a class="text-dark text-decoration-none" routerLink="/admin"
            >Admin</a
          >
        </li>
        <li class="breadcrumb-item">
          <a class="text-dark text-decoration-none" routerLink="/organization"
            >Liên hệ</a
          >
        </li>
      </ol>
    </nav>

  </div>

  <!-- Table -->
  <div
    class="table-wrapper table-responsive border border-bottom-0 rounded-top mt-4"
  >
    <div class="table-header bg-light rounded-top">
      <div class="d-flex justify-content-between align-items-center p-2">
        <div class="d-flex gap-2">
          <select
            class="form-select"
            style="max-width: 200px"
            (change)="onFilter($event)"
          >
            <option [value]="1" selected>Lọc theo</option>
            <option [value]="0">Tất cả</option>
            <option [value]="1">Hoạt động</option>
            <option [value]="-1">Không hoạt động</option>
          </select>
          <select
            class="form-select"
            style="max-width: 200px"
            (change)="onSort($event)"
          >
            <option [value]="0" selected disabled>Sắp xếp theo</option>
            <option value="id_asc">id tăng dần</option>
            <option value="id_desc">id giảm dần</option>
            <option value="name_asc">Từ a - z</option>
            <option value="name_desc">Từ z - a</option>
            <option value="create_desc">Liên hệ mới thêm</option>
            <option value="update_desc">Liên hệ mới sửa</option>
          </select>
        </div>

        <input
          type="text"
          class="form-control"
          #searchTerm
          placeholder="Tìm kiếm..."
          style="max-width: 260px"
          (keyup)="onSearch(searchTerm.value)"
        />
      </div>
    </div>
    <table class="table table-responsive table-body">
      <thead>
        <tr>
          <th><input [checked]="checkAll" type="checkbox" class="form-check-input" (change)="onCheckAll($event)"  /></th>
          <th class="text-secondary">STT</th>
          <th class="text-secondary">Tên liên hệ</th>
          <th class="text-secondary">Tiêu đề</th>
          <th class="text-secondary">Nội dung</th>
          <th class="text-secondary">Email</th>
          <th class="text-secondary">Số điện thoại</th>
          <th class="text-secondary">Ngày tạo</th>
          <th class="text-secondary">Thao tác</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let contact of contacts; index as index">
          <td>
            <input
            [checked]="checked"
              type="checkbox"
              class="form-check-input"
              [value]="contact.id"
              [checked]="checkedCheckBox[index]"
              (change)="onCheckboxValue($event , index)"
            />
          </td>
          <td>{{ (pageNumber * 10) + index + 1 }}</td>
          <td>{{ contact.name }}</td>
          <td class="text-truncate" style="width: 50px">
            {{ contact.subject }}
          </td>
          <td class="text-truncate" style="max-width: 150px">
            {{ contact.content }}
          </td>
          <td class="text-truncate" style="max-width: 100px">{{ contact.email }}</td>
          <td class="text-truncate" style="max-width: 100px">{{ contact.phoneNumber }}</td>
          <td>{{ contact.createTime | date: "dd/MM/yyyy" }}</td>
          <td>
            <i
              [hidden]="contact.status === -1"
              class="material-symbols-outlined"
              data-bs-toggle="modal"
              data-bs-target="#edit-modal"
              (click)="getById(contact.id)"
            >
              edit
            </i>
            <i
            [hidden]="contact.status === -1"
              class="material-symbols-outlined mx-2"
              (click)="onDelete(contact.id)"
            >
              delete
            </i>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="table-footer d-flex align-items-center border-bottom">
      <a [hidden]="totalItem === 0" class="ms-3" style="text-decoration: none">Hiển thị từ {{(pageNumber * 10) + 1}} - {{(pageNumber * 10)+numberOfElements}}/{{totalItem}} liên hệ</a>
      <a [hidden]="totalItem > 0" class="ms-3" style="text-decoration: none">Không có dữ liệu</a>
      <div style="width: 70%; float: right;">
        <p-paginator
        [rows]="itemPerPage"
        [pageLinkSize]="totalPage"
        [totalRecords]="totalItem"
        (onPageChange)="onPageChange($event)"
      ></p-paginator>
      </div>
  </div>
  <!-- Edit-modal -->
  <div
  class="modal fade"
  id="edit-modal"
  tabindex="-1"
  aria-labelledby="edit-modal"
  aria-hidden="true"
  >
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="edit-modal">Sửa Liên hệ</h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <form
          #formEdit="ngForm"
          (submit)="onEdit(contactDetail.id, formEdit.value)"
        >
          <div class="py-2">
            <label for="name">Tên liên hệ</label>
            <input
              type="text"
              name="name"
              #name="ngModel"
              [value]="contactDetail.name"
              [(ngModel)]="contactDetail.name"
              class="form-control my-2"
              placeholder="Nhập tên liên hệ"
              required pInputText
            />
            <small id="name-help" *ngIf="name.errors?.['required']" class="p-error block">Vui lòng tên liên hệ</small>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="py-2">
                <label for="email">Email</label>
                <input type="text"
                name="email"
                #email="ngModel"
                [value]="contactDetail.email"
                [(ngModel)]="contactDetail.email"
                class="form-control my-2"
                placeholder="Nhập địa chỉ email"
                required pInputText pattern="^([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$"/>
                <small id="email-help" *ngIf="email.errors?.['required']" class="p-error block">Vui lòng nhập địa chỉ email</small>
                <small id="email-help" *ngIf="email.errors?.['pattern']" class="p-error block">Địa chỉ email này không hợp lệ</small>
              </div>
            </div>
            <div class="col-6">
              <div class="py-2">
                <label for="name">Số điện thoại</label>
                <input
                  type="text"
                  name="phoneNumber"
                  #phoneNumber="ngModel"
                  [value]="contactDetail.phoneNumber"
                  [(ngModel)]="contactDetail.phoneNumber"
                  class="form-control my-2"
                  placeholder="Nhập số điện thoại"
                  required pInputText pattern="^(0?)(3[2-9]|5[6|8|9]|7[0|6-9]|8[0-6|8|9]|9[0-4|6-9])[0-9]{7}$"
                />
                <small id="phoneNumber-help" *ngIf="phoneNumber.errors?.['required']" class="p-error block">Vui lòng nhập số điện thoại</small>
                <small id="phoneNumber-help" *ngIf="phoneNumber.errors?.['pattern']" class="p-error block">Số điện thoại này không hợp lệ</small>
              </div>
            </div>
          </div>
          <div class="py-2">
            <label for="name">Tiêu đề</label>
            <input
              type="text"
              name="subject"
              #subject="ngModel"
              [value]="contactDetail.subject"
              [(ngModel)]="contactDetail.subject"
              class="form-control my-2"
              placeholder="Nhập tiêu đề"
              required pInputText
            />
            <small id="subject-help" *ngIf="subject.errors?.['required']" class="p-error block">Vui lòng nhập tiêu đề</small>
          </div>
          <div class="py-2">
            <label for="information">Nội dung</label>
            <textarea
              name="content"
              class="form-control my-2"
              [cols]="0"
              [rows]="10"
              [value]="contactDetail.content"
              [(ngModel)]="contactDetail.content"
              #content="ngModel"
              required
            ></textarea>
            <small id="content-help" *ngIf="content.errors?.['required']" class="p-error block">Vui lòng nhập nội dung</small>
          </div>
          <div class="d-flex justify-content-end gap-2">
            <button
              type="button"
              class="btn btn-outline-secondary"
              data-bs-dismiss="modal"
            >
              Hủy
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              data-bs-dismiss="modal"
            >
              Lưu
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  </div>
</div>
