import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { user } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import {MenuService} from "../../menu.service";
import {HttpErrorResponse} from "@angular/common/http";
import {ToastService} from "../../toast.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  user: user = {
    username: '',
    password: ''
  }
  hide = true;

  constructor(
    private authService: AuthService,
    private router: Router,
    private toast: ToastService,
    private menuService: MenuService
  ) { }

  ngOnInit(): void {
    //this.authService.getToken()
    //this.authService.getUsername()
  }

  showPassword() {
    this.hide = !this.hide;
  }

  submit(data: user) {
    this.authService.login(data).subscribe({
      next: (data: user) => {
        localStorage.setItem('user', JSON.stringify(data));
        this.menuService.updateMenu();
        this.toast.success('Đăng nhập', 'Đăng nhập thành công');
        this.router.navigateByUrl('/admin');
      },
      error: (err: HttpErrorResponse) => {
        console.log("login error: " + err);
        this.toast.error('Đăng nhập', 'Thông tin đăng nhập không chính xác / Đăng nhập hệ thống thất bại, vui lòng thử lại');
      }
    });
  }
}
