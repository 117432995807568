<div class="container-fluid p-4">
  <p-toast></p-toast>
  <p-confirmDialog
    [style]="{ width: '50vw' }"
    [baseZIndex]="10000"
  ></p-confirmDialog>
  <!-- Alert -->
  <div>
    <div class="fixed-bottom d-flex justify-content-center">
      <div
        [hidden]="selectedValue.length === 0"
        class="alert alert-danger w-50"
        role="alert"
      >
        <div class="d-flex justify-content-between align-items-center gap-2">
          <p class="my-0">Đã chọn {{ selectedValue.length }} phần tử</p>
          <div>
            <button
            class="btn btn-light px-4 cursor-pointer me-3"
            (click)="onCheckBox()"
          >
            Hủy
          </button>
            <button
            class="btn btn-danger px-4 cursor-pointer"
            (click)="onDelete(selectedValue)"
          >
            Xóa
          </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Header -->
  <div class="d-flex justify-content-between">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a class="text-dark text-decoration-none" routerLink="/admin"
            >Admin</a
          >
        </li>
        <li class="breadcrumb-item">
          <a class="text-dark text-decoration-none" routerLink="/organization"
            >Tổ chức</a
          >
        </li>
      </ol>
    </nav>
    <div class="d-flex gap-2">
      <button
        class="p-button-success p-button-sm"
        pButton
        type="button"
        icon="pi pi-download"
        iconPos="left"
        (click)="onDownloadTemplate()"
        label="Download Template"
      >
      </button>
      <button
        class="btn btn-success"
        data-bs-toggle="modal"
        data-bs-target="#import-modal"
      >
        <p class="d-flex align-items-center gap-2 text-white m-0">
          <span class="material-symbols-outlined"> drive_folder_upload </span>
          Import excel
        </p>
        <input type="file" name="file" [hidden]="true" />
      </button>
      <button
        class="d-flex gap-2 btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#add-modal"
      >
        <i class="material-symbols-outlined"> person_add </i>
        Thêm mới
      </button>
    </div>
  </div>
  <div class="my-4">
    <!-- Table -->
    <div class="table-wrapper my-4 table-responsive border rounded">
      <div class="table-header bg-light rounded-top">
        <div class="d-flex justify-content-between align-items-center p-2">
          <div class="d-flex gap-2">
            <select
            name="filterValue"
            class="form-select"
            (change)="onFilter($event)"
            style="max-width: 200px"
          >
            <option [value]="1">Lọc theo</option>
            <option [value]="0">Tất cả</option>
            <option [value]="1">Hoạt động</option>
            <option [value]="-1">Không hoạt động</option>
          </select>

          <select
              class="form-select"
              style="max-width: 200px"
              (change)="onSort($event)"
            >
              <option value="">Sắp xếp theo</option>
              <option value="id_asc">id tăng dần</option>
              <option value="id_desc">id giảm dần</option>
              <option value="name_asc">Từ a - z</option>
              <option value="name_desc">Từ z - a</option>
              <option value="create_desc">Chức danh mới thêm</option>
              <option value="update_desc">Chức danh mới sửa</option>
            </select>
          </div>
          <input
            type="text"
            class="form-control"
            style="max-width: 260px"
            placeholder="Tìm kiếm..."
            #searchInput
            (keyup)="onSearch(searchInput.value)"
          />
        </div>
      </div>
      <table class="table table-body">
        <thead>
          <tr>
            <th>
              <input
              [checked]="checkAll"
                type="checkbox"
                (change)="onCheckAll($event)"
                class="form-check-input"
                style="width: 15px; height: 15px"
              />
            </th>
            <th class="text-secondary">STT</th>
            <th class="text-secondary">Tên chức danh</th>
            <th class="text-secondary">Thông tin</th>
            <th class="text-secondary">Trạng thái</th>
            <th class="text-secondary">Ngày tạo</th>
            <th class="text-secondary">Ngày sửa</th>
            <th class="text-secondary">Người tạo</th>
            <th class="text-secondary">Người sửa</th>
            <th class="text-secondary">Thao tác</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let organization of organizations; index as index">
            <td>
              <input
              [checked]="checked"
                type="checkbox"
                class="form-check-input"
                style="width: 15px; height: 15px"
                [value]="organization.id"
                [checked]="checkedCheckBox[index]"
                (change)="onCheckBoxValue($event, index)"
              />
            </td>
            <td>{{ (pageNumber * 10) + index + 1 }}</td>
            <td>{{ organization.name }}</td>
            <td class="text-truncate" style="max-width: 150px">
              {{ organization.information }}
            </td>
            <td>
              <div
                class="d-inline-block bg-success bg-opacity-25 text-success p-2 rounded"
                [ngClass]="{
                  'text-success bg-success': organization.status === 1,
                  'text-danger bg-danger ': organization.status === -1,
                  'text-warning bg-warning': organization.status === null
                }"
              >
                {{
                  organization.status === 1 ? "Hoạt động" : "Không hoạt động"
                }}
              </div>
            </td>
            <td>{{ organization.createTime | date: "dd/MM/yyyy" }}</td>
            <td>{{ organization.updateTime | date: "dd/MM/yyyy" }}</td>
            <td>{{ organization.createBy }}</td>
            <td>{{ organization.updateBy }}</td>
            <td>
              <i
                [hidden]="organization.status === -1"
                class="material-symbols-outlined"
                data-bs-toggle="modal"
                data-bs-target="#edit-modal"
                (click)="onGetById(organization.id)"
              >
                edit
              </i>
              <i
                [hidden]="organization.status === -1"
                class="material-symbols-outlined mx-2"
                (click)="onDelete(organization.id)"
              >
                delete
              </i>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="table-footer d-flex align-items-center">
        <a [hidden]="totalItem === 0" class="ms-3" style="text-decoration: none">Hiển thị từ {{(pageNumber * 10) + 1}} - {{(pageNumber * 10)+numberOfElements}}/{{totalItem}} tổ chức</a>
        <a [hidden]="totalItem > 0" class="ms-3" style="text-decoration: none">Không có dữ liệu</a>
        <div style="width: 70%; float: right;">
          <p-paginator
          [rows]="itemPerPage"
          [pageLinkSize]="totalPage"
          [totalRecords]="totalItem"
          (onPageChange)="onPageChange($event)"
        ></p-paginator>
        </div>
    </div>
  </div>
</div>

<!-- Add-modal -->
<div
  class="modal fade"
  id="add-modal"
  tabindex="-1"
  aria-labelledby="add-modal"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="add-modal">Thêm tổ chức</h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <form #formAdd="ngForm" enctype="multipart/form-data">
          <div class="py-2">
            <label for="name">Tên tổ chức <span style="color: red">*</span></label>
            <input
              type="text"
              name="name"
              #name="ngModel"
              [(ngModel)]="organization.name"
              class="form-control my-2"
              placeholder="nhập tên tổ chức"
              required
            />
          </div>
          <div *ngIf="name.invalid && (name.dirty || name.touched)">
            <p class="text-danger" *ngIf="name.errors?.['required']">
              Vui lòng nhập tên tổ chức
            </p>
          </div>
          <div class="py-2">
            <label for="information">Thông tin <span style="color: red">*</span></label>
            <textarea
              name="information"
              class="form-control"
              #information="ngModel"
              [(ngModel)]="organization.information"
              cols="30"
              rows="10"
              required
            ></textarea>
          </div>
          <div
            *ngIf="
              information.invalid && (information.dirty || information.touched)
            "
          >
            <p class="text-danger" *ngIf="information.errors?.['required']">
              Vui lòng nhập thông tin
            </p>
          </div>
          <div class="py-2">
            <label for="parentCode">Tổ chức <span style="color: red">*</span></label>
            <select [(ngModel)]="organization.parentCode"
            #parentCode="ngModel" name="parentCode" id="" class="form-select my-2">
              <option [value]="0" disabled>Chọn tổ chức cha</option>
              <option
                *ngFor="let organization of organizations"
                [value]="organization.id"
              >
                {{ organization.name }}
              </option>
            </select>
          </div>
          <div class="d-flex justify-content-end gap-2">
            <button class="btn btn-outline-secondary" (click)="resetForm()" data-bs-dismiss="modal">
              Hủy
            </button>
            <button
              [disabled]="formAdd.invalid || organization.parentCode === 0"
              type="submit"
              class="btn btn-primary"
              data-bs-toggle="modal"
              (click)="onAdd(formAdd.value)"
            >
              Thêm Mới
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- Edit-modal -->
<div
  class="modal fade"
  id="edit-modal"
  tabindex="-1"
  aria-labelledby="edit-modal"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="edit-modal">Sửa tổ chức</h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <form #formEdit="ngForm">
          <div class="py-2">
            <label for="name">Tên tổ chức <span style="color: red">*</span></label>
            <input
              type="text"
              name="name"
              #name="ngModel"
              [(ngModel)]="organizationDetail.name"
              [value]="organizationDetail.name"
              class="form-control my-2"
              placeholder="nhập tên tổ chức"
              required
            />
          </div>
          <div *ngIf="name.invalid && (name.dirty || name.touched)">
            <p class="text-danger" *ngIf="name.errors?.['required']">
              Vui lòng nhập tên tổ chức
            </p>
          </div>
          <div class="py-2">
            <label for="information">Thông tin <span style="color: red">*</span></label>
            <textarea
              name="information"
              class="form-control"
              #information="ngModel"
              [(ngModel)]="organizationDetail.information"
              [value]="organizationDetail.information"
              cols="30"
              rows="10"
              required
              >{{ organizationDetail.information }}</textarea
            >
          </div>
          <div
            *ngIf="
              information.invalid && (information.dirty || information.touched)
            "
          >
            <p class="text-danger" *ngIf="information.errors?.['required']">
              Vui lòng nhập thông tin
            </p>
          </div>
          <div class="py-2">
            <label for="parentCode">Tổ chức</label>
            <select
              name="parentCode"
              [value]="organizationDetail.parentCode"
              [(ngModel)]="organizationDetail.parentCode"
              #parentCode="ngModel"
              class="form-select my-2"
            >
              <option [value]="0" disabled>Chọn tổ chức cha</option>
              <option
                *ngFor="let organization of organizations"
                [value]="organization.id"
              >
                {{ organization.name}}
              </option>
            </select>
          </div>
          <div class="d-flex justify-content-end gap-2">
            <button class="btn btn-outline-secondary" data-bs-dismiss="modal">
              Hủy
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              data-bs-dismiss="modal"
              [disabled]="formEdit.invalid || organizationDetail.parentCode === 0"
              (click)="onEdit(organizationDetail.id, formEdit.value)"
            >
              Lưu
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- Upload-modal -->
<div
  class="modal fade"
  id="import-modal"
  tabindex="-1"
  aria-labelledby="import-modal"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="import-modal">Import excel</h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <form #formAdd="ngForm" enctype="multipart/form-data">
          <div class="py-2">
            <label for="name">File excel</label>
            <input
              type="file"
              name="file"
              (change)="onChangeFile($event)"
              class="form-control my-2"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              #inputImport
            />
          </div>
          <div class="d-flex justify-content-end gap-2">
            <button class="btn btn-outline-secondary" data-bs-dismiss="modal">
              Hủy
            </button>
            <button
            [disabled]="inputImport.value === ''"
              type="submit"
              class="btn btn-primary"
              data-bs-dismiss="modal"
              (click)="onImportExcel(inputImport)"
            >
              Lưu
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
