import { HttpErrorResponse } from '@angular/common/http';
import {Component, HostListener, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from '../../constants';
import { news } from '../../models/news';
import { employee } from '../../models/employee';
import { NewsService } from '../../services/news.service';
import { EmployeeService } from '../../services/employee.service';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-news-content-page',
  templateUrl: './news-content-page.component.html',
  styleUrls: ['./news-content-page.component.css']
})
export class NewsContentPageComponent implements OnInit {
  news: news | undefined;
  employee: employee | undefined;
  constructor(
    private route: ActivatedRoute,
    public translate: TranslateService,
    private newsService: NewsService,
    private employeeService: EmployeeService
  ) { }

  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap;
    const slug = String(routeParams.get('slug'));
    console.log(slug);
    this.getNews(slug);
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const navBar = document.getElementById('navbar');
    const classList = navBar?.classList;
    if (document.documentElement.scrollTop > 100) {
      classList?.add('navbar-scrolled');
    } else {
      classList?.remove('navbar-scrolled');
    }
  }

  setLang(langCode: string) {
    this.translate.use(langCode);
    Constants.setLang(langCode);
  }

  getNews(slug: string): void {
    this.newsService.getNewsBySlug(slug).subscribe({
      next: (data: news) => {
        console.log(data);
        this.news = data;
        this.employeeService.getByUsername(data.createBy).subscribe({
          next: (empl: employee) => {
            this.employee = empl;
          },
          error: (err: HttpErrorResponse) => {
            console.log(err);
          }
        })
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      }
    })
  }
}
