import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountComponent } from './admin/account/account.component';
import { AdminComponent } from './admin/admin.component';
import { ConfigComponent } from './admin/config/config.component';
import { ContactComponent } from './admin/contact/contact.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { EmployeeComponent } from './admin/employee/employee.component';
import { LoginComponent } from './admin/login/login.component';
import { NewsTypeComponent } from './admin/news-type/news-type.component';
import { NewsComponent } from './admin/news/news.component';
import { OrganizationComponent } from './admin/organization/organization.component';
import { PartnerComponent } from './admin/partner/partner.component';
import { RoleComponent } from './admin/role/role.component';
import { ServiceComponent } from './admin/service/service.component';
import { HomeComponent } from './home/home.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { NewsPageComponent } from './news-page/news-page.component';
import { ContactPageComponent } from './contact-page/contact-page.component';
import { NewsContentPageComponent } from './news-page/news-content/news-content-page.component';
import {RoleGuard} from "./role.guard";
import {ROLE} from "./constants";
import { FAQPageComponent } from './faq-page/faq-page.component';
import {RecruitmentComponent} from "./admin/recruitment/recruitment.component";
import {LookUpCodeComponent} from "./admin/look-up-code/look-up-code.component";
import {JobListComponent} from "./job-list/job-list.component";
import {JobDetailComponent} from "./job-list/job-detail/job-detail.component";
import {LookUpValueComponent} from "./admin/look-up-value/look-up-value.component";
import {ProductComponent} from "./admin/product/product.component";
import {ProductPageComponent} from "./product-page/product-page.component";
import {
  ProductDetailPageComponent
} from "./product-page/product-detail-page/product-detail-page.component";

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
      { path: '', component: LandingPageComponent },
      { path: 'news', component: NewsPageComponent },
      { path: 'contact', component: ContactPageComponent },
      { path: 'product',
        children: [
          {path: '', component: ProductPageComponent},
          {path: 'product-detail/:id', component: ProductDetailPageComponent}
        ]
      },
      { path: 'job',
        children: [
          { path: '', component: JobListComponent },
          { path: 'job-detail/:id', component: JobDetailComponent}
        ]
      },
      { path: 'faq', component: FAQPageComponent },
      { path: 'news',
        children: [
          { path: '', component: NewsPageComponent },
          { path: 'content/:slug', component: NewsContentPageComponent }
        ]
      },
      { path: 'login', component: LoginComponent },
      {
        path: 'admin',
        component: AdminComponent,
        children: [
          //{ path: '', pathMatch: 'full', redirectTo: 'dashboard' },
          {
            path: 'dashboard',
            component: DashboardComponent,
            canActivate: [RoleGuard],
            data: { role: [ROLE.ADMIN, ROLE.STAFF, ROLE.USER] }
          },
          {
            path: 'config',
            component: ConfigComponent,
            canActivate: [RoleGuard],
            data: { role: [ROLE.ADMIN, ROLE.STAFF] }
          },
          {
            path: 'organization',
            component: OrganizationComponent,
            canActivate: [RoleGuard],
            data: { role: [ROLE.ADMIN] }
          },
          {
            path: 'account',
            component: AccountComponent,
            canActivate: [RoleGuard],
            data: { role: [ROLE.ADMIN] }
          },
          {
            path: 'role',
            component: RoleComponent,
            canActivate: [RoleGuard],
            data: { role: [ROLE.ADMIN] }
          },
          {
            path: 'employee',
            component: EmployeeComponent,
            canActivate: [RoleGuard],
            data: { role: [ROLE.ADMIN, ROLE.STAFF] }
          },
          {
            path: 'service',
            component: ServiceComponent,
            canActivate: [RoleGuard],
            data: { role: [ROLE.ADMIN, ROLE.STAFF] }
          },
          {
            path: 'news_type',
            component: NewsTypeComponent,
            canActivate: [RoleGuard],
            data: { role: [ROLE.ADMIN, ROLE.STAFF] }
          },
          {
            path: 'news',
            component: NewsComponent,
            canActivate: [RoleGuard],
            data: { role: [ROLE.ADMIN, ROLE.STAFF, ROLE.USER] }
          },
          {
            path: 'contact',
            component: ContactComponent,
            canActivate: [RoleGuard],
            data: { role: [ROLE.ADMIN, ROLE.STAFF, ROLE.USER] }
          },
          {
            path: 'partner',
            component: PartnerComponent,
            canActivate: [RoleGuard],
            data: { role: [ROLE.ADMIN, ROLE.STAFF, ROLE.USER] }
          },
          {
            path: 'lookUpCode',
            component: LookUpCodeComponent,
            canActivate: [RoleGuard],
            data: { role: [ROLE.ADMIN, ROLE.STAFF, ROLE.USER] }
          },
          {
            path: 'lookUpValue',
            component: LookUpValueComponent,
            canActivate: [RoleGuard],
            data: { role: [ROLE.ADMIN, ROLE.STAFF, ROLE.USER] }
          },
          {
            path: 'product',
            component: ProductComponent,
            canActivate: [RoleGuard],
            data: { role: [ROLE.ADMIN, ROLE.STAFF, ROLE.USER] }
          },
          {
            path: 'recruitment',
            component: RecruitmentComponent,
            canActivate: [RoleGuard],
            data: { role: [ROLE.ADMIN, ROLE.STAFF] }
          },
        ],
      },
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
