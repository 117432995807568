import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { organization } from '../models/organization';
import { response } from '../models/response';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  private apiUrl = environment.apiUrl;
  private token = this.authService.getToken();
  private httpOptions = {
    headers: {
      Authorization: `Bearer ${this.token}`
    },
  }

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  getOrganizations(status: number, page: number): Observable<response<organization[]>> {
    return this.http.get<response<organization[]>>(`${this.apiUrl}/organization/select?status=${status}&page=${page}&sort=create_desc`, this.httpOptions);
  }

  selectOrganizations(status: number, page: number,search: string, sort: string): Observable<response<organization[]>> {
    return this.http.get<response<organization[]>>(`${this.apiUrl}/organization/select?status=${status}&page=${page}&name=${search}&sort=${sort}`, this.httpOptions);
  }

  getOrganizationById(id: number | undefined): Observable<organization> {
    return this.http.get<organization>(`${this.apiUrl}/organization/selectOne?id=${id}`, this.httpOptions)
  }

  downloadTemplateExcel(): Observable<HttpResponse<Blob>> {
    return this.http.get(`${this.apiUrl}/organization/download-template`, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`
      }),
      observe: 'response',
      responseType: 'blob',
    });
  }

  createOrganization(data: organization, file: any) {
    const formData = new FormData();
    formData.append('organization', new Blob([
      JSON.stringify(data)
    ], {
      type: 'application/json'
    }));
    formData.append('file', file)
    return this.http.post(`${this.apiUrl}/organization/create`, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`
      },
      responseType: 'text'
    })
  }

  importOrganization( file: any) {
    const formData = new FormData();
    formData.append('file', file)
    return this.http.post(`${this.apiUrl}/organization/create`, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`
      },
      responseType: 'text'
    })
  }

  editOrganizationById(id: number | undefined, data: organization): Observable<organization> {
    data.status = 1;
    return this.http.post<organization>(`${this.apiUrl}/organization/update?id=${id}`, data, this.httpOptions);
  }

  deleteOrganizationById(id: number[] | undefined) {
    return this.http.post(`${this.apiUrl}/organization/delete`, id, {
      headers: {
        Authorization: `Bearer ${this.token}`
      },
      responseType: 'text'
    })
  }

  sortOrganization(sort: string): Observable<response<organization[]>> {
    return this.http.get<response<organization[]>>(`${this.apiUrl}/organization/select?sort=${sort}`,this.httpOptions)
  }

  searchOrganization(search: string): Observable<response<organization[]>> {
    return this.http.get<response<organization[]>>(`${this.apiUrl}/organization/select?name=${search}`,this.httpOptions)
  }

}
