import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from '../constants';
import { employee } from '../models/employee';
import { news } from '../models/news';
import { events } from '../models/news';
import { response } from '../models/response';
import { EmployeeService } from '../services/employee.service';
import { NewsService } from '../services/news.service';

@Component({
  selector: 'app-news-page',
  templateUrl: './news-page.component.html',
  styleUrls: ['./news-page.component.css']
})
export class NewsPageComponent implements OnInit {
  news: news[] = [];
  upcomingEvents: events[] = [];
  employees: employee[] = [];
  employeesNew: employee[] = [];
  keyword: any = '';
  totalPage = 0;
  totalItem = 0;
  itemPerPage = 0;
  constructor(
    public translate: TranslateService,
    private newsService: NewsService,
    private employeeService: EmployeeService
  ) { }

  ngOnInit(): void {
    this.getNews();
    this.getUpcomingEvents();
    this.getListEmployeeByTitle();
    this.getEmployeesNew();
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const navBar = document.getElementById('navbar');
    const classList = navBar?.classList;
    if (document.documentElement.scrollTop > 100) {
      classList?.add('navbar-scrolled');
    } else {
      classList?.remove('navbar-scrolled');
    }
  }

  setLang(langCode: string) {
    this.translate.use(langCode);
    Constants.setLang(langCode);
  }

  getNews(): void {
    this.newsService.getListNews(1).subscribe({
      next: (data: response<news[]>) => {
        console.log(data.content);
        this.news = data.content;
        this.totalItem = data.totalElements;
        this.totalPage = data.totalPages;
        this.itemPerPage = data.numberOfElements
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      }
    })
  }

  getUpcomingEvents(): void {
    this.newsService.getUpcomingEvents().subscribe({
      next: (data: events[]) => {
        console.log(data);
        this.upcomingEvents = data;
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      }
    })
  }

  getListEmployeeByTitle(): void {
    this.employeeService.getListEmployeeByTitle().subscribe({
      next: (data: employee[]) => {
        console.log(data);
        this.employees = data;
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      }
    })
  }

  getEmployeesNew(): void {
    this.employeeService.getListNewEmployee().subscribe({
      next: (data: response<employee[]>) => {
        console.log(data.content);
        this.employeesNew = data.content;
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      }
    })
  }

  onPageChange(event: any): void {
    const page = event.page + 1;
    this.newsService.getListNews(page).subscribe({
      next: (data: response<news[]>) => {
        console.log(data.content);
        this.news = data.content;
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      }
    })
  }

  onSearch(keyword: any): void {
    console.log(keyword.keyword);

    this.newsService.searchNews(keyword.keyword).subscribe({
      next: (data: response<news[]>) => {
        console.log(data.content);
        this.news = data.content;
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      }
    })
  }
}
