<div class="container-fluid p-4">
  <p-toast></p-toast>
  <p-confirmDialog
    [style]="{ width: '50vw' }"
    [baseZIndex]="10000"
  ></p-confirmDialog>
  <!-- Alert -->
  <div>
    <div class="fixed-bottom d-flex justify-content-center">
      <div
        [hidden]="selectedValue.length === 0"
        class="alert alert-danger w-50"
        role="alert"
      >
        <div class="d-flex justify-content-between align-items-center gap-2">
          <p style="margin-bottom: 0">
            Đã chọn {{ selectedValue.length }} phần tử
          </p>
          <div>
            <button
            class="btn btn-light px-4 cursor-pointer me-3"
            (click)="onCheckBox()"
          >
            Hủy
          </button>
          <button
            class="btn btn-danger px-4 cursor-pointer"
            (click)="onDelete(selectedValue)"
          >
            Xóa
          </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Header -->
  <div class="d-flex justify-content-between">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a class="text-dark text-decoration-none" routerLink="/admin"
            >Admin</a
          >
        </li>
        <li class="breadcrumb-item">
          <a class="text-dark text-decoration-none" routerLink="/organization"
            >Vai trò</a
          >
        </li>
      </ol>
    </nav>
    <div class="d-flex gap-2">
      <button
        class="p-button-success p-button-sm"
        pButton
        type="button"
        icon="pi pi-download"
        iconPos="left"
        (click)="onDownloadTemplate()"
        label="Download Template"
      >
      </button>
      <button
        class="btn btn-success d-flex gap-2"
        data-bs-toggle="modal"
        data-bs-target="#import-modal"
      >
        <span class="material-symbols-outlined"> drive_folder_upload </span>
        Import excel
      </button>

      <button
        class="d-flex gap-2 btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#add-modal"
      >
        <i class="material-symbols-outlined"> person_add </i>
        Thêm mới
      </button>
    </div>
  </div>
  <div class="table-wrapper my-4">
    <!-- Table -->
    <div class="table-responsive border rounded">
      <div class="table-header bg-light rounded-top">
        <div class="d-flex justify-content-between align-items-center p-2">
          <div class="d-flex gap-2">
            <select
              (change)="onFilter($event)"
              class="form-select"
              style="max-width: 200px"
            >
              <option [value]="1">Lọc theo</option>
              <option [value]="0">Tất cả</option>
              <option [value]="1">Hoạt động</option>
              <option [value]="-1">Không hoạt động</option>
            </select>
            <select
              class="form-select"
              style="max-width: 200px"
              (change)="onSort($event)"
            >
              <option value="">Sắp xếp theo</option>
              <option value="id_asc">id tăng dần</option>
              <option value="id_desc">id giảm dần</option>
              <option value="name_asc">Từ a - z</option>
              <option value="name_desc">Từ z - a</option>
              <option value="create_desc">Vai trò mới thêm</option>
              <option value="update_desc">Vai trò mới sửa</option>
            </select>
          </div>
          <input
            type="text"
            name="searchValue"
            class="form-control"
            placeholder="Tìm kiếm..."
            style="max-width: 260px"
            #searchInput
            (keyup)="onSearch(searchInput.value)"
          />
        </div>
      </div>
      <table class="table table-responsive table-body">
        <thead>
          <tr>
            <th class="text-secondary">
              <input type="checkbox" [checked]="checkAll" name="id" class="form-check-input" (change)="onCheckAll($event)"/>
            </th>
            <th class="text-secondary">STT</th>
            <th class="text-secondary">Tên vai trò</th>
            <th class="text-secondary">Trạng thái</th>
            <th class="text-secondary">Ngày tạo</th>
            <th class="text-secondary">Ngày sửa</th>
            <th class="text-secondary">Người tạo</th>
            <th class="text-secondary">Người sửa</th>
            <th class="text-secondary">Thao tác</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let role of roles; index as index">
            <td>
              <input
                type="checkbox"
                [value]="role.id"
                class="form-check-input"
                [checked]="checked"
                [checked]="checkedCheckBox[index]"
                (change)="onCheckboxValue($event, index)"
              />
            </td>
            <td>{{ (pageNumber * 10) + index + 1 }}</td>
            <td>{{ role.name }}</td>
            <td>
              <div
                class="d-inline-block bg-success bg-opacity-25 text-success p-2 rounded"
                [ngClass]="{
                  'text-success bg-success': role.status === 1,
                  'text-danger bg-danger ': role.status === -1,
                  'text-warning bg-warning': role.status === null
                }"
              >
                {{ role.status === 1 ? "Hoạt động" : "Không hoạt động" }}
              </div>
            </td>
            <td>{{ role.createTime | date: "dd/MM/yyyy" }}</td>
            <td>{{ role.updateTime | date: "dd/MM/yyyy" }}</td>
            <td>{{ role.createBy }}</td>
            <td>{{ role.updateBy }}</td>
            <td>
              <i
                [hidden]="role.status === -1"
                class="material-symbols-outlined"
                data-bs-toggle="modal"
                data-bs-target="#edit-modal"
                (click)="onGetDetail(role.id)"
              >
                edit
              </i>
              <i
                [hidden]="role.status === -1"
                class="material-symbols-outlined mx-2"
                (click)="onDelete(role.id)"
              >
                delete
              </i>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="table-footer d-flex align-items-center">
        <a [hidden]="totalItem === 0" class="ms-3" style="text-decoration: none">Hiển thị từ {{(pageNumber * 10) + 1}} - {{numberOfElements + (pageNumber * 10)}}/{{totalItem}} vai trò</a>
        <a [hidden]="totalItem > 0" class="ms-3" style="text-decoration: none">Không có dữ liệu</a>
        <div style="width: 70%; float: right;">
          <p-paginator
          [rows]="itemPerPage"
          [pageLinkSize]="totalPage"
          [totalRecords]="totalItem"
          (onPageChange)="onPageChange($event)"
        ></p-paginator>
        </div>
      </div>
    </div>
  </div>

  <!-- Add-modal -->
  <div
    class="modal fade"
    id="add-modal"
    tabindex="-1"
    aria-labelledby="add-modal"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="add-modal">Thêm mới vai trò</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form #formAdd="ngForm">
            <div class="py-2">
              <label for="name">Tên vai trò <span style="color: red">*</span></label>
              <input
                type="text"
                name="name"
                #name="ngModel"
                [(ngModel)]="role.name"
                class="form-control my-2"
                placeholder="nhập tên vai trò"
                required
              />
            </div>
            <div *ngIf="name.invalid && (name.dirty || name.touched)">
              <p class="text-danger" *ngIf="name.errors?.['required']">
                Vui lòng nhập tên vai trò
              </p>
            </div>
            <div class="d-flex justify-content-end gap-2">
              <button class="btn btn-outline-secondary" (click)="resetForm()" data-bs-dismiss="modal" aria-label="Close">
                Hủy
              </button>
              <button
                [disabled]="formAdd.invalid"
                type="submit"
                class="btn btn-primary"
                data-bs-dismiss="modal"
                (click)="onSubmit(formAdd.value)"
              >
                Lưu
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- Edit-modal -->
  <div
    class="modal fade"
    id="edit-modal"
    tabindex="-1"
    aria-labelledby="edit-modal"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="edit-modal">Sửa vai trò</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form #formEdit="ngForm">
            <div class="py-2">
              <label for="name">Tên vai trò</label>
              <input
                type="text"
                name="name"
                #name="ngModel"
                [(ngModel)]="roleDetail.name"
                [value]="roleDetail.name"
                class="form-control my-2"
                placeholder="nhập tên nhân viên"
              />
            </div>
            <div *ngIf="name.invalid && (name.dirty || name.touched) || roleDetail.name.length === 0">
              <p class="text-danger" *ngIf="name.errors?.['required']">
                Vui lòng nhập tên vai trò
              </p>
            </div>
            <div class="d-flex justify-content-end gap-2">
              <button class="btn btn-outline-secondary" data-bs-dismiss="modal">
                Hủy
              </button>
              <button
                type="submit"
                class="btn btn-primary"
                data-bs-dismiss="modal"
                [disabled]="formEdit.invalid || roleDetail.name.length === 0"
                (click)="onEdit(roleDetail.id, formEdit.value)"
              >
                Lưu
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- Import-modal -->
  <div
    class="modal fade"
    id="import-modal"
    tabindex="-1"
    aria-labelledby="import-modal"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="import-modal">Import excel</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form #formImport="ngForm">
            <div class="py-2">
              <label for="name">File excel</label>
              <input
                type="file"
                name="file"
                (change)="onChangeFile($event)"
                class="form-control my-2"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                #inputImport
              />
            </div>
            <div class="d-flex justify-content-end gap-2">
              <button class="btn btn-outline-secondary" data-bs-dismiss="modal">
                Hủy
              </button>
              <button
                [disabled]="inputImport.value === ''"
                type="submit"
                class="btn btn-primary"
                data-bs-dismiss="modal"
                (click)="onUploadFile(formImport.value, inputImport)"
              >
                Import
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
