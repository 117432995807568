<div class="container-fluid p-4">
  <p-toast></p-toast>
  <p-confirmDialog [style]="{ width: '50vw' }" [baseZIndex]="10000"></p-confirmDialog>

  <!-- Header -->
  <div class="d-flex justify-content-between">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a class="text-dark text-decoration-none" routerLink="/admin">Admin</a>
        </li>
        <li class="breadcrumb-item">
          <a class="text-dark text-decoration-none" routerLink="/organization">Loại tin tức</a>
        </li>
      </ol>
    </nav>
    <div class="d-flex gap-2">
      <button
            class="btn px-4 cursor-pointer me-1"
            style="background-color: lightblue; color: white;"
            *ngIf="selectedValue.length > 0"
            (click)="onCheckBox()"
          >
            Hủy chọn
          </button>
      <button *ngIf="selectedValue.length > 0" class="d-flex gap-2 btn btn-danger cursor-pointer"
        (click)="onDelete(selectedValue)">
        <i class="material-symbols-outlined"> delete </i>
        Xóa
      </button>

      <button class="d-flex gap-2 btn btn-primary" data-bs-toggle="modal" data-bs-target="#add-modal">
        <i class="material-symbols-outlined"> person_add </i>
        Thêm mới
      </button>
    </div>
  </div>
  <div class="table-wrapper my-4">
    <!-- Table -->
    <div class="table-responsive border border-bottom-0 rounded-top">
      <div class="table-header bg-light rounded-top">
        <div class="d-flex justify-content-between align-items-center p-2">
          <div class="d-flex gap-2">
            <!-- Filter -->
            <select
              name="filterValue"
              class="form-select"
              style="max-width: 200px"
              (change)="onFilter($event)"
            >
              <option [value]="1">Lọc theo</option>
              <option [value]="0">Tất cả</option>
              <option [value]="1">Hoạt động</option>
              <option [value]="-1">Không hoạt động</option>
            </select>
            <!-- Sort -->
            <select class="form-select" style="max-width: 200px" (change)="onSort($event)">
              <option value="">Sắp xếp theo</option>
              <option value="id_asc">Id tăng dần</option>
              <option value="id_desc">Id giảm dần</option>
              <option value="name_asc">Từ a - z</option>
              <option value="name_desc">Từ z - a</option>
              <option value="create_desc">Loại tin tức mới thêm</option>
              <option value="update_desc">Loại tin tức mới sửa</option>
            </select>
          </div>

          <input type="text" class="form-control" #searchTerm placeholder="Tìm kiếm..." style="max-width: 260px"
            (keyup)="onSearch(searchTerm.value)" />
        </div>
      </div>
      <table class="table table-responsive table-body">
        <thead>
          <tr>
            <th><input type="checkbox" [checked]="checkAll" class="form-check-input" (change)="onCheckAll($event)" /></th>
            <th class="text-secondary">STT</th>
            <th class="text-secondary">Loại tin tức</th>
            <th class="text-secondary">Trạng thái</th>
            <th class="text-secondary">Ngày tạo</th>
            <th class="text-secondary">Ngày sửa</th>
            <th class="text-secondary">Người tạo</th>
            <th class="text-secondary">Người sửa</th>
            <th class="text-secondary">Thao tác</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let news_type of news_types; index as index">
            <td>
              <input type="checkbox" [checked]="checked" class="form-check-input" [value]="news_type.id"
              [checked]="checkedCheckBox[index]"
                (change)="onCheckboxValue($event, index)" />
            </td>
            <td>{{ (pageNumber * 10) + index + 1 }}</td>
            <td>{{ news_type.name }}</td>
            <td>
              <div class="d-inline-block bg-opacity-25 p-2 rounded" [ngClass]="{
                  'text-success bg-success': news_type.status === 1,
                  'text-danger bg-danger ': news_type.status === -1,
                  'text-warning bg-warning': news_type.status === null
                }">
                {{ news_type.status === 1 ? "Hoạt động" : "Không hoạt động" }}
              </div>
            </td>
            <td>{{ news_type.createTime | date: "dd/MM/yyyy" }}</td>
            <td>{{ news_type.updateTime | date: "dd/MM/yyyy" }}</td>
            <td>{{ news_type.createBy }}</td>
            <td>{{ news_type.updateBy }}</td>
            <td>
              <i class="material-symbols-outlined" data-bs-toggle="modal" data-bs-target="#edit-modal"
              [hidden]="news_type.status === -1" (click)="onGetById(news_type.id)">
                edit
              </i>
              <i class="material-symbols-outlined mx-2" [hidden]="news_type.status === -1" (click)="onDelete(news_type.id)">
                delete
              </i>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="table-footer d-flex align-items-center border-bottom">
        <a [hidden]="totalItem === 0" class="ms-3" style="text-decoration: none">Hiển thị từ {{(pageNumber * 10) + 1}} - {{(pageNumber * 10)+numberOfElements}}/{{totalItem}} loại dịch vụ</a>
        <a [hidden]="totalItem > 0" class="ms-3" style="text-decoration: none">Không có dữ liệu</a>
        <div style="width: 70%; float: right;">
          <p-paginator
          [rows]="itemPerPage"
          [pageLinkSize]="totalPage"
          [totalRecords]="totalItem"
          (onPageChange)="onPageChange($event)"
        ></p-paginator>
        </div>
    </div>
  </div>
  </div>


  <!-- Add-modal -->
  <div class="modal fade" id="add-modal" tabindex="-1" aria-labelledby="add-modal" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="add-modal">Thêm mới loại tin tức</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form #formAdd="ngForm" enctype="multipart/form-data" (submit)="onSubmit(formAdd.value)">
            <div>
              <label for="username">Loại tin tức <span style="color: red">*</span></label>
              <input type="text" name="name" [(ngModel)]="news_type.name" #name="ngModel" class="form-control my-1"
                placeholder="nhập loại tin tức" required />
            </div>
            <div *ngIf="name.invalid && (name.dirty || name.touched)">
              <p class="text-danger">Vui lòng nhập loại tin tức</p>
            </div>
            <div class="d-flex mt-3 justify-content-end gap-2">
              <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                Hủy
              </button>
              <button type="submit" class="btn btn-primary" data-bs-dismiss="modal" [disabled]="formAdd.invalid">
                Thêm Mới
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- Edit-modal -->
  <div class="modal fade" id="edit-modal" tabindex="-1" aria-labelledby="edit-modal" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="edit-modal">Sửa loại tin tức</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form #formEdit="ngForm" enctype="multipart/form-data" (submit)="onEdit(news_typeDetail.id, formEdit.value)">
            <div>
              <label for="username">Tên loại tin tức <span style="color: red">*</span></label>
              <input type="text" name="name" [(ngModel)]="news_typeDetail.name" #name="ngModel"
                class="form-control my-1" placeholder="nhập tên loại tin tức" required />
            </div>
            <div *ngIf="name.invalid && (name.dirty || name.touched)">
              <p class="text-danger">Vui lòng nhập loại tin tức</p>
            </div>
            <div class="d-flex justify-content-end gap-2">
              <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                Hủy
              </button>
              <button type="submit" class="btn btn-primary" data-bs-dismiss="modal" [disabled]="formEdit.invalid">
                Lưu
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

</div>