import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { user } from '../models/user';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';
import {Router} from "@angular/router";
import {UrlUtils} from "../url.util";
import {MenuService} from "../menu.service";
import {Menu} from "../menu.util";
import MENUS from '../menus.json';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css'],
})
export class AdminComponent implements OnInit {
  sidebarExpanse = true;
  menus: Menu[] = MENUS;
  username = '';
  users: user[] = [];
  constructor(
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private menuService: MenuService
  ) { }

  ngOnInit(): void {
    if (this.authService.isAuthenticated()) {
      this.menuService.menu$.subscribe((data) => {
        console.log('subscribe', data);

        this.menus = data;
      });
      this.username = this.authService.getUsername();
      this.router.navigate([UrlUtils.getUrl('/dashboard')]);
    }
    else {
      this.router.navigate(['/login']);
    }
  }

  getAll(): void {
    this.userService.getAll(1, 1).subscribe({
      next: (data: any) => {
        this.users = data.content;
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      }
    })
  }

  getName() {
    return 'Administrator';
  }

  getUnit() {
    return 'Marketing Administrator';
  }

  logout() {
    this.authService.logout()
  }
}
