import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import { Component, OnInit,ViewChild , ElementRef} from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { response } from 'src/app/models/response';
import { role } from 'src/app/models/role';
import { user } from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';
import { RoleService } from 'src/app/services/role.service';
import {ToastService} from "../../toast.service";
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {
  @ViewChild('addForm') addForm: NgForm;
  @ViewChild('formEdit') formEdit: NgForm;

  users: user[] = [];
  user: user = {
    username: '',
    password: '',
    repassword: '',
  }

  roles: role[] = [];
  role: role = {
    id: 0,
    name: ''
  }
  userDetail: user = {
    username: '',
    password: '',
    repassword: '',
  }
  loading = false;
  onSelectdId: any = [];
  fileToUpload: any = null;
  numberOfElements = 0;
  totalPage = 10;
  itemPerPage = 10;
  totalItem = 10;
  pageNumber = 0;
  statusAccount : number = 1;
  username = '';
  sort = 'create_desc';
  checked = false;
  searchTerm = new Subject<string>();
  selectedValue: any = [];
  indexCheckBox:any[] = [];
  checkedCheckBox:any[] = [];
  hidePass = true;
  checkAll = false;
  hideRepassword = true;
  hidePassNew = true;
  hideRepasswordNew = true;
  constructor(
    private roleService : RoleService,
    private userService: UserService,
    private confirmationService: ConfirmationService,
    private toast: ToastService
  ) {
    this.addForm = new NgForm([], []);
    this.formEdit = new NgForm([], []);
   }

  ngOnInit(): void {
    this.getAll()
    this.getAllRole()
    this.searchTerm.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
    ).subscribe((keyword: string) => {
      if (keyword.length === 0) {
        this.getAll();
      }
      this.userService.searchUser(keyword).subscribe({
        next: (data: any) => {
          this.users = data.content;
          this.pageNumber = data.pageable.pageNumber;
        this.totalPage = data.totalPages;
        this.totalItem = data.totalElements;
        this.itemPerPage = data.numberOfElements;
        this.numberOfElements = data.numberOfElements;
        },
        error: (err: HttpErrorResponse) => {
          console.log(err);
        }
      })
    })
  }

  showPassword() {
    this.hidePass = !this.hidePass;
  }
  showRePassword() {
    this.hideRepassword = !this.hideRepassword;
  }

  showPasswordNew() {
    this.hidePassNew = !this.hidePassNew;
  }
  showRePasswordNew() {
    this.hideRepasswordNew = !this.hideRepasswordNew;
  }

  getAll() {
    this.loading = true;
    this.userService.getAll(1, 1).subscribe({
      next: (data: response<user[]>) => {
        console.log(data);
        this.users = data.content;
        this.totalItem = data.totalElements;
        this.itemPerPage = data.numberOfElements;
        this.totalPage = data.totalPages;
        this.pageNumber = data.pageable.pageNumber;
        this.numberOfElements = data.numberOfElements;
        this.loading = false;
      },
      error: (err) => {
        console.log(err);
      }
    })
  }

  getAllRole() {
    this.roleService.listAllRole().subscribe({
      next: (data: any) => {
        this.roles = data;
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
        this.toast.error('Load dữ liệu', 'Có lỗi xảy ra khi load dữ liệu');
      }
    })
  }

  onFilter(e: Event) {
    this.statusAccount = +(e.target as HTMLInputElement).value;
    this.userService.getAll(this.statusAccount, 1).subscribe({
      next: (data: response<user[]>) => {
        this.users = data.content;
        this.pageNumber = data.pageable.pageNumber;
        this.totalPage = data.totalPages;
        this.totalItem = data.totalElements;
        this.itemPerPage = data.numberOfElements;
        this.numberOfElements = data.numberOfElements;
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      }
    })
  }

  onCheckboxValue(e: any, index:number) {
    const id = +(e.target as HTMLInputElement).value;
    console.log(id);
    if (e.target.checked) {
      this.selectedValue.push(id)
      this.checkedCheckBox[index] = true;
      this.indexCheckBox.push(index);
      if (this.selectedValue.length === this.itemPerPage) {
        this.checkAll = true;
      }else{
        this.checkAll = false;
      }
    } else {
      this.selectedValue = this.selectedValue.filter((item: number) => item !== id);
      this.indexCheckBox = this.indexCheckBox.filter((item: number) => item !== index);
      if (this.selectedValue.length === this.itemPerPage) {
        this.checkAll = true;
      }else{
        this.checkAll = false;
      }
    }
  }

  onSearchChange(searchTerm: string) {
    this.username = searchTerm;
    this.searchTerm.next(searchTerm);
  }

  onPageChange(e: any) {
    const page = e.page + 1;
    this.userService.selectAll(this.statusAccount, page, this.username, this.sort).subscribe({
      next: (data: response<user[]>) => {
        this.users = data.content;
        this.pageNumber = data.pageable.pageNumber;
        this.numberOfElements = data.numberOfElements;
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      }
    })
  }

  onSort(e : Event){
    this.sort = (e.target as HTMLInputElement).value;
    this.userService.sortUser(this.sort).subscribe({
      next: (data: response<user[]>) => {
        this.users = data.content;
        this.pageNumber = data.pageable.pageNumber;
        this.totalPage = data.totalPages;
        this.totalItem = data.totalElements;
        this.itemPerPage = data.numberOfElements;
        this.numberOfElements = data.numberOfElements;
      },
      error: (err: HttpErrorResponse) => {
        this.toast.error('Sắp xếp tài khoản', 'Có lỗi xảy ra khi sắp xếp');
      }
    })
  }

  onSubmit(data: user) {
    this.userService.createAccount(data, Number(this.role.id)).subscribe({
      next: () => {
        this.sortUpdate('create_desc');
        this.addForm.resetForm();
        this.role.id= 0;
        this.toast.success('Thêm tài khoản', 'Thêm thành công');
      },
      error: (error) => {
        console.log(error);
        this.toast.error('Thêm tài khoản', 'Có lỗi xảy ra khi thêm');
      }
    })
  }

  onSelectedFile(e: any) {
    this.fileToUpload = e.target.files[0];
    console.log(this.fileToUpload);
  }

  onDownloadTemplate() {
    this.userService.downloadTemplateExcel().subscribe(response => {
      if (response.body) {
        const filename = 'template-user.xlsx';
        const blob = new Blob([response.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } else {
        console.error('Lỗi tải về Template');
      }
    });
  }

  onUploadFile(input: HTMLInputElement) {
    if(input.value === ''){
      this.toast.error('Import excel', 'Có lỗi xảy ra khi import');
    }else{
      this.userService.uploadFile(this.fileToUpload).subscribe({
        next: () => {
          this.getAll();
          input.value = '';
          this.toast.success('Import excel', 'Import thành công');
        },
        error: (err: HttpErrorResponse) => {
          this.toast.error('Import excel', 'Có lỗi xảy ra khi import');
        }
      })
    }
  }

  onCancel(){
    this.role.id = 0
  }

  getUserById(id: number | undefined) {
    this.userService.getById(id).subscribe((data: any) => {
      this.userDetail = data;
    })
    this.userService.getRoleIdByUserId(id).subscribe((data: number)=>{
      this.role.id= data;
    })
  }

  sortUpdate(text:string){
    this.userService.sortUser(text).subscribe({
      next: (data: response<user[]>) => {
        this.users = data.content;
        this.pageNumber = data.pageable.pageNumber;
        this.totalPage = data.totalPages;
        this.totalItem = data.totalElements;
        this.itemPerPage = data.numberOfElements;
        this.numberOfElements = data.numberOfElements;
      }
    })
  }

  onEdit(id: number | undefined, data: user,idRole: number) {
    this.userService.updateById(id, String(data.password), idRole).subscribe({
      next: () => {
        this.sortUpdate('update_desc');
        this.formEdit.reset();
        this.role.id= 0;
        this.toast.success('Sửa tài khoản', 'Sửa thành công');
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
        console.log(this.fileToUpload);
        this.toast.error('Sửa tài khoản', 'Có lỗi xảy ra khi sửa');
      }
    })
  }

  onDelete(id: number | undefined) {
    this.onSelectdId.push(id)
    this.confirmationService.confirm({
      header: 'Xóa tài khoản',
      message: 'Bạn có muốn xóa không ?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Đồng ý',
      rejectLabel: 'Hủy',
      accept: () => {
        if (this.selectedValue.length === 0) {
          this.selectedValue.push(id);
          this.userService.deleteById(this.selectedValue).subscribe({
            next: (data) => {
              this.getAll();
              this.selectedValue = []
              this.checked = false;
              this.checkAll = false;
              this.indexCheckBox.forEach(i => {
                this.checkedCheckBox[i] = false
              })
              this.indexCheckBox = [];
              this.toast.success('Xóa tài khoản', 'Xóa thành công');
            },
            error: (err: HttpErrorResponse) => {
              console.log(err);
              this.toast.error('Xóa tài khoản', 'Có lỗi xảy ra khi xóa');
            }
          })
        }
        else {
          this.userService.deleteById(this.selectedValue).subscribe({
            next: (data) => {
              this.getAll();
              this.selectedValue = []
              this.checked = false;
              this.checkAll = false;
              this.indexCheckBox.forEach(i => {
                this.checkedCheckBox[i] = false
              })
              this.indexCheckBox = [];
              this.toast.success('Xóa tài khoản', 'Xóa thành công');
            },
            error: (error) => {
              console.log(error);
              this.toast.error('Xóa tài khoản', 'Có lỗi xảy ra khi xóa');
            }
          })
        }
      }
    })
  }

  onCheckAll(e: any): void {
    if (e.target.checked) {
      this.selectedValue = [];
      this.users.forEach(n => {
        return this.selectedValue.push(n.id);
      });
      this.checked = true;
      this.checkAll = true;
    } else {
      this.selectedValue = [];
      this.checked = false;
      this.checkAll = false;
      for (let index = 0; index <= 10; index++) {
        this.checkedCheckBox[index] = false;
      }
    }
    console.log(this.selectedValue);
  }

  onCheckBox(){
    this.checked = false;
    this.checkAll = false;
    this.selectedValue = []
    this.indexCheckBox.forEach(i => {
      this.checkedCheckBox[i] = false
    })
    this.indexCheckBox = [];
  }

  resetForm(){
    this.addForm.reset();
  }
}
