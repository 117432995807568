import { Component, OnInit } from '@angular/core';
import {ProductService} from "../services/product.service";
import {product, product_search} from "../models/product";
import {response} from "../models/response";
import {FormBuilder, FormGroup} from "@angular/forms";
import {lookUpValue} from "../models/job";

@Component({
  selector: 'app-product-page',
  templateUrl: './product-page.component.html',
  styleUrls: ['./product-page.component.css']
})
export class ProductPageComponent implements OnInit {

  products: product[] = [];
  productsTop3: product[] = [];
  searchForm!: FormGroup;
  totalPage = 0;
  totalItem = 0;
  itemPerPage = 6;
  pageNumber = 0;
  numberOfElements = 0;

  constructor(
    private productService: ProductService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.initSearchForm();
    this.onSubmitSearch();
    this.searchProductsTop3();
  }

  private initSearchForm(): void {
    this.searchForm = this.fb.group({
      name: [''],
    });
  }
  private searchProductsTop3(): void {
    const searchParams: product_search = {
      name: this.searchForm.value.name,
      pageNumber: 0,
      pageSize: 3,
    };
    this.productService.searchProduct(searchParams).subscribe({
      next: (response: response<product[]>) => {
        this.productsTop3 = response.content;
        this.updatePaginationInfo(response);
      },
      error: (error) => this.handleError('Search failed', error)
    });
  }

  onSubmitSearch(): void {
    if (this.searchForm.valid) {
      const searchParams: product_search = {
        name: this.searchForm.value.name,
        pageNumber: 0,
        pageSize: this.itemPerPage,
      };
      this.searchProducts(searchParams);
    } else {
      console.warn('Form is invalid');
    }
  }

  performSearch(page: number = 0): void {
    const searchParams: product_search = {
      pageNumber: page,
      pageSize: this.itemPerPage,
    };
    this.searchProducts(searchParams);
  }

  onPageChange(event: any): void {
    this.pageNumber = event.page;
    this.itemPerPage = event.rows;
    this.performSearch(this.pageNumber);
  }

  private searchProducts(params: product_search): void {
    this.productService.searchProduct(params).subscribe({
      next: (response: response<product[]>) => {
        this.products = response.content;
        this.updatePaginationInfo(response);
      },
      error: (error) => this.handleError('Search failed', error)
    });
  }

  private updatePaginationInfo(response: any): void {
    this.pageNumber = response.pageable.pageNumber;
    this.totalPage = response.totalPages;
    this.totalItem = response.totalElements;
    this.numberOfElements = response.numberOfElements;
  }

  private handleError(message: string, error: any): void {
    console.error(`${message}:`, error);
    // You could add more sophisticated error handling here, like showing a user-friendly message
  }


}
