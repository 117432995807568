import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { response } from '../models/response';
import {product, product_search} from '../models/product';
import { AuthService } from './auth.service';
import {look_up_code} from "../models/look_up_code";

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  private apiUrl = environment.apiUrl;
  private token = this.authService.getToken();
  private httpOptions = {
    headers: {
      Authorization: `Bearer ${this.token}`
    },
  }
  constructor(
    private authService: AuthService,
    private http: HttpClient
  ) { }

  searchProduct(data: product_search): Observable<response<product[]>> {
    return this.http.post<response<product[]>>(`${this.apiUrl}/product/search`, data, this.httpOptions)
  }

  createProduct(data: product, backGroundImage: File, avatarImage: File) {
    const formData: FormData = new FormData();
    formData.append('backGroundImage', backGroundImage);
    formData.append('avatarImage', avatarImage);
    formData.append('productDto', new Blob([
      JSON.stringify(data)
    ], {
      type: 'application/json'
    }));
    return this.http.post(`${this.apiUrl}/product/create`, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`
      },
      responseType: 'text'
    });
  }

  uploadFile(file: File): Observable<product> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post<product>(`${this.apiUrl}/product/create`, formData, this.httpOptions);
  }

  updateProductById(id: number | undefined, data: product, backGroundImage: File, avatarImage: File) {
    const formData: FormData = new FormData();
    formData.append('backGroundImage', backGroundImage)
    formData.append('avatarImage', avatarImage)
    formData.append('productDto', new Blob([
      JSON.stringify(data)
    ], {
      type: 'application/json'
    }));
    return this.http.post(`${this.apiUrl}/product/update/${id}`, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`
      },
      responseType: 'text'
    });
  }

  deleteProductById(id: number[] | undefined) {
    return this.http.post(`${this.apiUrl}/product/delete`, id, {
      headers: {
        Authorization: `Bearer ${this.token}`
      },
      responseType: 'text'
    });
  }

  getProductById(id: number | undefined): Observable<product> {
    return this.http.get<product>(`${this.apiUrl}/product/detail/${id}`, this.httpOptions)
  }

  countProduct(): Observable<number> {
    return this.http.get<number>(`${this.apiUrl}/product/countService`, this.httpOptions);
  }

}
