<div>
  <app-header page="landing"></app-header>

  <div>
    <div id="section-header">
      <div class="mt-xl-4 py-xl-4 mt-lg-3 py-lg-3 mt-md-2 py-md-2 mt-sm-1 py-sm-1 container-fluid">
        <div class="row">
          <div class="col col-12 col-lg-6 d-flex flex-column justify-content-between">
            <div>
              <div>
              <span class="text-title fw-bolder text-light">{{
                "title.main_heading" | translate
                }}</span>
              </div>
              <div>
              <span class="text-content text-light">{{
                "content.main_heading" | translate
                }}</span>
              </div>
              <div class="mt-4 me-5 d-flex justify-content-start">
                <button class="btn btn-light">
                  <a class="navbar__button text-content" routerLink="/contact" routerLinkActive="">
                    {{ "btn.talk" | translate }}
                  </a>
                </button>
              </div>
            </div>

            <div class="bg-white mt-2 p-3" style="border-radius: 10px;">
              <div>
              <span class="text-title" style="color: #01579b;">
                {{ "title.section_header" | translate }}
              </span>
              </div>
              <div class="mt-lg-4 mt-md-2 mt-sm-1 mt-xs-1">
                <p class="text-content text-primary">
                  {{ "content.section_header.header_1" | translate }}
                </p>
                <p class="text-content text-primary">
                  {{ "content.section_header.header_2" | translate }}
                </p>
              </div>
            </div>
          </div>

          <div class="col col-10 col-lg-6 pb-5 mt-2 mx-auto">
            <img class="image-responsive" src="/assets/lp1.png" alt=""/>
          </div>
        </div>
      </div>

    </div>


    <div class="my-5 product-content">
      <section id="section-1" class="pt-5" style="background-image: none;">
        <div class="container-fluid">
          <div class="row slideshow">
            <ng-container *ngFor="let chunk of productChunks; let i = index">
              <div class="slide" [ngClass]="{ 'active': currentIndex === i }">
                <div class="navigation-arrow left" (click)="preSlide()">
                  <span class="material-symbols-outlined icon">arrow_back_ios</span>
                </div>
                <div class="product-container">
                  <div *ngFor="let product of chunk" class="product-item">
                    <div class="ss-card d-flex flex-column">
                      <div class="product-image">
                        <img class="img-fluid" [src]="product.backGroundImage" alt="Ảnh sản phẩm">
                      </div>
                      <div class="product-info">
                        <div class="product-header">
                          <img class="avatarImage" [src]="product.avatarImage" alt="Logo sản phẩm"/>
                          <a class="product-name ps-3" [routerLink]="['product/product-detail', product.id]">
                            {{ product.name }}
                          </a>
                        </div>
                        <p class="product-description">
                          {{ product.detailShort }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="navigation-arrow right" (click)="nextSlide()">
                  <span class="material-symbols-outlined icon">arrow_forward_ios</span>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </section>
      <div class="wave-container">
        <div class="ocean">
          <div class="wave"></div>
          <div class="wave"></div>
          <div class="wave"></div>
        </div>
      </div>
    </div>


    <!--  <div class="card">-->
    <!--    <p-carousel [value]="mainFunc" [numVisible]="2" [numScroll]="1" [responsiveOptions]="responsiveOptions">-->
    <!--      <ng-template let-product pTemplate="item">-->
    <!--        &lt;!&ndash; <div class="product-item">-->
    <!--          <div class="product-item-content">-->
    <!--            <div class="mb-3">-->
    <!--              <img src="{{ product.icon }}"-->
    <!--                [alt]="product.name" class="product-image" />-->
    <!--            </div>-->
    <!--            <div style="background-color: gray;">-->
    <!--              <h4 class="mb-1">{{ product.name }}</h4>-->
    <!--              <p-tag [value]="product.inventoryStatus"></p-tag>-->
    <!--              <div class="car-buttons mt-5">-->
    <!--                <p-button type="button" styleClass="p-button p-button-rounded mr-2" icon="pi pi-search"></p-button>-->
    <!--                <p-button type="button" styleClass="p-button-success p-button-rounded mr-2"-->
    <!--                  icon="pi pi-star-fill"></p-button>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div> &ndash;&gt;-->

    <!--&lt;!&ndash;        <div class="card shadow">&ndash;&gt;-->
    <!--&lt;!&ndash;          <div class="card-body">&ndash;&gt;-->
    <!--&lt;!&ndash;            <img class="d-block ms-auto me-auto" src="{{ product.icon }}" alt=""/>&ndash;&gt;-->
    <!--&lt;!&ndash;            <div class="mt-2 mb-4">&ndash;&gt;-->
    <!--&lt;!&ndash;              <p class="text-content text-primary fw-bold text-center">&ndash;&gt;-->
    <!--&lt;!&ndash;                {{ product.title | translate }}&ndash;&gt;-->
    <!--&lt;!&ndash;              </p>&ndash;&gt;-->
    <!--&lt;!&ndash;            </div>&ndash;&gt;-->
    <!--&lt;!&ndash;            <p class="text-content text-primary content">&ndash;&gt;-->
    <!--&lt;!&ndash;              {{ product.description | translate }}&ndash;&gt;-->
    <!--&lt;!&ndash;            </p>&ndash;&gt;-->
    <!--&lt;!&ndash;          </div>&ndash;&gt;-->
    <!--&lt;!&ndash;        </div>&ndash;&gt;-->
    <!--        <div style="position: relative; top: -1.2rem" class="d-flex justify-content-center">-->
    <!--          <button class="btn btn-primary text-content" data-bs-toggle="modal"-->
    <!--                  data-bs-target="#more-scope-financial-modal">-->
    <!--            {{ "btn.click_more" | translate }}-->
    <!--          </button>-->
    <!--        </div>-->
    <!--      </ng-template>-->
    <!--    </p-carousel>-->
    <!--  </div>-->
    <div class="my-5 container-fluid" id="section-4">
      <div class="row">
        <div class="col col-12 col-xl-3 col-md-6">
          <div class="card shadow">
            <div class="card-body">
              <div class="card-img">
                <img class="d-block ms-auto me-auto" src="/assets/lp4.png" alt=""/>
              </div>
              <div class="mt-2 mb-4">
                <p class="text-content text-primary fw-bold text-center">
                  {{ "title.scope.financial" | translate }}
                </p>
              </div>
              <div class="aaa">
                <p class="text-content text-primary content">
                  {{ "content.scope.financial" | translate }}
                </p>
              </div>
            </div>
          </div>
          <div style="position: relative; top: -1.2rem" class="d-flex justify-content-center">
            <button class="btn btn-primary text-content" data-bs-toggle="modal"
                    data-bs-target="#more-scope-financial-modal">
              {{ "btn.click_more" | translate }}
            </button>
          </div>
        </div>

        <div class="col col-12 col-xl-3 col-md-6">
          <div class="card shadow">
            <div class="card-body">
              <div class="card-img">
              <img class="d-block ms-auto me-auto" src="/assets/lp5.png" alt=""/>
              </div>
              <div class="mt-2 mb-4">
                <p class="text-content text-primary fw-bold text-center">
                  {{ "title.scope.governance" | translate }}
                </p>
              </div>
              <div class="aaa">
                <p class="text-content text-primary content">
                  {{ "content.scope.governance" | translate }}
                </p>
              </div>
            </div>
          </div>
          <div style="position: relative; top: -1.2rem" class="d-flex justify-content-center">
            <button class="btn btn-primary text-content" data-bs-toggle="modal"
                    data-bs-target="#more-scope-governance-modal">
              {{ "btn.click_more" | translate }}
            </button>
          </div>
        </div>

        <div class="col col-12 col-xl-3 col-md-6">
          <div class="card shadow">
            <div class="card-body">
              <div class="card-img">
              <img class="d-block ms-auto me-auto" src="/assets/lp6.png" alt=""/>
              </div>
              <div class="mt-2 mb-4">
                <p class="text-content text-primary fw-bold text-center">
                  {{ "title.scope.ecommerce" | translate }}
                </p>
              </div>
              <div class="aaa">
                <p class="text-content text-primary content">
                  {{ "content.scope.ecommerce" | translate }}
                </p>
              </div>
            </div>
          </div>
          <div style="position: relative; top: -1.2rem" class="d-flex justify-content-center">
            <button class="btn btn-primary text-content" data-bs-toggle="modal"
                    data-bs-target="#more-scope-ecommerce-modal">
              {{ "btn.click_more" | translate }}
            </button>
          </div>
        </div>

        <div class="col col-12 col-xl-3 col-md-6">
          <div class="card shadow">
            <div class="card-body">
              <div class="card-img">
              <img class="d-block ms-auto me-auto" src="/assets/lp7.png" alt=""/>
              </div>
              <div class="mt-2 mb-4">
                <p class="text-content text-primary fw-bold text-center">
                  {{ "title.scope.telecom" | translate }}
                </p>
              </div>
              <div class="aaa">
              <p class="text-content text-primary content">
                {{ "content.scope.telecom" | translate }}
              </p>
              </div>
            </div>
          </div>
          <div style="position: relative; top: -1.2rem" class="d-flex justify-content-center">
            <button class="btn btn-primary text-content" data-bs-toggle="modal"
                    data-bs-target="#more-scope-telecom-modal">
              {{ "btn.click_more" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="my-5 container-fluid">
      <div class="row">
        <div class="col col-12 col-lg-8 col-md-12 row">
          <div class="col-12 col-lg-9 pr-0" style="
            background: linear-gradient(
              84.43deg,
              #01579b -2.2%,
              #e1f5fe 98.42%
            );
            border-radius: 0px 18px 18px 0px;
            padding: 1.5rem;
          ">
          <span class="text-title text-light fw-bold mb-4 text-uppercase">HUMAN <span
            style="color: #0062ff">RESOURCES</span> &
            <span style="color: #0062ff">CAPACITY</span>
          </span>
            <p class="text-content text-light mt-5">
              {{ "content.section_about.about_1" | translate }}
            </p>
            <p class="text-content text-light">
              {{ "content.section_about.about_2" | translate }}
            </p>
          </div>
          <div class="d-flex flex-column justify-content-center col-12 col-lg-3 p-0">
            <div class="pivot">
              <span class="title">2019</span>
              <span class="content">
              {{ "content.section_about.establishment" | translate }}</span>
            </div>
            <div class="pivot">
              <span class="title">50+</span>
              <span class="content">{{
                "content.section_about.employee" | translate
                }}</span>
            </div>
            <div class="pivot">
              <span class="title">76+</span>
              <span class="content">{{
                "content.section_about.customer" | translate
                }}</span>
            </div>
          </div>
        </div>
        <div class="col col-10 col-lg-4 mx-auto">
          <img class="image-responsive" src="/assets/lp9.png" alt=""/>
        </div>
      </div>
    </div>

    <div class="my-10 container-fluid">
      <div class="d-flex justify-content-center">
      <span class="text-title text-primary">{{
        "title.section_partner" | translate
        }}</span>
      </div>

      <div class="d-flex justify-content-between max-vw-80 partners-scroll">
        <div *ngFor="let partner of partners; index as index" class="d-flex align-items-center">
          <img class="image-responsive img-partner" [src]="partner.image" alt="{{partner.name}}"/>
        </div>
      </div>
    </div>


    <div class="my-5 d-flex flex-row align-items-center">
      <div class="container-fluid">
        <div class="row">
          <div class="col col-md-6 col-sm-12 col-12">
            <img class="image-responsive" src="/assets/lp3.png" alt=""/>
          </div>
          <div class="col col-md-6 col-sm-12 col-12">
            <div class="text-content text-primary">
              <div class="text-title">
                {{ "title.section_intro" | translate }}
              </div>
              <div class="my-4 d-flex align-items-center">
                <span class="material-symbols-outlined me-3"> task_alt </span>
                <span>{{ "content.section_intro.intro_1" | translate }}</span>
              </div>
              <div class="my-4 d-flex align-items-center">
                <span class="material-symbols-outlined me-3"> task_alt </span><span>{{ "content.section_intro.intro_2" |
                translate }}</span>
              </div>
              <div class="my-4 d-flex align-items-center">
                <span class="material-symbols-outlined me-3"> task_alt </span><span>{{ "content.section_intro.intro_3" |
                translate }}</span>
              </div>
              <div class="my-4 d-flex align-items-center">
                <span class="material-symbols-outlined me-3"> task_alt </span>
                <span>{{ "content.section_intro.intro_4" | translate }}</span>
              </div>
              <div class="my-4 d-flex align-items-center">
                <span class="material-symbols-outlined me-3"> task_alt </span><span>{{ "content.section_intro.intro_5" |
                translate }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="my-5 container-fluid">
      <div class="row">
        <div class="col col-lg-5 col-10 mx-auto">
          <img class="image-responsive" src="/assets/lp8.png" alt=""/>
        </div>
        <div class="col col-lg-7 col-12 col-md-12 d-flex flex-column justify-content-center">
        <span class="text-title text-primary">{{
          "title.section_story" | translate
          }}</span>
          <p class="mt-4 text-content text-primary">
            {{ "content.section_story.story_1" | translate }}
          </p>
          <p class="text-content text-primary">
            {{ "content.section_story.story_2" | translate }}
          </p>
          <div class="mt-4">
            <button class="btn btn-primary text-content" data-bs-toggle="modal" data-bs-target="#more-story-modal">
              {{ "btn.click_more" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-footer></app-footer>

  <div class="modal fade" id="more-scope-financial-modal" tabindex="-1" aria-labelledby="more-scope-financial-modal"
       aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="more-scope-financial-modal">
            {{ "title.scope.financial" | translate }}
          </h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p class="text-content text-primary content">
            {{ "more.scope.financial" | translate }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="more-scope-governance-modal" tabindex="-1" aria-labelledby="more-scope-governance-modal"
       aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="more-scope-governance-modal">
            {{ "title.scope.governance" | translate }}
          </h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p class="text-content text-primary content">
            {{ "more.scope.governance" | translate }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="more-scope-ecommerce-modal" tabindex="-1" aria-labelledby="more-scope-ecommerce-modal"
       aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="more-scope-ecommerce-modal">
            {{ "title.scope.ecommerce" | translate }}
          </h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p class="text-content text-primary content">
            {{ "more.scope.ecommerce" | translate }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="more-scope-telecom-modal" tabindex="-1" aria-labelledby="more-scope-telecom-modal"
       aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="more-scope-telecom-modal">
            {{ "title.scope.telecom" | translate }}
          </h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p class="text-content text-primary content">
            {{ "more.scope.telecom" | translate }}
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="more-story-modal" tabindex="-1" aria-labelledby="more-story-modal" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="more-story-modal">
            {{ "title.section_story" | translate }}
          </h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p class="text-content text-primary">
            {{ "more.section_story.story" | translate }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
