import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {look_up_code} from '../models/look_up_code';
import {look_up_value_search} from '../models/dto/look_up_value_search';
import {response} from '../models/response';
import {AuthService} from './auth.service';
import {look_up_value} from "../models/look_up_value";
import {lookUpValue} from "../models/job";

@Injectable({
  providedIn: 'root'
})
export class LookUpValueService {
  private apiUrl = environment.apiUrl;
  private token = this.authService.getToken();
  private httpOptions = {
    headers: {
      Authorization: `Bearer ${this.token}`
    },
  }
  constructor(
    private authService: AuthService,
    private http: HttpClient
  ) { }

  searchLookUpValue(data: look_up_value_search): Observable<response<look_up_value[]>> {
    return this.http.post<response<look_up_value[]>>(`${this.apiUrl}/lookUpValue/search`, data, this.httpOptions)
  }

  getLookUpCodeById(id: number | undefined): Observable<look_up_code> {
    return this.http.get<look_up_code>(`${this.apiUrl}/lookUpValue/detail/${id}`, this.httpOptions)
  }

  createLookUpValue(data: look_up_value) {
    return this.http.post(`${this.apiUrl}/lookUpValue/create`, data, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
      responseType: 'text'
    })
  }

  updateLookUpValueById(id: number | undefined, data: look_up_code) {
    return this.http.post(`${this.apiUrl}/lookUpValue/update/${id}`, data, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
      responseType: 'text'
    })
  }

  deletelookUpValueById(ids: number | undefined) {
    return this.http.post(`${this.apiUrl}/lookUpValue/delete`,ids , {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
      responseType: 'text'
    })
  }

  getLcos(searchCriteria: {
    pageNumber: number;
    pageSize: number
  }): Observable<response<look_up_code[]>> {
    const url = `${this.apiUrl}/lookUpCode/search`;
    return this.http.post<response<look_up_code[]>>(url, searchCriteria, {headers: this.httpOptions.headers});
  }

}
