import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { RoleUtils } from './role.util';
import { AuthService } from './services/auth.service';
import { UrlUtils } from './url.util';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    if (!this.authService.isAuthenticated()) {
      this.router.navigate([UrlUtils.getUrl('/login')]);
    } else {
      if (
        !RoleUtils.isAccepted(
          route.data['role'],
          this.authService.getScopes()
        )
      ) {
        this.router.navigate([UrlUtils.getUrl('dashboard')]);
        return false;
      }
    }

    return true;
  }
}
