import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { response } from '../models/response';
import { role } from '../models/role';
import { AuthService } from './auth.service';


@Injectable({
  providedIn: 'root'
})
export class RoleService {
  private apiUrl = environment.apiUrl;
  private token = this.authService.getToken();
  private httpOptions = {
    headers: {
      Authorization: `Bearer ${this.token}`
    }
  }
  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  getAll(status: number, page: number): Observable<response<role[]>> {
    return this.http.get<response<role[]>>(`${this.apiUrl}/role/select?status=${status}&page=${page}&sort=create_desc`, this.httpOptions);
  }

  listAllRole(): Observable<response<role[]>> {
    return this.http.get<response<role[]>>(`${this.apiUrl}/role/get-all-action-role`, this.httpOptions);
  }

  downloadTemplateExcel(): Observable<HttpResponse<Blob>> {
    return this.http.get(`${this.apiUrl}/role/download-template`, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`
      }),
      observe: 'response',
      responseType: 'blob',
    });
  }

  selectAll(status: number, page: number,name: string,order: string): Observable<response<role[]>> {
    return this.http.get<response<role[]>>(`${this.apiUrl}/role/select?status=${status}&page=${page}&name=${name}&sort=${order}`, this.httpOptions);
  }

  filterRole(status: number): Observable<response<role[]>> {
    return this.http.get<response<role[]>>(`${this.apiUrl}/role/select?status=${status}`, this.httpOptions)
  }

  searchRole(name: string): Observable<role[]> {
    return this.http.get<role[]>(`${this.apiUrl}/role/select?name=${name}`, this.httpOptions)
  }

  sortRole(order: string): Observable<response<role[]>> {
    return this.http.get<response<role[]>>(`${this.apiUrl}/role/select?sort=${order}`, this.httpOptions)
  }

  getById(id: number | undefined): Observable<role> {
    return this.http.get<role>(`${this.apiUrl}/role/selectOne?id=${id}`, this.httpOptions);
  }

  create(data: role, file: any) {
    const formData = new FormData()
    formData.append('role', new Blob([
      JSON.stringify(data)
    ], {
      type: 'application/json'
    }));
    formData.append('file', file)
    return this.http.post(`${this.apiUrl}/role/create`, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`
      },
      responseType: 'text'
    });
  }

  updateById(id: number | undefined, data: role) {
    return this.http.post(`${this.apiUrl}/role/update?id=${id}`, data, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
      responseType: 'text'
    });
  }

  deleteById(id: number[] | undefined) {
    return this.http.post(`${this.apiUrl}/role/delete`, id, {
      headers: {
        Authorization: `Bearer ${this.token}`
      },
      responseType: 'text'
    });
  }
}
