
import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { Constants } from '../constants';
import { contact, contactData } from '../models/contact';
import { ContactService } from '../services/contact.service';

@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.css']
})
export class ContactPageComponent implements OnInit {
  contacts: contact[] = [];
  contact: contactData = {
    name: '',
    email: '',
    phoneNumber: '',
    subject: '',
    content: '',
  }

  selectValue = 0;
  errors : string[] = [];

  constructor(
    public translate: TranslateService,
    private contactService: ContactService,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {

  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const navBar = document.getElementById('navbar');
    const classList = navBar?.classList;
    if (document.documentElement.scrollTop > 100) {
      classList?.add('navbar-scrolled');
    } else {
      classList?.remove('navbar-scrolled');
    }
  }

  setLang(langCode: string) {
    this.translate.use(langCode);
    Constants.setLang(langCode);
  }

  onClickInfo(event : MouseEvent): void {
    this.selectValue = 1;
    location.href = '/contact#contact_info';
  }

  onClickEmail(event : MouseEvent): void {
    this.selectValue = 2;
    location.href = '/contact#send_email';
  }

  onClickSupport(event : MouseEvent): void {
    this.selectValue = 3;
    location.href = '/contact#online_support';
  }

  clearValue() : void {
    this.contact.name = '';
    this.contact.email = '';
    this.contact.phoneNumber = '';
    this.contact.subject = '';
    this.contact.content = '';
  }
  checkData(data : contact) : Boolean {
    this.trimData(data);
    if (data.name.length == 0)
      this.errors.push("Họ và tên không được để trống!");
    if (data.email.length == 0)
      this.errors.push("Email không được để trống!");
    if (data.phoneNumber.length == 0)
      this.errors.push("Số điện thoại không được để trống!");
    if (data.subject.length == 0)
      this.errors.push("Tiêu đề không được phép để trống!");
    if (data.content.length == 0)
      this.errors.push("Nội dung không được phép để trống!");

    if (this.errors.length == 0) return true;
    else return false;
  }

  trimData(data : contact): void {
    data.name = data.name.trim();
    data.email = data.email.trim();
    data.phoneNumber = data.phoneNumber.trim();
    data.subject = data.subject.trim();
    data.content = data.content.trim();
  }

  addMessageErrors(): void{
    for (let index = 0; index < this.errors.length; index++) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: this.errors[index]
      })
    }
    this.errors.length = 0;
  }

  onSubmit(data: contact): void {
    if (this.checkData(data)) {
      if (!data.email.match('^([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$')) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Địa chỉ email không hợp lệ!'
        })
      } else if (!data.phoneNumber.match('^(0?)(3[2-9]|5[6|8|9]|7[0|6-9]|8[0-6|8|9]|9[0-4|6-9])[0-9]{7}$')) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Số điện thoại không hợp lệ!'
        })
      } else {
        this.contactService.createContact(data, undefined).subscribe({
          next: () => {
            this.clearValue();
            this.messageService.add({
              severity: 'success',
              summary: 'Gửi email',
              detail: 'Đã gửi email thành công!'
            })
          },
          error: (err: HttpErrorResponse) => {
            console.log(err);
            this.messageService.add({
              severity: 'error',
              summary: 'Gửi email',
              detail: 'Đã có lỗi xảy khi gửi email!'
            })
          }
        })
      }
    } else {
      this.addMessageErrors();
    }
  }
}
