import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { debounceTime, distinctUntilChanged, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { partner } from '../models/partner';
import { response } from '../models/response';
import { role } from '../models/role';
import { user } from '../models/user';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private apiUrl = environment.apiUrl;
  private token: any = null;
  private httpOptions = {}

  constructor(
    private http: HttpClient,
    private authSerivce: AuthService
  ) { }

  createAccount(data: user, roleId: number) {
    const formData = new FormData();
    formData.append('username', JSON.stringify(data));
    formData.append('idRole', String(roleId));
    this.token = this.authSerivce.getToken();
    return this.http.post(`${this.apiUrl}/user/create`, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`
      },
      responseType: 'text'
    });
  }

  uploadFile(file: any){
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post(`${this.apiUrl}/user/create`, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`
      },
      responseType: 'text'
    });
  }

  downloadTemplateExcel(): Observable<HttpResponse<Blob>> {
    return this.http.get(`${this.apiUrl}/user/download-template`, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`
      }),
      observe: 'response',
      responseType: 'blob',
    });
  }

  getRoleIdByUserId(id : number| undefined): Observable<number>{
    return this.http.get<number>(`${this.apiUrl}/user/get-role-id-by-user-id?id=${id}`, this.httpOptions);
  }

  getAll(status: number, page: number): Observable<response<user[]>> {
    this.setToken();
    return this.http.get<response<user[]>>(`${this.apiUrl}/user/select?status=${status}&page=${page}&sort=create_desc`, this.httpOptions);
  }

  selectAll(status: number, page: number,username : string, sort: string): Observable<response<user[]>> {
    this.setToken();
    return this.http.get<response<user[]>>(`${this.apiUrl}/user/select?status=${status}&page=${page}&username=${username}&sort=${sort}`, this.httpOptions);
  }

  searchUser(username: string): Observable<user[]> {
    return this.http.get<user[]>(`${this.apiUrl}/user/select?username=${username}`, this.httpOptions)
  }

  getById(id: number | undefined): Observable<response<user>> {
    this.setToken();
    return this.http.get<response<user>>(`${this.apiUrl}/user/selectOne?id=${id}`, this.httpOptions);
  }

  updateById(id: number | undefined, data: string, idRole: number) {
    const formData = new FormData();
    formData.append('id', String(id));
    formData.append('username', data);
    formData.append('role', String(idRole));

    this.token = this.authSerivce.getToken();
    return this.http.post(`${this.apiUrl}/user/update?id=${id}`, formData, {
      headers: {
        'Authorization': `Bearer ${this.token}`,
      },
      responseType: 'text'
    });
  }

  deleteById(id: number[] | undefined) {
    this.token = this.authSerivce.getToken();
    return this.http.post(`${this.apiUrl}/user/delete?id=${id}`, id, {
      headers: {
        Authorization: `Bearer ${this.token}`
      },
      responseType: 'text'
    })
  }

  setToken() {
    this.token = this.authSerivce.getToken();
    this.httpOptions = {
      headers: {
        Authorization: `Bearer ${this.token}`
      }
    }
  }

  sortUser(sort: string): Observable<response<user[]>>{
    return this.http.get<response<user[]>>(`${this.apiUrl}/user/select?sort=${sort}`, this.httpOptions)
  }

}
