<div id="login-page" class="d-flex justify-content-center align-items-center">
  <div class="container-fluid">
    <p-toast></p-toast>
    <div class="row justify-content-center">
      <div class="col col-xl-4 col-lg-6 col-sm-8 col-10 ss-card">
        <div class="row mx-md-4 px-md-5 mx-sm-2 px-sm-2 px-2 my-5">
          <div class="col-12 col-sm-2 d-flex justify-content-center">
            <img
              src="/assets/logo.png"
              alt="icon"
              style="width: 4rem; height: 4rem"
            />
          </div>
          <div class="col-12 col-sm-10 d-flex justify-content-center">
            <span
              class="login-header align-self-center text-uppercase text-center text-primary"
              >Đăng nhập hệ thống</span
            >
          </div>
        </div>
        <form
          #loginForm="ngForm"
          (submit)="submit(loginForm.value)"
          class="mx-md-4 px-md-5 mx-sm-2 px-sm-2 px-2 py-md-4"
        >
          <div class="form-group">
            <label for="username" class="my-2">Tên tài khoản</label>
            <input
              type="text"
              class="form-control mb-2"
              id="username"
              name="username"
              [(ngModel)]="user.username"
              #username="ngModel"
              placeholder="Nhập tên tài khoản"
              autocomplete="off"
              tabindex="1"
              required
            />
          </div>
          <div *ngIf="username.invalid && (username.dirty || username.touched)">
            <p class="text-danger">
              Vui lòng nhập tài khoản
            </p>
          </div>
          <label for="password" class="my-2">Mật khẩu</label>
          <div class="input-group">
            <input
              [type]="hide ? 'password' : 'text'"
              class="form-control"
              id="password"
              name="password"
              [(ngModel)]="user.password"
              #password="ngModel"
              placeholder="Nhập mật khẩu"
              tabindex="2"
              required
            />
            <span class="show-btn input-group-text" id="show-btn" (click)="showPassword()" style="background-color: #fff;"><i [ngClass]="hide ? 'fas fa-eye' : 'fas fa-eye-slash'"></i></span>
          </div>
          <div>
            <div
              *ngIf="password.invalid && (password.dirty || password.touched)"
            >
              <p class="text-danger">
                Vui lòng nhập mật khẩu
              </p>
            </div>
          </div>
          <div class="form-group form-check my-4">
            <input
              type="checkbox"
              class="form-check-input"
              id="remember"
              tabindex="3"
            />
            <label class="form-check-label text-primary" for="remember"
              >Nhớ mật khẩu...</label
            >
          </div>
          <button
            [disabled]="loginForm.invalid"
            type="submit"
            class="btn btn-primary w-100 my-5"
            tabindex="4"
          >
            Đăng nhập
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
