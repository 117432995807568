
import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from '../constants';

@Component({
  selector: 'app-faq-page',
  templateUrl: './faq-page.component.html',
  styleUrls: ['./faq-page.component.css']
})
export class FAQPageComponent implements OnInit {

  constructor(
    public translate: TranslateService,
  ) { }

  ngOnInit(): void {

  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const navBar = document.getElementById('navbar');
    const classList = navBar?.classList;
    if (document.documentElement.scrollTop > 100) {
      classList?.add('navbar-scrolled');
    } else {
      classList?.remove('navbar-scrolled');
    }
  }

  setLang(langCode: string) {
    this.translate.use(langCode);
    Constants.setLang(langCode);
  }
}
