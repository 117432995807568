<div>
  <p-toast></p-toast>
  <app-header></app-header>

  <div class="container">
    <div class="container-fluid company-intro p-0">
      <div class="row">
        <div class="col-7 company-intro-content">
          <h2 class="mt-5">Join us</h2>
          <h1 class="pb-4 text-primary">Smart Solutions VietNam</h1>
          <h2>Bạn đang tìm một nơi để khai phá bản thân? Hãy tham gia với chúng
            tôi ngay nào !</h2>
          <p>Với mức tiêu chuẩn công nghệ tiên tiến và cơ hội thăng tiến, Smart
            Solutions hứa hẹn mang đến những trải nghiệm
            tuyệt vời nhất và cơ hội phát huy tối đa khả năng của mình.</p>
        </div>
        <div class="col-5 company-intro-image">
          <img class="image" src="/assets/recruitment_job.png">
        </div>
      </div>
    </div>

    <div class="row">
      <div class="job-left-content ps-0">
        <div class="job-card job-intro p-4">
          <h5 class="fw-bold">{{ jobDetail?.title }}</h5>
          <div class="row m-0 my-4">
            <div
              class="col-1 job-icon">
              <i class="pi pi-dollar"></i>
            </div>
            <div class="col-3">
              <div class="row">Mức lương</div>
              <div class="row fw-bold">{{ jobDetail?.salaryName }}</div>
            </div>
            <div
              class="col-1 job-icon">
              <i class="pi pi-map-marker"></i>
            </div>
            <div class="col-3">
              <div class="row">Địa điểm</div>
              <div class="row fw-bold">Hà Nội</div>
            </div>
            <div
              class="col-1 job-icon">
              <i class="pi pi-briefcase"></i>
            </div>
            <div class="col-3">
              <div class="row">Kinh nghiệm</div>
              <div class="row fw-bold">{{ jobDetail?.experienceName }}</div>
            </div>
          </div>
          <div>
            <span class="d-flex align-items-center">
              <i class="pi pi-clock"></i>
              <span class="ms-2">Hạn nộp: {{ jobDetail?.recruitmentTo | date:
                "dd/MM/yyyy" }}</span>
            </span>
          </div>
          <div
            class="row m-0 mt-3">
            <button style="height: 45px" type="button" pButton pRipple
                    class="search-button d-flex justify-content-center"
                    data-bs-toggle="modal"
                    data-bs-target="#applyDialogModal">
              <span>
                <i class="pi pi-send"></i>
                <span class="ms-2">Ứng tuyển ngay</span>
              </span>
            </button>
          </div>
        </div>

        <div class="job-card job-detail my-4 p-4">
          <div>
            <span class="d-flex align-items-center">
              <div class="title"></div>
              <h5 class="fw-bold m-0 ms-2">Chi tiết tin tuyển dụng</h5>
            </span>
          </div>
          <div class="row m-0 mt-4">
            <p class="m-0 mb-2 p-0" [innerHTML]="jobDetail?.content">
          </div>
          <span>Hạn nộp hồ sơ: {{ jobDetail?.recruitmentTo | date:
            "dd/MM/yyyy" }}</span>
          <button style="height: 40px" type="button" pButton pRipple
                  class="search-button d-flex justify-content-center my-3"
                  data-bs-toggle="modal"
                  data-bs-target="#applyDialogModal">
            <span>Ứng tuyển ngay</span>
          </button>
        </div>
      </div>
      <div class="job-right-content">
        <div class="job-card container-fluid p-4">
          <h5 class="fw-bold">Thông tin chung</h5>
          <div class="row m-0 my-4">
            <div
              class="col-1 job-icon">
              <i class="pi pi-star"></i>
            </div>
            <div class="col-9 ms-2">
              <div class="row">Cấp bậc</div>
              <div class="row fw-bold">{{ jobDetail?.levelName }}</div>
            </div>
          </div>
          <div class="row m-0 my-4">
            <div
              class="col-1 job-icon">
              <i class="pi pi-briefcase"></i>
            </div>
            <div class="col-9 ms-2">
              <div class="row">Kinh nghiệm</div>
              <div class="row fw-bold">{{ jobDetail?.experienceName }}</div>
            </div>
          </div>
          <div class="row m-0 my-4">
            <div
              class="col-1 job-icon">
              <i class="pi pi-users"></i>
            </div>
            <div class="col-9 ms-2">
              <div class="row">Số lượng tuyển</div>
              <div class="row fw-bold">{{ jobDetail?.quantity }}</div>
            </div>
          </div>
          <div class="row m-0 my-4">
            <div
              class="col-1 job-icon">
              <i class="pi pi-hourglass"></i>
            </div>
            <div class="col-9 ms-2">
              <div class="row">Hình thức làm việc</div>
              <div class="row fw-bold">Toàn thời gian</div>
            </div>
          </div>
          <div class="row m-0 my-4 mb-0">
            <div
              class="col-1 job-icon">
              <i class="pi pi-user"></i>
            </div>
            <div class="col-9 ms-2">
              <div class="row">Giới tính</div>
              <div class="row fw-bold">Không yêu cầu</div>
            </div>
          </div>
        </div>

        <div class="job-card container-fluid my-4 p-4">
          <h5 class="fw-bold">Công việc khác</h5>
          <div  class="job-listings" *ngFor="let job of jobs">
            <div class="my-3 p-2 p-card" [routerLink]="['/job/job-detail', job.id]">
              <p class="m-2 fw-bold job-title">{{ job.title }}</p>
              <p class="m-2 fw-bold job-salary">{{job.salaryName}}</p>
            </div>
          </div>
          <div class="pt-2 text-center">
            <a [routerLink]="['/job']" class="fw-bold text-decoration-none">Xem
              thêm công việc
              <i class="ms-3 pi pi-arrow-right"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-apply-dialog [title]="jobDetail?.title"
                    [recruitmentId]="jobDetail?.id"></app-apply-dialog>
  <app-footer></app-footer>
</div>
