import {Component, OnInit} from '@angular/core';
import {product, product_search} from "../../models/product";
import {ProductService} from "../../services/product.service";
import {response} from "../../models/response";
import {HttpErrorResponse} from "@angular/common/http";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-product-detail-page',
  templateUrl: './product-detail-page.component.html',
  styleUrls: ['./product-detail-page.component.css']
})
export class ProductDetailPageComponent implements OnInit {

  products: product[] = [];
  productDetail: product | null = null;

  constructor(
    private productService: ProductService,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const idParam = params.get('id');
      const id = idParam ? parseInt(idParam, 10) : undefined;
      this.getProductDetail(id);
    });
    this.otherProducts();
  }

  private getProductDetail(id: number | undefined): void {
    this.productService.getProductById(id).subscribe({
      next: (data: product) => {
        this.productDetail = data;
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      }
    })
  }

  private otherProducts(): void {
    const params: product_search = {
      pageNumber: 0,
      pageSize: 5,
    };
    this.productService.searchProduct(params).subscribe({
      next: (response: response<product[]>) => {
        this.products = response.content;
      },
      error: (error) => this.handleError('Search failed', error)
    });
  }

  private handleError(message: string, error: any): void {
    console.error(`${message}:`, error);
    // You could add more sophisticated error handling here, like showing a user-friendly message
  }
}
