import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { partner } from '../models/partner';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class PartnerService {
  private apiUrl = environment.apiUrl;
  private token = this.authService.getToken();
  private httpOptions = {
    headers: {
      Authorization: `Bearer ${this.token}`
    },
  }
  constructor(
    private authService: AuthService,
    private http: HttpClient
  ) { }

  getPartners(page: number): Observable<partner[]> {
    return this.http.get<partner[]>(`${this.apiUrl}/partner/select?page=${page}&sort=create_desc`, this.httpOptions)
  }

  selectAll(page: number, status : number, search: string, sort: string): Observable<partner[]> {
    return this.http.get<partner[]>(`${this.apiUrl}/partner/select?status=${status}&page=${page}&sort=${sort}&search=${search}`, this.httpOptions)
  }

  filterPartner(status: number): Observable<partner[]> {
    return this.http.get<partner[]>(`${this.apiUrl}/partner/select?status=${status}`, this.httpOptions)
  }

  sortPartner(order: string): Observable<partner[]> {
    return this.http.get<partner[]>(`${this.apiUrl}/partner/select?sort=${order}`, this.httpOptions)
  }

  getPartnerById(id: number | undefined): Observable<partner> {
    return this.http.get<partner>(`${this.apiUrl}/partner/selectOne?id=${id}`, this.httpOptions)
  }

  searchPartner(search: string | null): Observable<partner[]> {
    return this.http.get<partner[]>(`${this.apiUrl}/partner/select?search=${search}`, this.httpOptions);
  }

  uploadFile(file: any) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post(`${this.apiUrl}/partner/create`, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`
      },
      responseType: 'text'
    })
  }

  createPartner(data: partner, file: any) {
    const formData = new FormData();
    formData.append('image', file, file.name);
    formData.append('name', data.name)
    return this.http.post(`${this.apiUrl}/partner/create`, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`
      },
      responseType: 'text'
    });
  }

  updatePartnerById(id: number | undefined, data: any, file: string | Blob) {
    const formData = new FormData();
    formData.append('id', String(id));
    formData.append('name', data.name)
    formData.append('image', file)
    return this.http.post(`${this.apiUrl}/partner/update`, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`
      },
      responseType: 'text'
    })
  }

  deletePartnerById(id: number[] | undefined) {
    return this.http.post(`${this.apiUrl}/partner/delete`, id, {
      headers: {
        Authorization: `Bearer ${this.token}`
      },
      responseType: 'text'
    })
  }

  countPartner(): Observable<number> {
    return this.http.get<number>(`${this.apiUrl}/partner/countPartner`, this.httpOptions);
  }

}
