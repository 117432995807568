<div class="card border-0 shadow-sm">
  <div class="card-body d-flex justify-content-between align-items-center">
    <div class="card-content">
      <p class="card-title text-secondary">{{ title }}</p>
      <p class="card-tex fw-semibold">
        {{ content }}
      </p>
    </div>
    <i class="card-icon mat-icon material-symbols-outlined">
      {{ icon }}
    </i>
  </div>
</div>
