import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { news_type } from '../models/news_type';
import { response } from '../models/response';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class NewsTypeService {
  private apiUrl = environment.apiUrl;
  private token = this.authService.getToken();
  private httpOptions = {
    headers: {
      Authorization: `Bearer ${this.token}`
    },
  }
  constructor(
    private authService: AuthService,
    private http: HttpClient
  ) { }

  getListNewsType(status: number, page: number): Observable<response<news_type[]>> {
    return this.http.get<response<news_type[]>>(`${this.apiUrl}/newstype/select?status=${status}&page=${page}&sort=create_desc`, this.httpOptions);
  }

  selectAll(status: number, page: number, search: string, sort: string): Observable<response<news_type[]>> {
    return this.http.get<response<news_type[]>>(`${this.apiUrl}/newstype/select?status=${status}&page=${page}&sort=${sort}&search=${search}`, this.httpOptions);
  }

  sortNewsTypeByCondition(condition: string): Observable<response<news_type[]>> {
    return this.http.get<response<news_type[]>>(`${this.apiUrl}/newstype/select?sort=${condition}`, this.httpOptions);
  }

  searchNewsType(keyword: string): Observable<response<news_type[]>> {
    return this.http.get<response<news_type[]>>(`${this.apiUrl}/newstype/select?search=${keyword}`, this.httpOptions)
  }

  getNewsTypeById(id: number | undefined): Observable<news_type> {
    return this.http.get<news_type>(`${this.apiUrl}/newstype/selectOne?id=${id}`, this.httpOptions)
  }

  createNewsType(data: news_type) {
    data.status = 1;
    const formData = new FormData();
    formData.append('news_type', new Blob([
      JSON.stringify(data)
    ], {
      type: 'application/json'
    }));
    return this.http.post(`${this.apiUrl}/newstype/create`, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
      responseType: 'text'
    })
  }

  importExcel(file: string | Blob) {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post(`${this.apiUrl}/newstype/create`, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
      responseType: 'text'
    })
  }

  updateNewsTypeById(id: number | undefined, data: news_type) {
    const formData = new FormData();
    formData.append('news_type', new Blob([
      JSON.stringify(data)
    ], {
      type: 'application/json'
    }));
    return this.http.post(`${this.apiUrl}/newstype/update?id=${id}`, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
      responseType: 'text'
    })
  }

  deleteNewsTypeById(id: number | undefined) {
    return this.http.post(`${this.apiUrl}/newstype/delete`, id, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
      responseType: 'text'
    })
  }

}
