<div class="container-fluid p-4">
  <p-toast> </p-toast>
  <p-confirmDialog
    [style]="{ width: '50vw' }"
    [baseZIndex]="10000"
  ></p-confirmDialog>
  <!-- Alert -->
  <div>
    <div class="fixed-bottom d-flex justify-content-center">
      <div
        [hidden]="selectedValue.length === 0"
        class="alert alert-danger w-50"
        role="alert"
      >
        <div class="d-flex justify-content-between align-items-center gap-2">
          <p style="margin-bottom: 0">
            Đã chọn {{ selectedValue.length }} phần tử
          </p>
          <div>
            <button
            class="btn btn-light px-4 cursor-pointer me-3"
            (click)="onCheckBox()"
          >
            Hủy
          </button>

            <button
            class="btn btn-danger px-4 cursor-pointer"
            (click)="onDelete(selectedValue)"
          >
            Xóa
          </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Header -->
  <div class="d-flex justify-content-between">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a class="text-dark text-decoration-none" routerLink="/admin"
            >Admin</a
          >
        </li>
        <li class="breadcrumb-item">
          <a class="text-dark text-decoration-none" routerLink="/organization"
            >Dịch vụ</a
          >
        </li>
      </ol>
    </nav>
    <div class="d-flex gap-2">
      <button
        class="d-flex gap-2 btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#add-modal"
      >
        <i class="material-symbols-outlined"> person_add </i>
        Thêm mới
      </button>
    </div>
  </div>
  <!-- Table -->
  <div class="table-wrapper mt-4">
    <div class="table-responsive border border-bottom-0 rounded-top">
      <div class="table-header rounded-top">
        <div
          class="d-flex bg-light justify-content-between align-items-center p-2"
        >
          <div class="d-flex gap-2">
            <select
            name="filterValue"
            class="form-select"
            style="max-width: 200px"
            (change)="onFilter($event)"
          >
            <option [value]="1" selected>Lọc theo</option>
            <option [value]="0">Tất cả</option>
            <option [value]="1">Hoạt động</option>
            <option [value]="-1">Không hoạt động</option>
          </select>

          <select
              class="form-select"
              style="max-width: 200px"
              (change)="onSort($event)"
            >
              <option value="">Sắp xếp theo</option>
              <option value="id_asc">id tăng dần</option>
              <option value="id_desc">id giảm dần</option>
              <option value="name_asc">Từ a - z</option>
              <option value="name_desc">Từ z - a</option>
              <option value="create_desc">Dịch vụ mới thêm</option>
              <option value="update_desc">Dịch vụ mới sửa</option>
            </select>
          </div>
          <input
            type="text"
            name="searchValue"
            class="form-control"
            placeholder="Tìm kiếm..."
            style="max-width: 260px"
            #searchInput
            (input)="onSearch(searchInput.value)"
          />
        </div>
        <table class="table table-responsive table-body">
          <thead>
            <tr>
              <th>
                <input type="checkbox" [checked]="checkAll" name="" class="form-check-input" (change)="onCheckAll($event)" />
              </th>
              <th class="col text-secondary">STT</th>
              <th class="col text-secondary">Tên dịch vụ</th>
              <th class="col text-secondary">Hình ảnh</th>
              <th class="col text-secondary">Trạng thái</th>
              <th class="col text-secondary">Thông tin</th>
              <th class="col text-secondary">Loại dịch vụ</th>
              <th class="col text-secondary">Ngày tạo</th>
              <th class="col text-secondary">Ngày sửa</th>
              <th class="col text-secondary">Người tạo</th>
              <th class="col text-secondary">Người sửa</th>
              <th class="col text-secondary">Thao tác</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let service of services; index as index">
              <td>
                <input
                  [checked]="checked"
                  type="checkbox"
                  name=""
                  class="form-check-input"
                  [value]="service.id"
                  [checked]="checkedCheckBox[index]"
                  (change)="onCheckedValue($event, index)"
                />
              </td>
              <td>{{ (pageNumber * 10) + index + 1 }}</td>
              <td>{{ service.name }}</td>
              <td>
                <img [src]="service.image" alt="" width="50" height="50" />
              </td>
              <td>
                <div
                  class="d-inline-block bg-success bg-opacity-25 text-success p-2 rounded"
                  [ngClass]="{
                    'text-success bg-success': service.status === 1,
                    'text-danger bg-danger ': service.status === -1,
                    'text-warning bg-warning': service.status === null
                  }"
                >
                  {{ service.status === 1 ? "Hoạt động" : "Không hoạt động" }}
                </div>
              </td>
              <td class="text-truncate" style="max-width: 150px">
                {{ service.information }}
              </td>
              <td>{{ service.type }}</td>
              <td>{{ service.createTime | date: "dd/MM/yyyy" }}</td>
              <td>{{ service.updateTime | date: "dd/MM/yyyy" }}</td>
              <td>{{ service.createBy }}</td>
              <td>{{ service.updateBy }}</td>
              <td>
                <i
                [hidden]="service.status === -1"
                  class="material-symbols-outlined"
                  data-bs-toggle="modal"
                  data-bs-target="#edit-modal"
                  (click)="getById(service.id)"
                >
                  edit
                </i>
                <i
                [hidden]="service.status === -1"
                  class="material-symbols-outlined mx-2"
                  (click)="onDelete(service.id)"
                >
                  delete
                </i>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="table-footer d-flex align-items-center border-bottom">
          <a [hidden]="totalItem === 0" class="ms-3" style="text-decoration: none">Hiển thị từ {{(pageNumber * 10) + 1}} - {{(pageNumber * 10)+ numberOfElements}}/{{totalItem}} dịch vụ</a>
          <a [hidden]="totalItem > 0" class="ms-3" style="text-decoration: none">Không có dữ liệu</a>
          <div style="width: 70%; float: right;">
            <p-paginator
            [rows]="itemPerPage"
            [pageLinkSize]="totalPage"
            [totalRecords]="totalItem"
            (onPageChange)="onPageChange($event)"
          ></p-paginator>
          </div>
      </div>
    </div>

    <!-- Add-modal -->
    <div
      class="modal fade"
      id="add-modal"
      tabindex="-1"
      aria-labelledby="add-modal"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="add-modal">Thêm mới Dịch vụ</h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form #formAdd="ngForm" (submit)="onSubmit(formAdd.value)">
              <div class="py-2">
                <label for="username">Tên Dịch vụ <span style="color: red">*</span></label>
                <input
                  type="text"
                  name="name"
                  class="form-control my-2"
                  #name="ngModel"
                  [(ngModel)]="service.name"
                  placeholder="nhập tên dịch vụ"
                  required
                />
              </div>
              <div *ngIf="name.invalid && (name.dirty || name.touched)">
                <p class="text-danger" *ngIf="name.errors?.['required']">
                  Vui lòng nhập tên dịch vụ
                </p>
              </div>
              <div class="py-2">
                <label for="image">Hình ảnh <span style="color: red">*</span></label>
                <input
                  type="file"
                  name="image"
                  class="form-control my-2"
                  (change)="onChangeFile($event)"
                />
              </div>
              <div class="py-2">
                <label for="information">Thông tin <span style="color: red">*</span></label>
                <textarea
                  name="information"
                  class="form-control my-2"
                  #information="ngModel"
                  [(ngModel)]="service.information"
                  [cols]="0"
                  [rows]="10"
                  required
                ></textarea>
              </div>
              <div
                *ngIf="
                  information.invalid &&
                  (information.dirty || information.touched)
                "
              >
                <p class="text-danger" *ngIf="information.errors?.['required']">
                  Vui lòng nhập thông tin
                </p>
              </div>
              <div class="py-2">
                <label for="information">Loại dịch vụ <span style="color: red">*</span></label>
                <select
                  name="type"
                  class="form-select"
                  #type="ngModel"
                  [(ngModel)]="service.type"
                  required
                >
                  <option [value]="0">Chọn loại dịch vụ</option>
                  <option
                    *ngFor="let service of serviceData"
                    [value]="service.id"
                  >
                    {{ service.name }}
                  </option>
                </select>
              </div>
              <div *ngIf="type.invalid && (type.dirty || type.touched)">
                <p class="text-danger" *ngIf="type.errors?.['required']">
                  Vui lòng chọn kiểu dịch vụ
                </p>
              </div>
              <div class="d-flex justify-content-end gap-2">
                <button
                  type="button"
                  class="btn btn-outline-secondary"
                  data-bs-dismiss="modal"
                >
                  Hủy
                </button>
                <button
                  type="submit"
                  class="btn btn-primary"
                  data-bs-dismiss="modal"
                  [disabled]="formAdd.invalid"
                >
                  Thêm Mới
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Edit-modal -->
    <div
      class="modal fade"
      id="edit-modal"
      tabindex="-1"
      aria-labelledby="edit-modal"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="edit-modal">Sửa Dịch vụ</h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form
              #formEdit="ngForm"
              (submit)="onEdit(serviceDetail.id, formEdit.value)"
            >
              <div class="py-2">
                <label for="name">Tên Dịch vụ</label>
                <input
                  type="text"
                  name="name"
                  #name="ngModel"
                  [value]="serviceDetail.name"
                  [(ngModel)]="serviceDetail.name"
                  class="form-control my-2"
                  placeholder="nhập tên Dịch vụ"
                  required
                />
              </div>
              <div *ngIf="name.invalid && (name.dirty || name.touched)">
                <p class="text-danger" *ngIf="name.errors?.['required']">
                  Vui lòng nhập tên dịch vụ
                </p>
              </div>
              <div class="py-2">
                <label for="image">Hình ảnh</label>
                <input
                  type="file"
                  name="image"
                  class="form-control my-2"
                  placeholder="thêm Hình ảnh"
                  (change)="onChangeFile($event)"
                />
              </div>
              <div class="py-2">
                <label for="information">Thông tin</label>
                <textarea
                  name="information"
                  class="form-control my-2"
                  [(ngModel)]="serviceDetail.information"
                  [cols]="0"
                  [rows]="10"
                  #information="ngModel"
                  required
                  >{{ serviceDetail.information }}</textarea
                >
              </div>
              <div
                *ngIf="
                  information.invalid &&
                  (information.dirty || information.touched)
                "
              >
                <p
                  class="text-danger py-2"
                  *ngIf="information.errors?.['required']"
                >
                  Vui lòng nhập thông tin
                </p>
              </div>
              <div class="py-2">
                <label for="information">Loại dịch vụ</label>
                <select
                  name="type"
                  class="form-select"
                  [(ngModel)]="serviceDetail.type"
                >
                  <option
                    *ngFor="let service of serviceData"
                    [value]="service.id"
                  >
                    {{ service.name }}
                  </option>
                </select>
              </div>
              <div class="d-flex justify-content-end gap-2">
                <button
                  type="button"
                  class="btn btn-outline-secondary"
                  data-bs-dismiss="modal"
                >
                  Hủy
                </button>
                <button
                  type="submit"
                  class="btn btn-primary"
                  data-bs-dismiss="modal"
                >
                  Lưu
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Import modal -->
    <div
      class="modal fade"
      id="import-modal"
      tabindex="-1"
      aria-labelledby="import-modal"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="import-modal">Thêm mới Dịch vụ</h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form
              #formImport="ngForm"
              enctype="multipart/form-data"
              (submit)="onUploadFile()"
            >
              <div class="py-2">
                <label for="image">File excel</label>
                <input
                  type="file"
                  name="file"
                  class="form-control my-2"
                  required
                  (change)="onChangeFile($event)"
                />
              </div>
              <div class="d-flex justify-content-end gap-2">
                <button
                  type="button"
                  class="btn btn-outline-secondary"
                  data-bs-dismiss="modal"
                >
                  Hủy
                </button>
                <button type="submit" class="btn btn-primary">Import</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
