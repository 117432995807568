import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { news_type } from 'src/app/models/news_type';
import { response } from 'src/app/models/response';
import { NewsTypeService } from 'src/app/services/news-type.service';
import {ToastService} from "../../toast.service";
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-news-type',
  templateUrl: './news-type.component.html',
  styleUrls: ['./news-type.component.css']
})
export class NewsTypeComponent implements OnInit {
  @ViewChild('formAdd') formAdd: NgForm;
  news_types: news_type[] = [];
  news_type: news_type = {
    name: '',
  }
  news_typeDetail: news_type = {
    name: '',
  }
  numberOfElements = 0;
  checked = false;
  totalPage = 10;
  itemPerPage = 10;
  totalItem = 10;
  pageNumber = 0;
  previewImage: any = '';
  selectedValue: any = [];
  indexCheckBox: any[] = [];
  checkedCheckBox: any[] = [];
  selectedFile: string | Blob = '';
  searchTerm = new Subject<string>();
  statusNewsType = 1;
  search = '';
  sort = 'create_desc';
  checkAll = false;
  constructor(
    private newsTypeService: NewsTypeService,
    private confirmationService: ConfirmationService,
    private toast: ToastService
  ) {
    this.formAdd = new NgForm([], []);
   }

  ngOnInit(): void {
    this.getAll()
    this.searchTerm.pipe(
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe((value: string) => {
      if (value.length === 0) {
        this.getAll()
      }
      this.newsTypeService.searchNewsType(value).subscribe({
        next: (data: response<news_type[]>) => {
          this.news_types = data.content;
          this.pageNumber = data.pageable.pageNumber;
        this.totalPage = data.totalPages;
        this.totalItem = data.totalElements;
        this.itemPerPage = data.numberOfElements;
        this.numberOfElements = data.numberOfElements;
        },
        error: (err: HttpErrorResponse) => {
          console.log(err);
          this.toast.error("Tìm kiếm loại tin tức", 'Có lỗi xảy ra khi tìm kiếm');
        }
      })
    })
  }

  getAll(): void {
    this.newsTypeService.getListNewsType(1, 1).subscribe({
      next: (data: response<news_type[]>) => {
        this.news_types = data.content;
        this.itemPerPage = data.numberOfElements
        this.totalItem = data.totalElements;
        this.totalPage = data.totalPages;
        this.pageNumber = data.pageable.pageNumber;
        this.numberOfElements = data.numberOfElements;
        console.log(data);

      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      }
    })
  }

  onGetById(id: number | undefined): void {
    this.newsTypeService.getNewsTypeById(id).subscribe({
      next: (data: any) => {
        this.news_typeDetail = data;
        console.log(data);

      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      }
    })
  }

  onPageChange(e: any): void {
    this.selectedValue = [];
    this.checked = false;
    const page = e.page + 1;
    this.newsTypeService.selectAll(this.statusNewsType, page, this.search, this.sort ).subscribe({
      next: (data: response<news_type[]>) => {
        this.news_types = data.content;
        this.pageNumber = data.pageable.pageNumber;
        this.numberOfElements = data.numberOfElements;
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      }
    })
  }

  onUploadFile(): void {
    this.newsTypeService.importExcel(this.selectedFile).subscribe({
      next: (data: any) => {
        this.getAll();
        this.toast.success('Import excel', 'Import thành công');
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
        this.toast.error('Import excel', 'Có lỗi xảy ra khi import');
      }
    })
  }

  onSearch(keyword: string): void {
    this.search = keyword;
    this.searchTerm.next(keyword)
  }

  onFilter(e: Event): void {
    this.statusNewsType = +(e.target as HTMLInputElement).value;
    this.newsTypeService.getListNewsType( this.statusNewsType, 1).subscribe({
      next: (data: response<news_type[]>) => {
        this.news_types = data.content;
        this.pageNumber = data.pageable.pageNumber;
        this.totalPage = data.totalPages;
        this.totalItem = data.totalElements;
        this.itemPerPage = data.numberOfElements;
        this.numberOfElements = data.numberOfElements;
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
        this.toast.error('Lọc loại tin tức', 'Có lỗi xảy ra khi lọc');
      }
    })
  }

  onSort(e: Event): void {
    this.sort = (e.target as HTMLInputElement).value;
    this.newsTypeService.sortNewsTypeByCondition(this.sort).subscribe({
      next: (data: response<news_type[]>) => {
        this.news_types = data.content;
        this.pageNumber = data.pageable.pageNumber;
        this.totalPage = data.totalPages;
        this.totalItem = data.totalElements;
        this.itemPerPage = data.numberOfElements;
        this.numberOfElements = data.numberOfElements;
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
        this.toast.error('Sắp xếp loại tin tức', 'Có lỗi xảy khi sắp xếp');
      }
    })
  }

  sortUpdate(){
    this.newsTypeService.sortNewsTypeByCondition('update_desc').subscribe({
      next: (data: response<news_type[]>) => {
        this.news_types = data.content;
        this.pageNumber = data.pageable.pageNumber;
        this.totalPage = data.totalPages;
        this.totalItem = data.totalElements;
        this.itemPerPage = data.numberOfElements;
        this.numberOfElements = data.numberOfElements;
      }
    })
  }

  onCheckAll(e: any): void {
    if (e.target.checked) {
      this.selectedValue = [];

      this.news_types.forEach(n => {
        return this.selectedValue.push(n.id);
      });
      this.checked = true;
      this.checkAll = true;
    } else {
      this.selectedValue = [];
      this.checked = false;
      this.checkAll = false;
      for (let index = 0; index <= 10; index++) {
        this.checkedCheckBox[index] = false;
      }
    }
    console.log(this.selectedValue);
  }

  onCheckboxValue(e: any, index : number): void {
    const id = +(e.target as HTMLInputElement).value;
    if (e.target.checked) {
      this.selectedValue.push(id)
      this.checkedCheckBox[index] = true;
      this.indexCheckBox.push(index);
      if (this.selectedValue.length === this.itemPerPage) {
        this.checkAll = true;
      }else{
        this.checkAll = false;
      }
    } else {
      this.indexCheckBox = this.indexCheckBox.filter((item: number) => item !== index);
      this.selectedValue = this.selectedValue.filter((item: number) => item !== id);
      if (this.selectedValue.length === this.itemPerPage) {
        this.checkAll = true;
      }else{
        this.checkAll = false;
      }
    }

  }

  onSubmit(data: news_type) {
    this.newsTypeService.createNewsType(data).subscribe({
      next: () => {
        this.getAll();
        this.formAdd.resetForm();
        this.toast.success('Thêm loại tin tức', 'Thêm thành công');
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
        this.toast.error('Thêm loại tin tức', 'Có lỗi xảy ra khi thêm mới');
      }
    })
  }

  onEdit(id: number | undefined, data: news_type) {
    this.newsTypeService.updateNewsTypeById(id, data).subscribe({
      next: (data: any) => {
        this.getAll();
        this.toast.success('Sửa loại tin tức', 'Sửa thành công');
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
        this.toast.error('Sửa loại tin tức', 'Có lỗi xảy ra khi sửa');
      }
    })
  }

  onDelete(id: number | undefined) {
    this.confirmationService.confirm({
      header: 'Xóa loại bài viết',
      message: 'Bạn có muốn xóa không?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Đồng ý',
      rejectLabel: 'Hủy',
      accept: () => {
        if (this.selectedValue.length === 0) {
          this.selectedValue.push(id);
          this.newsTypeService.deleteNewsTypeById(this.selectedValue).subscribe({
            next: () => {
              this.getAll()
              this.selectedValue = [];
              this.checked = false;
              this.checkAll = false;
              this.indexCheckBox.forEach(i => {
                this.checkedCheckBox[i] = false
              })
              this.indexCheckBox = [];
              this.toast.success('Xóa loại bài viết', 'Xóa thành công');
            },
            error: (err: HttpErrorResponse) => {
              console.log(err);
              this.toast.error('Xóa loại bài viết', 'Có lỗi xảy ra khi xóa');
            }
          })
        } else {
          this.newsTypeService.deleteNewsTypeById(this.selectedValue).subscribe({
            next: () => {
              this.getAll()
              this.selectedValue = [];
              this.checked = false;
              this.checkAll = false;
              this.indexCheckBox.forEach(i => {
                this.checkedCheckBox[i] = false
              })
              this.indexCheckBox = [];
              this.toast.success('Xóa loại bài viết', 'Xóa thành công');
            },
            error: (err: HttpErrorResponse) => {
              console.log(err);
              this.toast.error('Xóa loại bài viết', 'Có lỗi xảy ra khi xóa');
            }
          })
        }
      }
    })
  }

  onCheckBox(){
    this.checked = false;
    this.checkAll = false;
    this.selectedValue = []
    this.indexCheckBox.forEach(i => {
      this.checkedCheckBox[i] = false
    })
    this.indexCheckBox = [];
  }
}

